import { IonAlert, IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from './_redux/models/app.model';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App as AppCapacitor, URLOpenListenerEvent } from '@capacitor/app';

setupIonicReact({});

import './App.scss';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';

import '@acciona/ui-ionic-kit/lib/assets/base.min.css';
import { history } from './_helpers/history';
import Dashboard from './pages/Dashboard/Dashboard';
import SplitPane from './components/SplitPane/SplitPane';
import Login from './pages/Auth/Login/Login';
import Callback from './pages/Auth/Login/Callback';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Spaces from './pages/Spaces/Spaces';
import Workstation from './pages/Spaces/Workstation/Workstation';
import Workroom from './pages/Spaces/Workroom/Workroom';
import Parking from './pages/Spaces/Parking/Parking';
import NewsD from './pages/Dashboard/News/News';
import Incidents from './pages/Incidents/Incidents';
import ProfileRouter from './pages/Profile/ProfileRouter';
import CodeInvitation from './pages/Auth/Hid/CodeInvitation';
import DoorError from './pages/Auth/Hid/DoorError';
import CallbackMobile from './pages/Auth/Login/CallbackMobile';
import HIDService from './_redux/services/hid/hid.services';
import Notifications from './pages/Notifications/Notifications';
import NotificationDetail from './pages/Notifications/NotificationDetail';
import FirebaseLogWatcher from './analytics/FirebaseLogWatcher';
import {
  appActions,
  NewsActions,
  NotificationsActions,
} from './_redux/actions';
import { userActions } from './_redux/actions/user.actions';
import { vehicleActions } from './_redux/actions/vehicles.actions';
import { Toast } from '@acciona/ui-ionic-kit';
import { applyMacFontStyles, updateGlobalColors } from './utils/functions';

import PrivacyPolicies from './pages/Auth/PrivacyPolicies/PrivacyPolicies';
import { UserStore } from './_redux/models/user.model';
import { useTranslation } from 'react-i18next';
import Logout from './pages/Auth/Logout/Logout';
import CanRoute from './components/CanRoute/CanRoute';
import Card from './pages/Profile/Card/Card';
import { LocalNotifications } from '@capacitor/local-notifications';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import NotificationUnauthorized from './pages/Notifications/NotificationUnauthorized';
import { useNetworkStatus } from './hooks/useNetworkStatus';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import {
  monthsEn,
  monthsEs,
  weekdaysEn,
  weekdaysEs,
  monthsShortEn,
  monthsShortEs,
  monthsPt,
  weekdaysPt,
  monthsShortPt,
  EPermissionsIds,
} from './utils/constants';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import { Device } from '@capacitor/device';
import OfflineRoute from './components/OfflineRoute/OfflineRoute';
import MobileOnlyRoute from './components/MobileOnlyRoute/MobileOnlyRoute';
import { useOfflineServicesCheck } from './hooks/useOfflineServicesCheck';
import { NotificationsStore } from './_redux/models/notifications.model';
import locale_pt from 'dayjs/locale/pt';

import Canteen from './pages/Spaces/Canteen/Canteen';
import Confirmation from './pages/Shared/Success/Confirmation';
import AppNotificationDetail from './pages/Notifications/AppNotificationDetail';
import { useLogout } from './hooks/useLogout';
import { useGetMoreMenuConfig } from './pages/Dashboard/More/hooks/useGetMoreMenuConfig';
import ServicesRouter from './pages/Dashboard/More/ServicesRouter';
import InAppBrowserOnlyRoute from './components/InAppBrowserOnlyRoute/InAppBrowserOnlyRoute';
import ExternalVideoPlayer from './pages/Dashboard/News/ExternalVideoPlayer/ExternalVideoPlayer';
import RestaurantTable from './pages/Spaces/RestaurantTable/RestaurantTable';
import PaymentConfirmation from './pages/PaymentConfirmation/PaymentConfirmation';

// Add custom property 'appHistory' to the global window object
declare global {
  interface Window {
    appHistory: History;
  }
}
const App: React.FC<any> = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);
  dayjs.extend(isToday);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(timezone);
  dayjs.extend(updateLocale);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [messageToast, setMessage] = useState('');
  const [toastType, setToastType] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');

  const {
    theme,
    globalSettings,
    localSettings,
    offlineNetwork,
    offlineServices,
    offlineToastIsOpen,
    noReservationPermissionToastIsOpen,
  } = useSelector((state: AppStore) => state.app);

  const offlineMode = offlineNetwork || offlineServices;

  const {
    isUserAcceptedPolicies,
    error: errorUser,
    user: { userIsRegisteredInDatabase },
  } = useSelector((state: UserStore) => state.user);

  useNetworkStatus();

  const { noticeToastMessage, genericToast, genericAlert } = useSelector(
    (state: NotificationsStore) => state.notifications,
  );

  useOfflineServicesCheck();

  const { logOut } = useLogout();

  useGetMoreMenuConfig();

  useEffect(() => {
    dispatch(appActions.getLocalSettings());
    const requestPerm = async () => {
      await LocalNotifications.requestPermissions(); //Only ask first app startup Antroid 13
      await AndroidPermissions.requestPermissions([
        AndroidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION,
        AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
        AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
        AndroidPermissions.PERMISSION.BLUETOOTH_SCAN,
        AndroidPermissions.PERMISSION.BLUETOOTH_ADVERTISE,
        AndroidPermissions.PERMISSION.BLUETOOTH_CONNECT,
      ]);
    };
    isPlatform('android') && requestPerm();
    isPlatform('capacitor') && setStatusBarStyleLight();
    Device.getInfo().then(info => {
      if (info.operatingSystem === 'mac') {
        applyMacFontStyles();
      }
    });
    HIDService.popupEventDispatcher.subscribe({
      next: popupData => {
        const data = popupData as {
          title: string;
          message: string;
          type: 'success' | 'info' | 'warning' | 'error';
          handler: () => any;
        };
        const message = t(data.message);
        setToastType(data.type);
        setMessage(message);
        setShowToast(true);
      },
    });
    AppCapacitor.addListener(
      'appUrlOpen',
      async (event: URLOpenListenerEvent) => {
        const url = new URL(event.url.toString());
        const params = new URLSearchParams(url.search);
        const invitationCode = params.get('invitationCode');
        if (invitationCode !== null) {
          dispatch(userActions.deepLinkCodeReceived(invitationCode));
        }
      },
    );
    return () => {
      AppCapacitor.removeAllListeners();
    };
  }, [dispatch]);

  useEffect(() => {
    if (localSettings.localLanguage === 'es') {
      dayjs.locale('es');
      dayjs.updateLocale('es', {
        months: monthsEs,
        weekdays: weekdaysEs,
        monthsShort: monthsShortEs,
        weekStart: 0,
      });
    } else if (localSettings.localLanguage === 'en') {
      dayjs.locale('en');
      dayjs.updateLocale('en', {
        months: monthsEn,
        weekdays: weekdaysEn,
        monthsShort: monthsShortEn,
        weekStart: 0,
      });
    } else if (localSettings.localLanguage === 'pt') {
      dayjs.locale(locale_pt);
      dayjs.updateLocale('pt', {
        months: monthsPt,
        weekdays: weekdaysPt,
        monthsShort: monthsShortPt,
        weekStart: 0,
      });
    }
    dayjs.tz.setDefault(globalSettings.campusTimeZone);
  }, [localSettings.localLanguage, globalSettings.campusTimeZone]);

  useEffect(() => {
    if (isUserAcceptedPolicies) {
      dispatch(appActions.getWelcomeSetup());
      dispatch(NewsActions.getSessionId());
      dispatch(vehicleActions.getListVehicle());
    }
  }, [isUserAcceptedPolicies]);

  useEffect(() => {
    globalSettings.colors && updateGlobalColors(globalSettings.colors);
  }, [globalSettings.colors]);

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };

  useEffect(() => {
    if (userIsRegisteredInDatabase === false) {
      dispatch(
        NotificationsActions.setGenericAlertWithButtons(
          t('title_problem_with_user_identification'),
          t('user_does_not_exist'),
          [
            {
              text: t('ok_text'),
              role: 'confirm',
              handler: logOut,
            },
          ],
        ),
      );
    }
  }, [userIsRegisteredInDatabase]);

  return (
    <IonApp className={theme == 'dark' && 'dark-theme'}>
      {isPlatform('ios') && <div className="notificationBar" />}
      <IonReactRouter history={history}>
        <FirebaseLogWatcher />
        <SplitPane>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/signout">
              <Logout />
            </Route>
            <Route exact path="/callback">
              <Callback />
            </Route>
            <Route exact path="/callbackMobile">
              <CallbackMobile />
            </Route>
            <Route exact path="/codeInvitation">
              <CodeInvitation />
            </Route>
            <Route exact path="/codeInvitation/door">
              <DoorError />
            </Route>
            <MobileOnlyRoute exact path="/card">
              <Card />
            </MobileOnlyRoute>
            <PrivateRoute path="/policies">
              <PrivacyPolicies />
            </PrivateRoute>
            <Route exact path="/confirmation">
              <Confirmation />
            </Route>
            <OfflineRoute path="/dashboard">
              <Dashboard />
            </OfflineRoute>
            <PrivateRoute path="/workstation">
              <Workstation />
            </PrivateRoute>
            <PrivateRoute path="/spaces">
              <Spaces />
            </PrivateRoute>
            <PrivateRoute path="/workroom">
              <Workroom />
            </PrivateRoute>
            <PrivateRoute path="/restaurant">
              <RestaurantTable></RestaurantTable>
            </PrivateRoute>
            <PrivateRoute path="/parking">
              <Parking />
            </PrivateRoute>
            <PrivateRoute path="/canteen">
              <Canteen />
            </PrivateRoute>
            <InAppBrowserOnlyRoute exact path="/news/video/:videoId">
              <ExternalVideoPlayer />
            </InAppBrowserOnlyRoute>
            <PrivateRoute path="/news">
              <NewsD />
            </PrivateRoute>
            <PrivateRoute path="/incidents">
              <Incidents />
            </PrivateRoute>
            <PrivateRoute path="/notifications">
              <CanRoute functionality="notifications">
                <Notifications />
              </CanRoute>
            </PrivateRoute>
            <PrivateRoute exact path="/notification-detail">
              <CanRoute functionality="notifications">
                <NotificationDetail />
              </CanRoute>
            </PrivateRoute>
            <PrivateRoute exact path="/appnotification-detail">
              <CanRoute functionality="notifications">
                <AppNotificationDetail />
              </CanRoute>
            </PrivateRoute>
            <PrivateRoute exact path="/notification-unauthorized">
              <NotificationUnauthorized />
            </PrivateRoute>
            {/*  MORE SERVICES --------------- */}
            <PrivateRoute path="/services">
              <ServicesRouter />
            </PrivateRoute>
            <PrivateRoute path="/howtoget">
              <Redirect to={`/services/${EPermissionsIds.HOW_TO_GET}`} />
            </PrivateRoute>
            <PrivateRoute path="/restaurants">
              <Redirect to={`/services/${EPermissionsIds.RESTAURANT}`} />
            </PrivateRoute>
            <PrivateRoute path="/medicalservices">
              <Redirect to={`/services/${EPermissionsIds.MEDICAL_SERVICES}`} />
            </PrivateRoute>
            {/*  ----------------------------- */}
            <OfflineRoute path="/profile">
              <ProfileRouter />
            </OfflineRoute>
            <Route exact path="/payment-confirmation/:orderId">
              <PaymentConfirmation />
            </Route>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route>
              <Redirect to="/login" /> {/* Fallback if route doesn't exist' */}
            </Route>
          </Switch>
        </SplitPane>
      </IonReactRouter>
      <Toast
        isOpen={showToast && !offlineMode}
        onDidDismiss={() => setShowToast(false)}
        message={messageToast}
        position="bottom"
        type={toastType}
      />
      <Toast
        isOpen={!!errorUser && !offlineMode && userIsRegisteredInDatabase}
        message={
          errorUser === 'not_available_head_office'
            ? t('not_available_head_office')
            : t('msg_system_unavailable')
        }
        position="bottom"
        type="error"
      />
      <Toast
        isOpen={offlineToastIsOpen}
        message={
          offlineNetwork
            ? t('toast_offline_network')
            : t('toast_offline_services')
        }
        position="bottom"
        type={'error'}
        duration={3000}
        onDidDismiss={() => dispatch(appActions.setOfflineToastIsOpen(false))}
      />
      <Toast
        isOpen={!!noticeToastMessage}
        message={noticeToastMessage}
        position="bottom"
        type="success"
        onDidDismiss={() => dispatch(NotificationsActions.resetNoticeToast())}
      />
      <Toast
        isOpen={noReservationPermissionToastIsOpen}
        message={t('no_reservation_permission')}
        position="bottom"
        type="error"
        onDidDismiss={() =>
          dispatch(appActions.setNoReservationPermissionToastIsOpen(false))
        }
      />
      <Toast
        isOpen={genericToast.isOpen}
        message={genericToast.message}
        type={genericToast.type}
        position="bottom"
        onDidDismiss={() => {
          dispatch(NotificationsActions.dismissGenericToast());
        }}
      />
      <IonAlert
        mode="ios"
        header={genericAlert.header}
        message={genericAlert.message}
        isOpen={genericAlert.isOpen}
        buttons={genericAlert.buttons}
        backdropDismiss={false}
        onDidDismiss={() => {
          dispatch(NotificationsActions.dismissGenericAlert());
        }}
      />
    </IonApp>
  );
};

export default App;
