import { Icon, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonRow,
  IonCol,
  IonLabel,
  IonLoading,
} from '@ionic/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsActions, userActions } from '../../../_redux/actions';
import { UserStore } from '../../../_redux/models/user.model';
import styles from './styles.module.scss';
import isYesterday from 'dayjs/plugin/isYesterday';

import LastMovements from './components/LastMovements/LastMovements';
import Transactions from './components/Transactions/Transactions';
import MovementDetail from './MovementDetail/MovementDetail';
import { userServices } from '../../../_redux/services/user/user.services';
dayjs.extend(isYesterday);

const Wallet: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employeeId } = useSelector((store: UserStore) => store.user.user);
  const { walletV2, isLoading } = useSelector((store: UserStore) => store.user);
  const [showLastMovements, setShowLastMovements] = useState(false);
  const [showMovementDetail, setShowMovementDetail] = useState(false);
  const [showLoadingMovementDetail, setShowLoadingMovementDetail] =
    useState(false);
  const [movementDetail, setMovementDetail] = useState<any>();

  const handleUpdateTransactions = async () => {
    dispatch(userActions.getWalletV2(employeeId));
    setShowLastMovements(!showLastMovements);
  };

  const getFormatDate = (date: string) => {
    const dateFormat = dayjs(date).utc();
    if (dateFormat.isToday()) {
      return t('lbl_today');
    } else if (dateFormat.isYesterday()) {
      return t('lbl_yesterday');
    } else {
      return dateFormat.format('DD/MM/YYYY');
    }
  };

  const goToDetailTransaction = async idTransaction => {
    try {
      setShowLoadingMovementDetail(true);
      const resp = await userServices.getWalletDetailTransaction(idTransaction);
      setShowLoadingMovementDetail(false);

      if (!resp.success) {
        dispatch(
          NotificationsActions.setGenericErrorToast(
            t('msg_error_wallet_detail'),
          ),
        );
      } else {
        setMovementDetail(resp);
        setShowMovementDetail(true);
      }
    } catch (err) {
      dispatch(
        NotificationsActions.setGenericErrorToast(t('msg_error_wallet_detail')),
      );
    }
  };
  useEffect(() => {
    dispatch(userActions.getWalletV2(employeeId));
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_wallet')}
        />
      </IonHeader>
      {isLoading || showLoadingMovementDetail ? (
        <IonLoading
          isOpen={isLoading || showLoadingMovementDetail}
          message={t('msg_loading')}
        />
      ) : showMovementDetail ? (
        <MovementDetail
          transactionDetail={movementDetail}
          navigateBack={() => setShowMovementDetail(false)}
        ></MovementDetail>
      ) : (
        <IonContent fullscreen>
          <div className={styles.container}>
            <div className={styles.card}>
              <div className={styles.cardIcon}>
                <Icon className={`icon icon-24 icon-wallet`} />
              </div>
              <div className={styles.cardPoints}>
                <IonLabel className={styles.title}>
                  {walletV2?.administrativeBalance}
                </IonLabel>
                <IonLabel className={styles.subtitle}>
                  {t('wallet_balance')}
                </IonLabel>
              </div>
            </div>
            <div className={styles.itemLastMovements}>
              <LastMovements
                handleUpdateTransactions={handleUpdateTransactions}
                dateLastUpdated={dayjs(walletV2?.lastUpdated)
                  .tz()
                  .format(' DD MMM YYYY[,] HH:mm')}
              />
            </div>
            <div className={styles.itemMovements}>
              {walletV2?.movements && walletV2?.movements?.length > 0 ? (
                walletV2?.movements?.map(tr => {
                  return (
                    <Transactions
                      key={tr.transactionsId}
                      commerceName={tr.commerceName}
                      expense={tr.type === 'rest' ? '0' : tr.amount}
                      date={getFormatDate(tr.transactionsDate)}
                      place={tr.place}
                      goToDetailTransaction={() => {
                        goToDetailTransaction(tr.transactionsId);
                      }}
                    />
                  );
                })
              ) : (
                <div className={styles.noTransactionsContainer}>
                  <IonRow className="ion-justify-content-center">
                    <IonCol className={styles.header}>
                      <IonLabel className={styles.title_2}>
                        {t('no_transactions.header')}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonCol className={styles.subheader}>
                      <IonLabel className={styles.subhead}>
                        {t('no_transactions.firstSubheader')}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};
export default Wallet;
