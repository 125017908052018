import { Props } from './types';
import styles from './styles.module.scss';
import { Icon } from '@acciona/ui-ionic-kit';

const SummarySection: React.FC<Props> = props => {
  return (
    <>
      {props?.order ? (
        <div className={styles.container}>
          <div className={styles.logo}>
            <Icon className={`icon icon-24 icon-plate`}></Icon>
            {/* <Icon
          className={`icon icon-24 icon-${
            ProductType[props.order.productType]
          }`}
        ></Icon> */}
          </div>
          <div className={styles.content}>
            <div className={styles.content__title}>
              <h2 className={styles.title}>{props.order.name}</h2>
              <span className={styles.title}>({props.order.quantity})</span>
            </div>
            {/* <p className={styles.content__price}>
              {props.order.totalUnitAmount / 100}
            </p> */}
          </div>
        </div>
      ) : (
        <h1>No se pudo recuperar el detalle del pedido</h1>
      )}
    </>
  );
};

export default SummarySection;
