import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IonAlert,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import { ActionSheet, Alert, Toast } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VehicleStore } from '../../../_redux/models/vehicles.model';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../_redux/models/app.model';
import Can from '../../../components/Can/Can';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import {
  appActions,
  userActions,
  workroomActions,
  vehicleActions,
  workstationActions,
  parkingActions,
  reservationActions,
  NotificationsActions,
} from '../../../_redux/actions';
import { UserStore } from '../../../_redux/models/user.model';
import { isPlatform } from '@ionic/react';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import {
  isCanteenReservationActive,
  isEmpty,
  getCanteenReservActiveModalDescription,
  getCanteenReservationsSorted,
} from '../../../utils/functions';
import { getAvailableVehicles } from '../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import _ from 'lodash';
import { ParkingStore } from '../../../_redux/models/parking.model';
import { getParkingRoute } from '../../Spaces/helpers';
import { useCanReservationDeskForToday } from '../../../hooks/useCanReservationDeskForToday';
import dayjs from 'dayjs';
import { useLocationState } from '../../../hooks/useLocationState';
import { PARKING_PERMISSIONS } from '../../../utils/constants';

const ActionsMenuMobile: React.FC = () => {
  const [canteenModalDescValues, setCanteenModalDescValues] = useState({
    date: '',
    from: '',
    to: '',
  });
  const { canReservationDeskForToday, isDeskReservationForTodayActive } =
    useCanReservationDeskForToday();
  const { t } = useTranslation();
  const history = useHistory();
  const historyState: any = useLocationState();
  const dispatch = useDispatch();
  const {
    emergencyPhone,
    plannedReservation,
    lastMinuteReservation,
    canteenReservationLastHour,
    temporalVisitsDistrict,
    dayParkingPlannedTurningPoint,
  } = useSelector((state: AppStore) => state.app.globalSettings);
  const {
    permanentDesks,
    permanentParkings,
    reservations,
    configurationDeskReservationForToday,
  } = useSelector((store: ReservationStore) => store.reservation);

  const {
    hasReservableDays: hasReservableWorkstationDays,
    configurations,
    isLoadingWorkstations,
    error: errorWorkstations,
  } = useSelector((state: WorkstationStore) => state.workstation);

  const { hasReservableDays: hasReservableParkingDays, plannedReservableDays } =
    useSelector((state: ParkingStore) => state.parking);
  const { sedesList } = useSelector((state: UserStore) => state.user.user);

  const { vehicles } = useSelector((state: VehicleStore) => state.vehicle);
  const { user, error } = useSelector((state: UserStore) => state.user);
  const {
    district,
    hasValidDistrict,
    defaultSede,
    permissions,
    building,
    floor,
    deskReservationConfiguration,
    userIsRegisteredInDatabase,
  } = user;
  const raffle = useSelector((state: ParkingStore) => state.parking.raffle);
  const [showAlert, setShowAlert] = useState({ state: false, msg: null });
  const [showRaffle, setShowRaffle] = useState<boolean>(false);
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [canteenActive, setCanteenActive] = useState(null);
  const [openCanteenReservActiveModal, setOpenCanteenReservActiveModal] =
    useState(false);

  const goToCalendar = useCallback(() => {
    if (!district || _.isEmpty(district)) {
      setShowAlert({ state: true, msg: 'lbl_not_available_district' });
      return;
    }
    if (hasValidDistrict) {
      history.push('/workstation/calendar');
    } else {
      setShowAlert({ state: true, msg: 'lbl_not_valid_district' });
    }
  }, [district, hasValidDistrict]);

  const thereAreAvailableVehicles = useMemo(
    () => !isEmpty(getAvailableVehicles(vehicles, permissions)),
    [vehicles, permissions],
  );

  const data = useMemo(
    () => [
      {
        id: 1,
        text: t(
          isDeskReservationForTodayActive
            ? 'lbl_action_desk_forToday'
            : 'lbl_action_res_position',
        ),
        routerLink: '/workstation/calendar',
        icon: 'icon icon-seat',
        permissionCode: 'desks',
        show:
          isEmpty(permanentDesks.filter(p => p.idSede === defaultSede.id)) ||
          hasReservableWorkstationDays,
      },
      {
        id: 2,
        text: t('lbl_action_res_parking'),
        routerLink: getParkingRoute(
          thereAreAvailableVehicles,
          plannedReservation,
          lastMinuteReservation,
        ),
        icon: 'icon icon-parking',
        permissionCode: [
          PARKING_PERMISSIONS.parking,
          PARKING_PERMISSIONS.pooler_moto,
          PARKING_PERMISSIONS.pooler_microcar,
        ],
        show:
          (plannedReservation || lastMinuteReservation) &&
          (isEmpty(
            permanentParkings.filter(p => p.idSede === defaultSede.id),
          ) ||
            hasReservableParkingDays),
      },
      {
        id: 5,
        text: t('lbl_actions_res_restaurant'),
        routerLink: '/canteen',
        icon: 'icon icon-plate',
        permissionCode: ['reservation_restaturant'],
        show: true,
        showLine: true,
      },
      {
        id: 3,
        text: t('lbl_action_res_room'),
        routerLink: '/workroom/filters',
        icon: 'icon icon-room',
        permissionCode: 'room',
        show: true,
      },
      {
        id: 4,
        text: t('lbl_action_res_qr'),
        routerLink: '/spaces/scanqrcode',
        icon: 'icon icon-qr',
        permissionCode: ['room', 'order_restaurant_menu'],
        show: isPlatform('capacitor'),
      },
    ],
    [
      permanentParkings,
      permanentDesks,
      hasReservableParkingDays,
      thereAreAvailableVehicles,
      defaultSede.id,
      configurationDeskReservationForToday,
      isDeskReservationForTodayActive,
    ],
  );

  useIonViewDidEnter(() => {
    if (userIsRegisteredInDatabase) {
      dispatch(userActions.getUserPermissions());
      dispatch(userActions.getReservationPolicies());
      dispatch(userActions.validateUserDistrict());
      dispatch(appActions.getAppSettings());
      dispatch(parkingActions.getRaffle());
      dispatch(vehicleActions.getListVehicle());
      dispatch(workstationActions.getWsConfigurations());
      dispatch(parkingActions.getPkConfigurations());
      dispatch(parkingActions.getPkReservableDays());
    }
  });

  useEffect(() => {
    return () => {
      if (
        history.action === 'POP' &&
        (history.location.pathname == '/workstation/lastminute' ||
          history.location.pathname == '/workroom/list')
      ) {
        history.replace('/dashboard/home');
      }
    };
  }, [history]);

  const handleItemClick = async (e, router) => {
    switch (router) {
      case '/workroom/filters':
        dispatch(workroomActions.resetSearchFilters());
        break;
      case '/workstation/calendar':
        e.preventDefault();
        if (!isDeskReservationForTodayActive) {
          goToCalendar();
          break;
        }
        if (await canReservationDeskForToday()) {
          const dayCompletePolicy = configurations.policies.find(
            policy => policy.nombre === 'Dia completo',
          );
          dispatch(
            workstationActions.getLastMinuteSelectWs(
              [`${dayjs.tz().format('YYYY-MM-DD')}T00:00:00.000Z`],
              {
                isVisit: district === temporalVisitsDistrict,
                defaultBuilding: parseInt(building),
                defaultFloor: parseInt(floor),
              },
              dayCompletePolicy?.nombre,
              dayCompletePolicy?.fullHoraMin,
              dayCompletePolicy?.fullHoraMax,
              `${t('today')} | ${t('complete_day')} | ${t(
                deskReservationConfiguration.configurationName,
              )}`,
            ),
          );
        }
        break;
      case '/parking/calendarPlanned':
        e.preventDefault();
        if (raffle.inProgress) {
          setShowRaffle(true);
        } else {
          if (plannedReservableDays.length === 0) {
            dispatch(
              NotificationsActions.setGenericAlert(
                t('lbl_alert_noReservableDays_title'),
                t('lbl_alert_noReservableDays_desc', {
                  day: t(
                    `dateFormats.dayIndexedByNum.${dayParkingPlannedTurningPoint}`,
                  ).toLocaleLowerCase(),
                }),
              ),
            );
            return;
          }
          history.push(router);
        }
        break;
      case '/canteen':
        e.preventDefault();
        if (
          isCanteenReservationActive(reservations, canteenReservationLastHour)
        ) {
          setOpenCanteenReservActiveModal(true);
          const canteenReservations =
            getCanteenReservationsSorted(reservations);
          const canteenReserv =
            canteenReservations.length > 1
              ? canteenReservations[1]
              : canteenReservations[0];
          setCanteenActive(canteenReserv);
          const sedeSpace = sedesList.find(e => e.id == canteenReserv?.idSede);
          const newValues = getCanteenReservActiveModalDescription(
            canteenReserv,
            sedeSpace?.campusTimeZone,
          );
          setCanteenModalDescValues(newValues);
        } else {
          history.push(router);
        }

        break;
    }
  };
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className={styles.toolbar}>
          <IonTitle>{t('tab_actions')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar className={styles.toolbar}>
            <IonTitle size="large" className={styles.largeTitleBold}>
              {t('tab_actions')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className={styles.gridWeb}>
          <DetailList className={styles.listActions}>
            {data
              .filter(e => e.show)
              .map((item, index, arr) => {
                const isLastitem = index === arr.length - 1;
                return (
                  <Can key={item.id} functionality={item.permissionCode}>
                    <DetailListItem
                      className={styles.itemSty}
                      mode="ios"
                      lines={isLastitem ? 'none' : 'inset'}
                      routerLink={item.routerLink}
                      type="button"
                      startIcon={item.icon}
                      startIconColor="primary"
                      titleLight={item.text}
                      endIcon="icon icon-chevron-right"
                      endIconColor="primary"
                      onClick={e => handleItemClick(e, item.routerLink)}
                    />
                  </Can>
                );
              })}
            <Can functionality="incidents">
              <div className={styles.separatorList} />
              <DetailListItem
                className={styles.itemSty}
                mode="ios"
                lines="none"
                routerLink={'/incidents/categories'}
                type="button"
                startIcon="icon icon-incident"
                startIconColor="primary"
                titleLight={t('lbl_action_res_incident')}
                endIcon="icon icon-chevron-right"
                endIconColor="primary"
              />
            </Can>
            <Can functionality="emergency">
              <div className={styles.separatorList} />
              <DetailListItem
                className={styles.itemStySecondary}
                mode="ios"
                lines="inset"
                type="button"
                onClick={() => setOpenActionSheet(true)}
                startIcon="icon icon-emergency"
                startIconColor="secondary"
                titleLight={t('lbl_action_emergencyCall')}
                titleColor="secondary"
                endIcon="icon icon-chevron-right"
                endIconColor="secondary"
              />
            </Can>
          </DetailList>
        </div>
        <Alert
          header={t('btn_last_minute_workstation')}
          isOpen={showAlert.state}
          onDidDismiss={() => setShowAlert({ state: false, msg: null })}
          message={t(showAlert.msg)}
          buttons={[{ text: t('btn_understood'), role: 'cancel' }]}
          mode="ios"
        />
        <ActionSheet
          cssClass={styles.actionsheetSty}
          isOpen={openActionSheet && permissions.includes('emergency')}
          mode="ios"
          onDidDismiss={() => setOpenActionSheet(false)}
          buttons={[
            {
              cssClass: styles.btnActionSheetOptions,
              text: t('call_to', { number: emergencyPhone }),
              handler: () => {
                window.open(`tel:${emergencyPhone}`, '_self');
                setOpenActionSheet(false);
              },
            },
            {
              cssClass: styles.btnActionSheetCancel,
              text: `${t('cancel_text')}`,
              role: 'cancel',
              handler: () => {
                setOpenActionSheet(false);
              },
            },
          ]}
        />
        <Toast
          isOpen={showRaffle}
          message={t('lbl_raffle_inprogress')}
          position="bottom"
          type="info"
          onDidDismiss={() => setShowRaffle(false)}
        />
        {historyState && historyState.error && (
          <Toast
            isOpen={true}
            message={historyState.error}
            position="bottom"
            type="error"
          />
        )}
        <Toast
          isOpen={!!error || !!errorWorkstations}
          message={error || errorWorkstations}
          position="bottom"
          type="error"
        />
        <IonAlert
          mode="ios"
          header={t('canteenReservActiveModal.header')}
          message={t('canteenReservActiveModal.description', {
            date: canteenModalDescValues.date,
            from: canteenModalDescValues.from,
            to: canteenModalDescValues.to,
          })}
          isOpen={openCanteenReservActiveModal}
          backdropDismiss={false}
          buttons={[
            {
              text: t('cancel_text'),
              role: 'cancel',
              handler: () => {
                setOpenCanteenReservActiveModal(false);
              },
            },
            {
              text: t('canteenReservActiveModal.btn_routerToReserv'),
              cssClass: styles.canteenModalBtn,
              role: 'confirm',
              handler: () => {
                dispatch(
                  reservationActions.getReservationCanteen(
                    canteenActive?.dates[0]?.reservationId,
                  ),
                );
                setOpenCanteenReservActiveModal(false);
              },
            },
          ]}
        ></IonAlert>
        <IonLoading
          isOpen={isLoadingWorkstations}
          message={t('msg_loading')}
          duration={0}
        />
      </IonContent>
    </IonPage>
  );
};

export default ActionsMenuMobile;
