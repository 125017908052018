import { useEffect, useState } from 'react';
import { Alert, List, ListHeader, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  isPlatform,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  appActions,
  parkingActions,
  workstationActions,
} from '../../_redux/actions';
import { vehicleActions } from '../../_redux/actions/vehicles.actions';
import { getAppVersion } from '../../utils/functions';
import { UserData, UserStore } from '../../_redux/models/user.model';
import { AppStore } from '../../_redux/models/app.model';
import Can from '../../components/Can/Can';
import { DetailListItem } from '../../components/Base/DetailListItem';
import { ProfileHeader } from './ProfileHeader';
import { useLogout } from '../../hooks/useLogout';
import styles from './styles.module.scss';
import { useCheckUserPermissions } from '../../hooks/useCheckUserPermissions';

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { offlineNetwork, offlineServices } = useSelector(
    (state: AppStore) => state.app,
  );

  const offlineMode = offlineNetwork || offlineServices;

  const { logOut } = useLogout();

  const { hasPermission } = useCheckUserPermissions();
  const data = [
    {
      id: 1,
      text: t('lbl_profile_personal_data'),
      routerLink: '/profile/user',
      icon: 'icon icon-client',
      permissionCode: 'public',
      accessAllowedInOfflineMode: isPlatform('capacitor') && !offlineNetwork,
    },
    {
      id: 2,
      text: t('lbl_profile_wallet'),
      routerLink: hasPermission('order_restaurant_menu')
        ? '/profile/wallet'
        : '/profile/walletOld',
      icon: 'icon icon-wallet',
      permissionCode: 'wallet',
      accessAllowedInOfflineMode: false,
    },
    {
      id: 3,
      text: t('lbl_profile_settings'),
      routerLink: '/profile/settings',
      icon: 'icon icon-settings',
      permissionCode: 'public',
      accessAllowedInOfflineMode: false,
    },
    {
      id: 4,
      text: t('lbl_profile_personal_incidence'),
      routerLink: '/profile/incidence',
      icon: 'icon icon-incident',
      permissionCode: 'incidents',
      accessAllowedInOfflineMode: false,
    },
    {
      id: 5,
      text: t('lbl_profile_Legal'),
      routerLink: '/profile/legal',
      icon: 'icon icon-documentation',
      permissionCode: 'public',
      accessAllowedInOfflineMode: false,
    },
    {
      id: 6,
      text: t('lbl_profile_close_sesion'),
      icon: 'icon icon-logout',
      color: 'red',
      permissionCode: 'public',
      accessAllowedInOfflineMode: false,
    },
  ];
  const dispatch = useDispatch();
  const [version, setVersion] = useState('');
  const user = useSelector((state: UserStore) => state.user.user);
  const [showAlert, setShowAlert] = useState(false);
  const [userData, setUserData] = useState<UserData>(user);
  useEffect(() => {
    async function fetchVersion() {
      setVersion(await getAppVersion());
    }
    fetchVersion();

    dispatch(vehicleActions.getListVehicle());
    dispatch(appActions.getAppSettings());
    dispatch(workstationActions.getWsConfigurations());
    dispatch(parkingActions.getPkConfigurations());
  }, []);

  const hadleLogOut = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    setUserData(prev => ({
      ...prev,
      name: user?.name ?? '',
      firstSurname: user?.firstSurname ?? '',
      secondSurname: user?.secondSurname ?? '',
      employeeId: user?.employeeId ?? '',
    }));
  }, [user]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('profile')}
        />
      </IonHeader>
      <IonContent>
        <div>
          <ProfileHeader user={userData} />
          <div className={styles.listGrid}>
            <List className={styles.bgWhitesmoke}>
              <ListHeader className={styles.roundHeader}></ListHeader>
              {data.map(item => {
                return item.id === 6 ? (
                  <DetailListItem
                    className={styles.profileList}
                    key={item.id}
                    mode="ios"
                    lines="inset"
                    type="button"
                    onClick={hadleLogOut}
                    button
                    startIcon={item.icon}
                    titleLight={item.text}
                  />
                ) : (
                  <Can key={item.id} functionality={item.permissionCode}>
                    <DetailListItem
                      className={styles.profileList}
                      mode="ios"
                      lines="inset"
                      startIcon={item.icon}
                      titleLight={item.text}
                      endIcon="icon icon-chevron-right"
                      {...(!offlineMode || item.accessAllowedInOfflineMode
                        ? { routerLink: item.routerLink }
                        : {
                            onClick: () =>
                              dispatch(appActions.setOfflineToastIsOpen(true)),
                          })}
                    />
                  </Can>
                );
              })}
            </List>
          </div>
        </div>
        <Alert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          message={t('lbl_logout_confirmation')}
          buttons={[
            { text: 'No', role: 'cancel' },
            { text: t('lbl_affirmative'), handler: logOut },
          ]}
          mode="ios"
        />
      </IonContent>
      <IonFooter className={styles.btnFooter}>
        <IonGrid className={styles.footerGridSty}>
          <IonRow className={styles.version}>
            <IonText>{version}</IonText>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default Profile;
