import { Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
/* Workstation */
import WorkstationReservationDetail from './Workstation/WorkstationReservationDetail/WorkstationReservationDetail';
/* Parking */
import ParkingRequestDetail from './Parking/ParkingRequestDetail/ParkingRequestDetail';
import ParkingReservationDetail from './Parking/ParkingReservationDetail/ParkingReservationDetail';
import PassengersByRequest from './Parking/PassengersByRequest/PassengersByRequest';
import PassengersByReservation from './Parking/PassengersByReservation/PassengersByReservation';
import VehicleByRequest from './Parking/VehicleByRequest/VehicleByRequest';
import VehicleByReservation from './Parking/VehicleByReservation/VehicleByReservation';
/* Workroom */
import DetailWorkroom from './Workroom/DetailWorkroom/DetailWorkroom';
import WorkroomReservationDetail from './Workroom/WorkroomReservationDetail/WorkroomReservationDetail';
import WorkroomWaitingDetail from './Workroom/WorkroomWaitingDetail/WorkroomWaitingDetail';
import WorkRoomDetail from './Workroom/WorkRoomDetail';
/* Locker */
import LockerDetail from './Locker/LockerDetail/LockerDetail';
import LockerDownloadApp from './Locker/LockerDownloadApp/LockerDownloadApp';
import { CanteenReservationDetail } from './Canteen/CanteenReservationDetail/CanteenReservationDetail';
/* Table */
import IFrameContainer from './RestaurantTable/components/IFrameContainer/IFrameContainer';
import OrderDetail from './RestaurantTable/OrderDetail/OrderDetail';
/* Common */
import SpacesScanQRCode from './Common/SpacesScanQRCode/SpacesScanQRCode';
import OrderLoader from './RestaurantTable/OrderLoader/OrderLoader';
import MovementDetail from '../Profile/Wallet/MovementDetail/MovementDetail';

const Spaces: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        {/* Workstation */}
        <Route exact path="/spaces/workstation/reservationdetail">
          <WorkstationReservationDetail />
        </Route>
        {/* Parking */}
        <Route path="/spaces/parking/detail" exact>
          <ParkingReservationDetail />
        </Route>
        <Route exact path="/spaces/parking/detail/vehicles">
          <VehicleByReservation />
        </Route>
        <Route exact path="/spaces/parking/detail/vehiclesrequest">
          <VehicleByRequest />
        </Route>
        <Route exact path="/spaces/parking/detail/passengers">
          <PassengersByReservation />
        </Route>
        <Route exact path="/spaces/parking/detail/passengersrequest">
          <PassengersByRequest />
        </Route>
        <Route path="/spaces/parking/requestdetail" exact>
          <ParkingRequestDetail />
        </Route>
        {/* Workroom */}
        <Route path="/spaces/workroom/detail" exact>
          <DetailWorkroom />
        </Route>
        <Route path="/spaces/workroom/workroomdetail" exact>
          <WorkRoomDetail />
        </Route>
        <Route path="/spaces/workroom/reservationdetail" exact>
          <WorkroomReservationDetail />
        </Route>
        <Route path="/spaces/workroom/waitingdetail">
          <WorkroomWaitingDetail />
        </Route>
        {/* Locker */}
        <Route path="/spaces/locker/reservationdetail" exact>
          <LockerDetail />
        </Route>
        <Route path="/spaces/locker/downloadApp" exact>
          <LockerDownloadApp />
        </Route>
        {/* Canteen */}
        <Route path="/spaces/canteen/reservationdetail" exact>
          <CanteenReservationDetail />
        </Route>
        {/* Restaurant Table */}
        <Route path="/spaces/restaurant/reservationdetail" exact>
          <MovementDetail />
        </Route>
        <Route path="/spaces/restaurant" exact>
          <IFrameContainer></IFrameContainer>
        </Route>
        <Route path="/spaces/restaurant/orderdetail/:orderId" exact>
          <OrderDetail></OrderDetail>
        </Route>
        <Route path="/spaces/restaurant/payment/:orderId" exact>
          <OrderLoader></OrderLoader>
        </Route>
        {/* Common */}
        <Route path="/spaces/scanqrcode" exact>
          <SpacesScanQRCode />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Spaces;
