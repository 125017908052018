import { Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Confirmation from '../../Shared/Success/Confirmation';
import TransactionError from './TransactionError/TransactionError';

const RestaurantTable: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route path="/restaurant/confirmation" exact>
          <Confirmation />
        </Route>
        <Route path="/restaurant/error" exact>
          <TransactionError />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default RestaurantTable;
