import { useEffect, useState } from 'react';
import { FileSystemService } from '../_redux/services/fileSystem/fileSystem.service';

const correctExtension = (extension: string) => {
  const extensionConverter = {
    svg: 'svg+xml',
  };
  return extensionConverter[extension] ?? extension;
};

/**
 *
 * @param url image origin
 * @param fallback fallback resource, would not be wrapped or transformed if used
 * @param wrapper object containing a pre and post string to wrap the url, but NOT the fallback
 * @returns a src, base64 or fallback string
 */
export const useLoadSafeImageUrl = (
  url: string,
  fallback: string,
  wrapper: { pre: string; post: string } = { pre: '', post: '' },
) => {
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const wrap = (url: string) => {
    return wrapper.pre + url + wrapper.post;
  };

  const setFinalUrl = (url: string) => {
    setData(url);
    setIsLoading(false);
  };

  useEffect(() => {
    const generateSafeUrl = () => {
      // first default loading is tested by creating an img node
      // and setting its src to the original url
      const img = document.createElement('img');

      img.onerror = async () => {
        // if original url fails we try to load the src as base64
        // this is only possible if capacitor is loaded
        // if now, the catch will set the fallback
        try {
          const res = await FileSystemService.downloadFileAsBase64(url);
          if (!res) throw new Error('Empty base64 data');
          const extension = correctExtension(url.split('.').at(-1));
          setFinalUrl(wrap(`data:image/${extension};base64,${res}`));
        } catch (err) {
          // capacitor is not loaded or the fetch failed
          setFinalUrl(fallback);
        }
      };

      img.onload = () => {
        // on success, we set the wrapped url
        setFinalUrl(wrap(url));
      };
      // set the image src to trigger the request
      img.src = url;
    };

    generateSafeUrl();
  }, [url]);

  return { data, isLoading };
};
