import { OFFLINE_SEDES } from '../../utils/constants';
import { UserAction, UserState } from '../models/user.model';
import { userTypesEnum } from '../types/user.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  user: {
    employeeId: null,
    name: null,
    firstSurname: null,
    secondSurname: null,
    professionalMobile: null,
    landlinePhone: null,
    professionalEmail: null,
    personalEmail: null,
    district: '',
    districtName: '',
    hasValidDistrict: false,
    permissions: [],
    permissionAllHeadOffices: [],
    movingDate: null,
    defaultSede: OFFLINE_SEDES[0],
    sedesList: OFFLINE_SEDES,
    canReleasePermanent: false,
    favouriteDeskPolicy: {
      id: '',
      name: '',
      fullHoraMin: '',
      fullHoraMax: '',
    },
    deskReservationConfiguration: {
      id: 0,
      configurationName: '',
      isActive: false,
      headOffice: 0,
    },
  },
  card: {
    active: false,
    registered: false,
    fromInvitationCode: false,
    firstTimeActive: false,
    activationCodeRequested: false,
    codeRequestDate: null,
  },
  invitationCode: { included: false, code: '' },
  policies: null,
  reservationPolicies: null,
  otherPolicies: null,
  msg: null,
  isUserLangDefined: false,
  isUserAcceptedPolicies: false,
  wallet: null,
  walletV2: null,
  publicHolidays: [],
  userIsNotRegisteredInDatabase: undefined,
};

export const userReducer = (
  state: UserState = initialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case userTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case userTypesEnum.SET_CARD_ACTIVE:
      return {
        ...state,
        card: { ...state.card, active: true },
      };
    case userTypesEnum.SET_CARD_INACTIVE:
      return {
        ...state,
        card: { ...state.card, active: false },
      };
    case userTypesEnum.SET_CARD_REGISTERED:
      return {
        ...state,
        card: { ...state.card, registered: true },
      };
    case userTypesEnum.SET_CARD_ACTIVATION_REQUESTED:
      return {
        ...state,
        card: {
          ...state.card,
          activationCodeRequested: action.activationCodeRequestStatus,
        },
      };
    case userTypesEnum.SET_CARD_FROM_INVITATION:
      return {
        ...state,
        card: {
          ...state.card,
          registered: true,
          firstTimeActive: true,
          fromInvitationCode: true,
        },
      };
    case userTypesEnum.RESET_SET_CARD_FROM_INVITATION:
      return {
        ...state,
        card: { ...state.card, fromInvitationCode: false },
      };
    case userTypesEnum.RESET_SET_CARD_ACTIVE_FROM_INVITATION:
      return {
        ...state,
        card: { ...state.card, firstTimeActive: false },
      };
    case userTypesEnum.SET_CARD_UNREGISTERED:
      return {
        ...state,
        card: { ...state.card, registered: false },
      };
    case userTypesEnum.SET_INVITATION_CODE:
      return {
        ...state,
        invitationCode: { included: true, code: action.code },
      };
    case userTypesEnum.UNSET_INVITATION_CODE:
      return {
        ...state,
        invitationCode: {
          included: false,
          code: '',
        },
      };
    case userTypesEnum.RESET_CARD:
      return {
        ...state,
        card: { ...initialState.card },
      };
    case userTypesEnum.SET_USER: {
      const { defaultSede = null, sedesList = [] } = action.user;

      const defaultSedeId = defaultSede ? defaultSede?.id ?? 0 : 0;

      const sedesWithoutDefault = sedesList.filter(
        sede => sede?.id !== defaultSedeId,
      );

      const sedes = defaultSede
        ? [defaultSede, ...sedesWithoutDefault]
        : sedesWithoutDefault;

      return {
        ...state,
        user: { ...state.user, ...action.user, sedesList: sedes },
        isLoading: false,
      };
    }
    case userTypesEnum.SET_SEDE: {
      return {
        ...state,
        user: { ...state.user, defaultSede: action.userSede },
        isLoading: false,
      };
    }
    case userTypesEnum.SET_PERMISSIONS:
      return {
        ...state,
        user: { ...state.user, permissions: action.permissions },
        isLoading: false,
      };
    case userTypesEnum.SET_ALL_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          permissionAllHeadOffices: action.allPermissions,
        },
        isLoading: false,
      };
    case userTypesEnum.SET_POLICIES:
      return {
        ...state,
        policies: action.policies,
        isLoading: false,
      };
    case userTypesEnum.SET_RESERVATION_POLICIES:
      return {
        ...state,
        reservationPolicies: action.policies,
        isLoading: false,
      };
    case userTypesEnum.SET_OTHER_POLICIES:
      return {
        ...state,
        otherPolicies: action.otherPolicies,
        isLoading: false,
      };
    case userTypesEnum.SET_USER_LANG_DEFINED:
      return {
        ...state,
        isUserLangDefined: true,
        isLoading: false,
      };
    case userTypesEnum.SET_USER_ACCEPTED_POLICIES:
      return {
        ...state,
        isUserAcceptedPolicies: true,
      };
    case userTypesEnum.FAILURE_USER:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case userTypesEnum.SET_MSG:
      return {
        ...state,
        msg: action.msg,
        isLoading: false,
      };
    case userTypesEnum.RESET_MESSAGE:
      return {
        ...state,
        msg: null,
      };
    case userTypesEnum.SET_WALLET:
      return {
        ...state,
        wallet: action.wallet,
        isLoading: false,
      };
    case userTypesEnum.SET_WALLETV2:
      return {
        ...state,
        walletV2: action.walletV2,
        isLoading: false,
      };
    case userTypesEnum.SET_PUBLIC_HOLIDAYS:
      return {
        ...state,
        publicHolidays: action.publicHolidays,
        isLoading: false,
      };
    case userTypesEnum.SET_HAS_VALID_DISTRICT:
      return {
        ...state,
        user: { ...state.user, hasValidDistrict: action.hasValidDistrict },
        isLoading: false,
      };
    case userTypesEnum.SET_USER_HEADOFFICE:
      return {
        ...state,
        user: {
          ...state.user,
          defaultSede: action.headOffice.defaultSede,
          sedesList: action.headOffice.sedesList,
          userIsRegisteredInDatabase: true,
        },
        isLoading: false,
      };
    case userTypesEnum.SET_USER_IS_REGISTERED_IN_DATABASE:
      return {
        ...state,
        user: {
          ...state.user,
          userIsRegisteredInDatabase: action.userIsRegisteredInDatabase,
        },
        isLoading: false,
      };
    case userTypesEnum.RESET_USER:
      return initialState;
    case userTypesEnum.SET_CODE_REQUEST_DATE:
      return {
        ...state,
        card: {
          ...state.card,
          codeRequestDate: action.codeRequestDate,
        },
      };
    default:
      return state;
  }
};
