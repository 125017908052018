import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import ServiceDetailContent from './components/ServiceDetailContent';
import styles from './styles.module.scss';

type Props = {
  title: string;
  info: {
    name: string;
    details: string;
  };
};

export const ServiceDetail = (props: Props) => {
  const { title, info } = props;
  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={title}
        />
      </IonHeader>
      <IonContent>
        <div className={`${styles.gridHeader} ${styles.templateDetailContent}`}>
          {info && (
            <ServiceDetailContent
              name={info.name}
              details={info.details}
            ></ServiceDetailContent>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
