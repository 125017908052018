import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

/**
 * Renders a hidden icon to preload the font used for icons,
 * helping to prevent rendering defects related to typography when the icon
 * font is loaded asynchronously.
 *
 * The icon is visually hidden but still available for preloading.
 */
export const PreLoadIconsFont = () => {
  return (
    <Icon
      className={`icon icon-minus ${styles.visuallyHidden}`}
      aria-hidden="true"
    />
  );
};
