import { Button, Icon, Item, TopBar } from '@acciona/ui-ionic-kit';
import { IonContent, IonHeader, IonLoading, IonPage } from '@ionic/react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { MapWorkRoom } from '../../../../components/MapWorkRoom/MapWorkRoom';

import { UserStore } from '../../../../_redux/models/user.model';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import SafeImage from '../../../../components/SafeImage/SafeImage';
import styles from './styles.module.scss';

const WorkRoomDetail: React.FC = () => {
  const { t } = useTranslation();
  const { workroomDetail, isLoading } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );
  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const location = useLocation().state as any;
  const code = location.code || null;
  const history = useHistory();

  useEffect(() => {
    const backFunction = ev => {
      ev.preventDefault();
      ev.detail.register(10, () => {
        code
          ? history.replace('/profile/search', { searchWord: code })
          : history.goBack();
      });
    };
    document.addEventListener('ionBackButton', backFunction);
    return () => {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);
  return (
    <IonPage>
      <IonLoading
        isOpen={isLoading}
        message={t('msg_loading')}
        duration={2000}
      />
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            <Button
              onClick={() =>
                code
                  ? history.replace('/profile/search', { searchWord: code })
                  : history.goBack()
              }
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('detail_workroom')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div>
          {workroomDetail?.name && (
            <DetailCard
              id="map-detail-card"
              name="Sala"
              subName={workroomDetail?.descriptionType}
              type="workroom"
              icon="icon icon-room"
              title={`${t('workroom')} ${workroomDetail?.name}`}
            ></DetailCard>
          )}

          <div className={styles.gridWeb}>
            <div className={styles.listGrid}>
              {workroomDetail?.imageLink && (
                <Item lines="none" className={styles.imgContainer}>
                  <SafeImage
                    className={styles.imgStyle}
                    src={workroomDetail.imageLink}
                    fallback="/assets/images/workroom1.jpg"
                  />
                </Item>
              )}
              <DetailList className={styles.itemsContent}>
                {workroomDetail?.equipmentItems?.length > 0 && (
                  <DetailListItem
                    className={styles.equipment}
                    startIcon="icon icon-equipments"
                    startIconColor="primary"
                    title={t('lbl_equipment')}
                    description={workroomDetail.equipmentItems.join(', ')}
                  />
                )}
                {workroomDetail?.capacity && (
                  <DetailListItem
                    startIcon="icon icon-occupation"
                    startIconColor="primary"
                    title={t('lbl_allowed_capacity')}
                    description={t('lbl_min_members', {
                      qty: workroomDetail?.capacity,
                    })}
                  />
                )}
                {workroomDetail?.descriptionSede && (
                  <DetailListItem
                    startIcon="icon icon-explore"
                    startIconColor="primary"
                    title={t('lbl_head_office')}
                    description={workroomDetail?.descriptionSede}
                  />
                )}
                {workroomDetail?.location && (
                  <DetailListItem
                    startIcon="icon icon-campus"
                    startIconColor="primary"
                    title={`${t('building_filter_workroom')} ${
                      workroomDetail?.location?.buildingName
                    } , ${workroomDetail?.location?.floorName}`}
                    description={
                      districtName === workroomDetail.location?.district ? (
                        <div className={styles.locatdivs}>
                          <Icon
                            color="primary"
                            className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
                          />
                          {t('lbl_your_district')}
                        </div>
                      ) : (
                        `${t('lbl_district')}: ${
                          workroomDetail?.location?.district
                        }`
                      )
                    }
                  />
                )}
              </DetailList>

              {workroomDetail?.mapImage && (
                <div className={styles.mapContainer}>
                  {
                    <MapWorkRoom
                      x={workroomDetail.x}
                      y={workroomDetail.y}
                      mapImage={workroomDetail.mapImage}
                    />
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WorkRoomDetail;
