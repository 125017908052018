export enum userTypesEnum {
  REQUEST = 'FETCH_USER_REQUEST',
  FAILURE_USER = 'FAILURE_USER',
  RESET_MESSAGE = 'RESET_MESSAGE',
  SET_USER = 'SET_USER',
  SET_CARD_ACTIVE = 'SET_CARD_ACTIVE',
  SET_CARD_REGISTERED = 'SET_CARD_REGISTERED',
  SET_CARD_FROM_INVITATION = 'SET_CARD_FROM_INVITATION',
  SET_CARD_UNREGISTERED = 'SET_CARD_UNREGISTERED',
  RESET_SET_CARD_FROM_INVITATION = 'RESET_SET_CARD_FROM_INVITATION',
  RESET_SET_CARD_ACTIVE_FROM_INVITATION = 'RESET_SET_CARD_ACTIVE_FROM_INVITATION',
  RESET_CARD = 'RESET_CARD',
  SET_CARD_INACTIVE = 'SET_CARD_INACTIVE',
  SET_INVITATION_CODE = 'SET_INVITATION_CODE',
  UNSET_INVITATION_CODE = 'UNSET_INVITATION_CODE',
  SET_PERMISSIONS = 'SET_SEDE_PERMISSIONS',
  SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS',
  SET_POLICIES = 'SET_POLICIES',
  SET_RESERVATION_POLICIES = 'SET_RESERVATION_POLICIES',
  SET_OTHER_POLICIES = 'SET_OTHER_POLICIES',
  SET_USER_LANG_DEFINED = 'SET_USER_LANG_DEFINED',
  SET_USER_ACCEPTED_POLICIES = 'SET_USER_ACCEPTED_POLICIES',
  SET_MSG = 'SET_MSG',
  RESET_USER = 'RESET_USER',
  SET_WALLET = 'SET_WALLET',
  SET_WALLETV2 = 'SET_WALLETV2',
  SET_PUBLIC_HOLIDAYS = 'SET_PUBLIC_HOLIDAYS',
  SET_CARD_ACTIVATION_REQUESTED = 'SET_CARD_ACTIVATION_REQUESTED',
  SET_SEDE = 'SET_SEDE',
  SET_HAS_VALID_DISTRICT = 'SET_HAS_VALID_DISTRICT',
  SET_USER_HEADOFFICE = 'SET_USER_HEADOFFICE',
  SET_USER_IS_REGISTERED_IN_DATABASE = 'SET_USER_IS_REGISTERED_IN_DATABASE',
  SET_CODE_REQUEST_DATE = 'SET_CODE_REQUEST_DATE',
}
