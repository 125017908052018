import { TopBar, SearchBar, FilterChip, Toast } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import {
  NewsActions,
  searchActions,
  workroomActions,
} from '../../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SearchStore } from '../../../_redux/models/search.model';
import { classes } from './classes';
import _ from 'lodash';
import dayjs from 'dayjs';
import { history } from '../../../_helpers/history';
import { isEmpty } from '../../../utils/functions';
import { ListCard } from '../../../components/Base/ListCard';
import { NewsSecondaryCard } from '../../../components/Base/NewsSecondaryCard';
import SearchDashboard from './SearchDashboard/SearchDashboard';
import useDataNews from '../../../hooks/useDataNews';
import Can from '../../../components/Can/Can';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { DetailSearchPerson } from '../../../components/Base/DetailSearchPerson';
import { useLocationState } from '../../../hooks/useLocationState';

const Search: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [qty, setQTY] = useState({
    all: 0,
    desk: 0,
    room: 0,
    parking: 0,
    person: 0,
    news: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [workRoomData, setWorkRoomData] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [placeholder, setPlaceholder] = useState(
    `${t('lbl_search_placeholder')}`,
  );
  const { search, isLoading, error, lastRequestTime } = useSelector(
    (store: SearchStore) => store.search,
  );

  const newsCard = useDataNews(_.has(search, 'news') && search['news']);
  const state = useLocationState() as any;
  const filterTypes = [
    {
      id: 'all',
      text: `${t('lbl_filter_all')}`,
      checked: true,
    },
    {
      id: 'person',
      text: `${t('lbl_persons')}`,
      checked: false,
      icon: 'client',
    },
    {
      id: 'news',
      text: `${t('tab_news')}`,
      checked: false,
      icon: 'app',
    },
    {
      id: 'room',
      text: `${t('tab_rooms')}`,
      checked: false,
      icon: 'app',
    },
  ];
  const [filterType, setFilterType] = useState(filterTypes);
  const handleSearchSearchChange = e => {
    if (e.detail.value.length > 2) {
      const newRequestTime = Date.now();
      setSearchWord(e.detail.value!);
      if (newRequestTime > lastRequestTime) {
        dispatch(searchActions.getSearch(e.detail.value!, newRequestTime));
      }
    } else {
      setSearchWord('');
      dispatch(searchActions.resetSearch());
    }
  };

  const handleSearchFilterChange = data => {
    const { id } = data;
    setSelectedFilter(id);
    setFilterType(
      [...filterType].map(object => {
        if (object.id !== id) {
          return {
            ...object,
            checked: false,
          };
        } else if (object.checked === false) {
          return {
            ...object,
            checked: true,
          };
        } else {
          return { ...object };
        }
      }),
    );
  };

  const handleDetailPerson = employeeId => {
    dispatch(searchActions.getSearchPersonDetail(employeeId));
  };

  const handleCancelSearch = () => {
    setQTY({ all: 0, desk: 0, room: 0, parking: 0, person: 0, news: 0 });
    setWorkRoomData([]);
    setSearchWord('');
    dispatch(searchActions.resetSearch());
  };

  const searchSuggested = searchWords => {
    setSearchWord(searchWords);
    dispatch(searchActions.getSearch(searchWords, Date.now()));
    setPlaceholder(searchWords);
  };

  const handleDetailWorkRoom = (workRoomCode: string) => {
    dispatch(workroomActions.getSearchWorkroomDetail(workRoomCode));
  };

  useEffect(() => {
    let qtys = {
      all: 0,
      desk: 0,
      room: 0,
      parking: 0,
      person: 0,
      news: 0,
    };

    setWorkRoomData([]);
    if (_.has(search, 'desk')) qtys = { ...qtys, desk: 1 };
    if (_.has(search, 'person'))
      qtys = { ...qtys, person: search['person'].length };
    if (_.has(search, 'news')) qtys = { ...qtys, news: search['news'].length };

    if (_.has(search, 'parking')) qtys = { ...qtys, parking: 1 };
    if (_.has(search, 'room')) {
      const reservation = _.has(search, 'room.reservations') ? 1 : 0;

      qtys = {
        ...qtys,
        room: search['room'].rooms?.length + reservation,
      };
      const dataRoom = [];
      search['room'].rooms &&
        search['room'].rooms.forEach(data => {
          const icons = [
            {
              id: 1,
              visible: true,
              type: 'label',
              icon: data?.equipment,
              slot: 'start',
            },
            {
              id: 2,
              visible: true,
              type: 'icon',
              icon: 'icon icon-occupation',
              slot: 'end',
            },
            {
              id: 3,
              visible: true,
              type: 'label',
              icon: data.capacity.toString(),
              slot: 'end',
            },
          ];
          dataRoom.push({
            id: data.spaceId.toString(),
            title: data.name,
            description: `${data.address} | ${data.floor}`,
            imageURL: data?.imageS3Link ?? '',
            actions: icons,
            code: data.name, // data.name because sometimes code is different from name, and search dont find the room when back from detail
            district: data?.district,
            equipmentItems: data?.equipmentItems,
          });
        });

      setWorkRoomData(dataRoom);
    }
    qtys = {
      ...qtys,
      all:
        search?.results ??
        qtys.desk + qtys.person + qtys.news + qtys.parking + qtys.room,
    };
    setQTY(qtys);
  }, [search]);

  useEffect(() => {
    setQTY({
      all: 0,
      desk: 0,
      room: 0,
      parking: 0,
      person: 0,
      news: 0,
    });
    setWorkRoomData([]);
    dispatch(searchActions.resetSearch());
    dispatch(NewsActions.getCategories());
  }, []);

  useEffect(() => {
    if (state?.searchWord) {
      const newRequestTime = Date.now();
      setSearchWord(state?.searchWord);
      setPlaceholder(state?.searchWord);
      dispatch(searchActions.getSearch(state?.searchWord, newRequestTime));
    }
  }, [state]);

  const handleSelectNew = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname));
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_search_page')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <SearchBar
            autoCapitalize="sentences"
            className={styles.searchBar}
            autocomplete="on"
            onIonInput={handleSearchSearchChange}
            onIonClear={handleCancelSearch}
            placeholder={placeholder}
            onIonFocus={() => setPlaceholder('')}
            debounce={800}
          />

          {isEmpty(searchWord) ? (
            <SearchDashboard
              setSuggestedSearchWord={searchSuggested}
            ></SearchDashboard>
          ) : (
            <>
              {isLoading ? (
                <div className="ion-text-center">
                  <IonSpinner />
                </div>
              ) : search && search.results === 0 ? (
                <DetailListItem title={t('lbl_not_results')} />
              ) : (
                search?.results && (
                  <DetailListItem className={styles.filters} lines="full">
                    <div className={styles.gridscroll}>
                      {filterType.map((item, index) => {
                        return (
                          qty[item.id] > 0 && (
                            <FilterChip
                              key={index}
                              id={item.id}
                              text={`${item.text} (${qty[item.id]})`}
                              checked={item.checked}
                              onChange={handleSearchFilterChange}
                            />
                          )
                        );
                      })}
                    </div>
                  </DetailListItem>
                )
              )}

              {(selectedFilter == 'all' || selectedFilter == 'desk') &&
                _.has(search, 'desk') && (
                  <IonGrid className={`${styles.generalStyleCard}`}>
                    <IonRow className={styles.paddingCard}>
                      <IonCol size="2.5">
                        <IonRow>
                          <div
                            className={`${styles.divIcon} ${classes['workstation']}`}
                          >
                            <IonIcon
                              className={`icon icon-seat ${styles.iconFont}`}
                            />
                          </div>
                        </IonRow>
                      </IonCol>
                      <IonCol size="8">
                        <IonRow className={`${styles.cardText} `}>
                          {`${search['desk'].qty} ${t('workstations_reserved', {
                            multiple: search['desk'].qty > 1 ? 's' : '',
                          })}`}
                        </IonRow>
                        <IonRow className={`${styles.cardSubtext}`}>
                          {' '}
                          {t('date_between', {
                            dateini: dayjs(search['desk'].dateMin).format(
                              'DD MMM',
                            ),
                            dateend: dayjs(search['desk'].dateMax).format(
                              'DD MMM',
                            ),
                          })}
                        </IonRow>
                        <IonRow
                          className={`${styles.cardLink}`}
                          onClick={() =>
                            history.replace('/dashboard/reservations', {
                              filterSpace: 'Desk',
                            })
                          }
                        >
                          {t('show_desk_reserved')}
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              {(selectedFilter == 'all' || selectedFilter == 'parking') &&
                _.has(search, 'parking') && (
                  <IonGrid className={`${styles.generalStyleCard}`}>
                    <IonRow className={styles.paddingCard}>
                      <IonCol size="2.5">
                        <IonRow>
                          <div
                            className={`${styles.divIcon} ${classes['parking']}`}
                          >
                            <IonIcon
                              className={`icon icon-parking ${styles.iconFont}`}
                            />
                          </div>
                        </IonRow>
                      </IonCol>
                      <IonCol size="8">
                        <IonRow className={`${styles.cardText} `}>
                          {`${search['parking'].qty} ${t('parking_reserved', {
                            multiple: search['parking'].qty > 1 ? 's' : '',
                          })}`}
                        </IonRow>
                        <IonRow className={`${styles.cardSubtext}`}>
                          {t('date_between', {
                            dateini: dayjs(search['parking'].dateMin).format(
                              'DD MMM',
                            ),
                            dateend: dayjs(search['parking'].dateMax).format(
                              'DD MMM',
                            ),
                          })}
                        </IonRow>
                        <IonRow
                          className={`${styles.cardLink}`}
                          onClick={() =>
                            history.replace('/dashboard/reservations', {
                              filterSpace: 'Parking',
                            })
                          }
                        >
                          {t('show_parkings_reserved')}
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              {(selectedFilter == 'all' || selectedFilter == 'room') &&
              _.has(search, 'room.reservations') ? (
                <IonGrid className={`${styles.generalStyleCard}`}>
                  <IonRow className={styles.paddingCard}>
                    <IonCol size="2.5">
                      <IonRow>
                        <div
                          className={`${styles.divIcon} ${classes['workroom']}`}
                        >
                          <IonIcon
                            className={`icon icon-room ${styles.iconFont}`}
                          />
                        </div>
                      </IonRow>
                    </IonCol>
                    <IonCol size="8">
                      <IonRow className={`${styles.cardText} `}>
                        {`${search['room'].reservations.qty} ${t(
                          'workroom_reserved',
                          {
                            multiple:
                              search['room'].reservations.qty > 1 ? 's' : '',
                          },
                        )}`}
                      </IonRow>
                      <IonRow className={`${styles.cardSubtext}`}>
                        {t('date_between', {
                          dateini: dayjs(
                            search['room'].reservations.dateMin,
                          ).format('DD MMM'),
                          dateend: dayjs(
                            search['room'].reservations.dateMax,
                          ).format('DD MMM'),
                        })}
                      </IonRow>
                      <IonRow
                        className={`${styles.cardLink}`}
                        onClick={() =>
                          history.replace('/dashboard/reservations', {
                            filterSpace: 'Room',
                          })
                        }
                      >
                        {t('show_rooms_reserved')}
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              ) : null}

              {(selectedFilter == 'all' || selectedFilter == 'person') &&
              _.has(search, 'person') &&
              !isEmpty(search['person']) ? (
                <div className={styles.lists}>
                  <DetailListItem
                    title={t('lbl_persons')}
                    titleColor="primary"
                    className={styles.personsTitle}
                  />
                  {search['person'].map((item, index) => (
                    <DetailSearchPerson
                      key={index}
                      onClick={() => handleDetailPerson(item.employeeId)}
                      description={item.fullName}
                      footnote={item.function}
                    />
                  ))}
                </div>
              ) : null}
              <Can functionality="news">
                {(selectedFilter == 'all' || selectedFilter == 'news') &&
                !isEmpty(newsCard) ? (
                  <DetailListItem
                    className={styles.lists}
                    lines="full"
                    title={t('lbl_relevant_news')}
                    titleColor="primary"
                    actionText={
                      <span
                        className={styles.paddingRight}
                        onClick={() => history.replace('/dashboard/news')}
                      >
                        {t('lbl_show_all_news')}
                      </span>
                    }
                  />
                ) : null}

                {(selectedFilter == 'all' || selectedFilter == 'news') &&
                !isEmpty(newsCard) ? (
                  <div className={styles.lists}>
                    <IonRow>
                      {newsCard.map(data => (
                        <IonCol
                          key={data.id}
                          size="12"
                          sizeSm="6"
                          sizeMd="6"
                          sizeLg="6"
                          sizeXl="4"
                          className={styles.colAlignCenter}
                        >
                          <NewsSecondaryCard
                            data={{
                              ...data,
                              imageURL: data.id,
                            }}
                            onClick={() => handleSelectNew(data.id)}
                            mode="ios"
                            displaySocial={false}
                          />
                        </IonCol>
                      ))}
                    </IonRow>
                  </div>
                ) : null}
              </Can>
              {(selectedFilter == 'all' || selectedFilter == 'room') &&
              workRoomData &&
              !isEmpty(workRoomData) ? (
                <div className={styles.lists}>
                  <IonRow>
                    {workRoomData &&
                      workRoomData.map((wr, index) => {
                        return (
                          <IonCol
                            key={index}
                            size="12"
                            sizeSm="6"
                            sizeMd="6"
                            sizeLg="6"
                            sizeXl="4"
                            className={styles.colAlignCenter}
                          >
                            <ListCard
                              isWorkroom
                              data={wr}
                              fallbackImg={'/assets/images/workroom1.jpg'}
                              className={styles.listCardResponsive}
                              onClick={() => handleDetailWorkRoom(wr.code)}
                            />
                          </IonCol>
                        );
                      })}
                  </IonRow>
                </div>
              ) : null}
            </>
          )}
        </div>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
      </IonContent>
    </IonPage>
  );
};

export default Search;
