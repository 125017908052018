import {
  IonHeader,
  IonContent,
  IonFooter,
  IonLabel,
  IonCheckbox,
  IonToolbar,
  IonButton,
  IonIcon,
  IonTitle,
  isPlatform,
} from '@ionic/react';

import { Button, Modal } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { Props } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';
import { DetailListItem } from '../../../../components/Base/DetailListItem';

const BuildingModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  let update = true;
  const buildings = useSelector(
    (state: WorkroomStore) => state.workroom.buildings,
  );
  useEffect(() => {
    dispatch(workroomActions.getBuildings());
  }, []);

  useEffect(() => {
    props.value && setSelectedBuildings(props.value);
  }, [props.value]);

  useEffect(() => {
    const pluck = buildings.map(building => building['id']);
    setSelectedBuildings(pluck);
  }, [buildings]);

  const handleSelectBuilding = value => {
    if (update) {
      const idSelected = parseInt(value.detail.value);

      if (value.detail.checked) {
        !selectedBuildings.includes(idSelected) &&
          setSelectedBuildings(prevState => [...prevState, idSelected]);
      } else {
        const filterBuilding = [...selectedBuildings].filter(
          id => id !== idSelected,
        );
        setSelectedBuildings(filterBuilding);
      }
    }
  };

  const confirmBuilding = () => {
    if (isEmpty(selectedBuildings)) {
      const allBuildings = buildings.map(building => building['id']);
      update = false;
      setSelectedBuildings(allBuildings);
      props.setValue(allBuildings);
    } else {
      props.setValue(selectedBuildings);
    }
    props.showModal(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={confirmBuilding}
      backdropDismiss={false}
    >
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButton
            className={styles.btnBack}
            fill="clear"
            onClick={() => props.showModal(false)}
          >
            <IonIcon className="icon icon-chevron-left" />
            <IonTitle className={styles.title}>
              {t('builidng_workroom')}
            </IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          {buildings &&
            buildings.map(({ name, id }, i) => (
              <DetailListItem key={i} lines="inset" type="button">
                <IonCheckbox
                  slot={isPlatform('ios') ? 'start' : 'end'}
                  mode="ios"
                  value={id.toString()}
                  onIonChange={value => handleSelectBuilding(value)}
                  checked={selectedBuildings.includes(id)}
                  className={styles.checkbox}
                />
                <IonLabel>{name}</IonLabel>
              </DetailListItem>
            ))}
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={confirmBuilding}>
              {t('btn_confirm_building_room')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </Modal>
  );
};
export default BuildingModal;
