import { useState } from 'react';
import { isPlatform } from '@ionic/react';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useTranslation } from 'react-i18next';

interface OpenExternalVideoPlayerProps {
  videoUrl: string;
}

const extractUrl = (url: string): string => {
  const index = url.indexOf('/callback');
  if (index !== -1) {
    return url.substring(0, index);
  }
  return url;
};

export const useVideoPlayer = (videoUrl: string | '') => {
  const [showVideo, setShowVideo] = useState(false);
  const [playError, setPlayError] = useState('');
  const { t } = useTranslation();

  const openExternalVideoPlayer = ({
    videoUrl,
  }: OpenExternalVideoPlayerProps) => {
    const videoId = videoUrl.split('/').reverse()[0];
    const options = `location=no,clearcache=yes`;
    const destinationUrl =
      extractUrl(process.env.REACT_APP_COGNITO_WEB_REDIRECT_URI) +
      `/news/video/${videoId}`;

    const externalBrowser = InAppBrowser.create(
      destinationUrl,
      '_blank',
      options,
    );

    externalBrowser.on('loaderror').subscribe(() => {
      setPlayError(t('error_default'));
    });

    externalBrowser.on('loadstart').subscribe(event => {
      if (event.url.includes('exit')) {
        closeVideo();
        externalBrowser.close();
      }
    });
  };

  const playVideo = () => {
    if (isPlatform('capacitor')) {
      openExternalVideoPlayer({ videoUrl });
    } else {
      setShowVideo(true);
    }
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  return {
    showVideo,
    playVideo,
    closeVideo,
    playError,
  };
};
