import React, { useState, useEffect, useMemo } from 'react';
import {
  IonPage,
  IonHeader,
  IonButton,
  IonContent,
  IonFooter,
  useIonViewDidEnter,
  IonLoading,
  IonList,
  IonItem,
  IonIcon,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TopBar,
  Button,
  Toast,
  WeekCalendarMultiselector,
  Icon,
  DayCalendar,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { parkingActions } from '../../../../_redux/actions/parking.actions';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import styles from './styles.module.scss';
import { AppStore } from '../../../../_redux/models/app.model';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { reservationActions } from '../../../../_redux/actions';
import { Legend } from '../../../../components/Base/Legend';
import { LegendItem } from '../../../../components/Base/LegendItem';
import { UserStore } from '../../../../_redux/models/user.model';
import dayjs from 'dayjs';
import _ from 'lodash';
import { settingsReservations } from '../../types';
import SettingsModal from '../../Workstation/SettingsModal';
import { POLICIES_SCHEDULES } from '../../../../utils/constants';
import {
  checkReservations,
  getIconSchedule,
  getTypeLabel,
} from '../../helpers';
import { ReservationCard } from '../../../../components/Base/ReservationCard';
import { getNumberOfWeeks, showPolicies } from '../../../../utils/functions';
import { getReservationSede } from './helpers';
import { useHistory } from 'react-router';

const Calendar: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [showToastErrorParking, setShowToastErrorParking] = useState(false);
  const [msgErrorReservation, setMsgErrorReservation] = useState('');
  const [showToastHomeOffice, setShowToastHomeOffice] = useState(false);
  const [datesSelected, setDates] = useState<string[]>([]);
  // const [reservableDays, setReservablesDays] = useState<string[]>([]);
  const [showCalendar, setshowCalendar] = useState<boolean>(false);
  const [calendarIsDisabled, setCalendarIsDisabled] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [nonSelectable, setNonSelectable] = useState<string[]>([]);
  const [numWeeks, setNumWeeks] = useState<number>(1);
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const { homeOfficeReservationAvailable, campusTimeZone } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const [minDate, setMinDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 00:00:00')).toISOString()),
  );
  const [maxDate, setMaxDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 23:59:00')).toISOString()),
  );

  const [settingsReservation, setSettingsReservation] =
    useState<settingsReservations>(null);

  const {
    user: { favouriteParkingPolicy },
  } = useSelector((state: UserStore) => state.user);

  const {
    error: errorParking,
    isLoading: isLoadingParking,
    configurations,
    reservableDays,
  } = useSelector((state: ParkingStore) => state.parking);

  const {
    reservedParkingDays: reservedPkDays,
    reservations: reservationsByOwner,
    homeOfficeDays: reservedHomeOfficeDays,
    isLoading: isLoadingReservation,
    error: errorReservation,
  } = useSelector((state: ReservationStore) => state.reservation);

  const handleSelectDate = dates => {
    if (
      !reservableDays.some(day => day === dayjs(dates).format('YYYY-MM-DD'))
    ) {
      return;
    }

    if (
      reservedHomeOfficeDays.includes(dayjs(dates).format('YYYY-MM-DD')) &&
      !nonSelectable.includes(dayjs(dates).format('YYYY-MM-DD'))
    ) {
      setShowToastHomeOffice(true);
      if (!homeOfficeReservationAvailable) return;

      setDates([...datesSelected, dayjs(dates).format('YYYY-MM-DD')]);
    }

    if (datesSelected.includes(dayjs(dates).format('YYYY-MM-DD'))) {
      setDates(
        datesSelected.filter(d => d != dayjs(dates).format('YYYY-MM-DD')),
      );
    } else if (!nonSelectable.includes(dayjs(dates).format('YYYY-MM-DD'))) {
      setDates([...datesSelected, dayjs(dates).format('YYYY-MM-DD')]);
    } else {
      setMsgErrorReservation(
        dayjs(dates).isToday()
          ? t('msg_error_incompatible_today')
          : t('msg_error_incompatible_schedule'),
      );
    }
  };

  const handleSelectDateParking = () => {
    const typeReservation =
      POLICIES_SCHEDULES?.find(e => e.value == settingsReservation.schedule)
        ?.id || null;
    const configReservations =
      configurations?.policies?.find(e => typeReservation == e.nombre) || null;

    const hourStart =
      settingsReservation.schedule === 'custom_schedule'
        ? settingsReservation?.customStart
        : configReservations?.fullHoraMin ?? '00:00';
    const hourEnd =
      settingsReservation.schedule === 'custom_schedule'
        ? settingsReservation?.customEnd
        : configReservations?.fullHoraMax ?? '23:59';

    dispatch(
      parkingActions.searchParkingLastMinute(
        datesSelected.map(d => `${d}T00:00:00.000Z`),
        null,
        null,
        typeReservation,
        hourStart,
        hourEnd,
        `${t('day_label', {
          number: datesSelected.length,
          multiple: datesSelected.length > 1 ? 's' : '',
        })} | ${getHeaderText} `,
      ),
    );
  };

  const handleDismissToast = () => {
    setShowToastErrorParking(false);
    setShowToastHomeOffice(false);
    dispatch(parkingActions.resetErrorParking());
  };

  const handleDismissToastReservation = () => {
    setMsgErrorReservation('');
  };

  const dataLegend = [
    { type: 'parking', text: t('lbl_res_parking') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  useIonViewDidEnter(() => {
    !showCalendar && setshowCalendar(true);
  });

  useEffect(() => {
    dispatch(parkingActions.setParkings([]));
  }, []);

  useEffect(() => {
    setMinDate(_.first(reservableDays));
    setMaxDate(_.last(reservableDays));
    const firstDate = dayjs(_.first(reservableDays))
      .startOf('day')
      .toISOString();
    const lastDate = dayjs(_.last(reservableDays)).endOf('day').toISOString();
    dispatch(
      reservationActions.getReservedDaysPkAndHomeOffice(firstDate, lastDate),
    );
    setNumWeeks(getNumberOfWeeks(firstDate, lastDate));
    //     setNonSelectable([]);
  }, [reservableDays]);

  useEffect(() => {
    errorParking && setShowToastErrorParking(true);
  }, [errorParking]);

  useEffect(() => {
    if (errorReservation) {
      setMsgErrorReservation(errorReservation);
      setCalendarIsDisabled(true);
    }
    return () => {
      dispatch(reservationActions.resetErrorReservations());
    };
  }, [errorReservation]);

  useEffect(() => {
    if (reservedPkDays && configurations && settingsReservation) {
      setNonSelectable(
        checkReservations(
          settingsReservation,
          reservedPkDays,
          campusTimeZone,
          configurations,
        ),
      );
    }
  }, [reservedPkDays, configurations, settingsReservation]);

  useEffect(() => {
    if (favouriteParkingPolicy && configurations) {
      settingsReservation &&
        setNonSelectable(
          checkReservations(
            {
              ...settingsReservation,
              schedule: POLICIES_SCHEDULES?.find(
                e => e.id == favouriteParkingPolicy.nombre,
              ).value,
              customStart: favouriteParkingPolicy?.fullHoraMin ?? '00:00',
              customEnd: favouriteParkingPolicy?.fullHoraMax ?? '23:00',
            },
            reservedPkDays,
            campusTimeZone,
            configurations,
          ),
        );
      setSettingsReservation({
        ...settingsReservation,
        schedule: POLICIES_SCHEDULES?.find(
          e => e.id == favouriteParkingPolicy.nombre,
        ).value,
        customStart:
          favouriteParkingPolicy.nombre === 'Personalizada'
            ? favouriteParkingPolicy?.fullHoraMin
            : configurations?.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMin ?? '00:00',
        customEnd:
          favouriteParkingPolicy.nombre === 'Personalizada'
            ? favouriteParkingPolicy?.fullHoraMax
            : configurations?.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMax ?? '23:59',
      });
    }
  }, [favouriteParkingPolicy, configurations]);

  const getHeaderText = useMemo(() => {
    if (settingsReservation) {
      const schedule =
        settingsReservation.schedule == 'custom_schedule'
          ? `${settingsReservation.customStart}-${settingsReservation.customEnd}`
          : t(settingsReservation.schedule + '_no_hours');

      if (settingsReservation.config)
        return `${schedule} | ${t(settingsReservation.config)}`;
      return `${schedule}`;
    } else {
      return '';
    }
  }, [settingsReservation]);

  const handleShowSettings = () => {
    setShowSettings(true);
  };

  const handleOnCloseOption = () => {
    setDates([]);

    setNonSelectable(
      checkReservations(
        settingsReservation,
        reservedPkDays,
        campusTimeZone,
        configurations,
      ),
    );
  };
  const handleOnclickBackbutton = () => {
    history.push('/dashboard/actions');
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonButton
              onClick={handleOnclickBackbutton}
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('calendar_title')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.snackbar}>
          <div className={styles.calendarSnackbarContent}>
            <div className={styles.firstText}>{getHeaderText}</div>

            <div className={styles.lastIcon}>
              {configurations && showPolicies(configurations.policies) && (
                <Icon
                  slot="end"
                  className={`icon icon-settings icon-20`}
                  onClick={handleShowSettings}
                />
              )}
            </div>
          </div>
        </div>
        <Toast
          isOpen={showToastErrorParking}
          onDidDismiss={handleDismissToast}
          message={errorParking}
          position="bottom"
          type="error"
        ></Toast>
        <Toast
          isOpen={!!msgErrorReservation}
          onDidDismiss={handleDismissToastReservation}
          message={msgErrorReservation}
          position="bottom"
          type="error"
        ></Toast>
        <Toast
          isOpen={showToastHomeOffice}
          onDidDismiss={handleDismissToast}
          message={
            homeOfficeReservationAvailable
              ? t('msg_homeoffice')
              : t('msg_not_reservation_homeoffice')
          }
          position="bottom"
          type={homeOfficeReservationAvailable ? 'homeOffice' : 'error'}
        />
        <div className={styles.gridWeb}>
          <div
            className={`${styles.parkingCalendar} ${styles.calendarGrid} ${
              isLoadingParking || isLoadingReservation || calendarIsDisabled
                ? styles.calendarDisabled
                : ''
            }`}
          >
            {showCalendar && (
              <>
                <WeekCalendarMultiselector
                  selected={datesSelected}
                  reserved={reservedPkDays
                    .filter(e => !nonSelectable.includes(e.date))
                    .map(e => e.date)}
                  homeOffice={reservedHomeOfficeDays}
                  onChange={handleSelectDate}
                  locale={localLanguage}
                  weeks={numWeeks}
                  maxSelectedDays={20}
                  reservableDays={reservableDays.filter(
                    e =>
                      !nonSelectable.includes(e) ||
                      reservedPkDays.map(e => e.date).includes(e),
                  )}
                  reservedNonSelectable={nonSelectable.filter(e =>
                    reservedPkDays.map(r => r.date).includes(e),
                  )}
                  minDate={minDate}
                  maxDate={maxDate}
                  classNames={{
                    notAvailable: styles.notAvailable,
                    homeOffice: styles.homeOffice,
                    reserved: styles.reserved,
                    reservedNonSelectable: styles.reservedNonSelectable,
                    selected: styles.selected,
                    today: styles.today,
                    available: styles.available,
                  }}
                />
                <div className={styles.messageCalendar}>
                  {t('message_only_reservable')}
                </div>
              </>
            )}
          </div>
          {!_.isEmpty(datesSelected) && (
            <IonList className={styles.listReservations}>
              {reservedPkDays
                .filter(e => datesSelected.includes(e.date))
                .map(item => (
                  <IonItem
                    key={item.hours.start}
                    lines="none"
                    className={styles.itemList}
                  >
                    <ReservationCard
                      data={{
                        id: item?.date,
                        type: 'Parking',
                        header: item.spaceCode,
                        title: item?.headOffice?.description,
                        subtitle: getTypeLabel(item, configurations),
                        descriptionSede: getReservationSede(
                          item,
                          reservationsByOwner,
                        ),
                        link: null,
                        neighborhood: item?.useDistrict,
                      }}
                      headerTranslucent={false}
                      infoSize="large"
                      mode="ios"
                      icon="icon icon-parking"
                      endContent={
                        <IonIcon
                          className={`icon icon-${getIconSchedule(item?.type)}`}
                        />
                      }
                    >
                      <DayCalendar
                        locale={localLanguage}
                        date={
                          new Date(
                            dayjs(
                              `${item.date
                                .toString()
                                .substring(0, 10)} 12:00:00`,
                            ).toISOString(),
                          )
                        }
                        selected={false}
                        id={item?.date}
                        disabled
                      ></DayCalendar>
                    </ReservationCard>
                  </IonItem>
                ))}
            </IonList>
          )}
          {settingsReservation && configurations && (
            <SettingsModal
              title={t('reservation_configuration')}
              id="settingsModal"
              isOpen={showSettings}
              onClose={handleOnCloseOption}
              setOpenModal={setShowSettings}
              value={settingsReservation.schedule}
              settingsReservation={settingsReservation}
              setValue={setSettingsReservation}
              configurations={configurations}
            />
          )}
          <IonLoading
            isOpen={isLoadingParking || isLoadingReservation}
            message={t('msg_loading')}
            duration={2000}
          />
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <Legend className={styles.centerLegend}>
          {dataLegend.map((item, idx) => {
            return <LegendItem key={idx} type={item.type} text={item.text} />;
          })}
        </Legend>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              onClick={handleSelectDateParking}
              disabled={
                calendarIsDisabled || !datesSelected.length || !!errorParking
              }
            >
              {t('btn_calendar')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Calendar;
