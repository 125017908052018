import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from '@ionic/react';
import { Icon } from '@acciona/ui-ionic-kit';
import { Props } from './types';
import { AppStore } from '../../../_redux/models/app.model';
import { appActions } from '../../../_redux/actions';
import styles from './styles.module.scss';

const NavBar: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { offlineNetwork, offlineServices } = useSelector(
    (state: AppStore) => state.app,
  );

  const location = useLocation();
  const [actionsMenuIsOpen, setActionsMenuIsOpen] = useState(false);
  const [actionsButtonUrl, setActionsButtonUrl] =
    useState('/dashboard/actions');

  useEffect(() => {
    setActionsMenuIsOpen(location.pathname === '/dashboard/actions');
  }, [location.pathname]);

  useEffect(() => {
    const url =
      offlineNetwork || offlineServices
        ? undefined
        : actionsMenuIsOpen
        ? '/dashboard/home'
        : '/dashboard/actions';
    setActionsButtonUrl(url);
  }, [actionsMenuIsOpen, offlineNetwork, offlineServices]);

  const handleClick = useCallback(
    (accessAllowedInOfflineMode = false, noPermission = false) => {
      const offlineMode = offlineNetwork || offlineServices;

      if (!offlineMode || accessAllowedInOfflineMode) {
        if (noPermission)
          dispatch(appActions.setNoReservationPermissionToastIsOpen(true));
        return;
      }
      dispatch(appActions.setOfflineToastIsOpen(true));
    },
    [offlineNetwork, offlineServices],
  );

  return (
    <IonTabs>
      {props.routing}
      <IonTabBar
        className={`${styles.tabBar} ${
          isPlatform('android') ? styles.tabBarAndroid : ''
        }`}
        slot="bottom"
        mode="ios"
      >
        {props.navTabs.map(navTab => {
          const noPermission = navTab.noPermission || false;
          let accessPermission = false;

          if (offlineNetwork || offlineServices) {
            accessPermission = navTab.accessAllowedInOfflineMode;
          } else {
            accessPermission = !noPermission;
          }

          return navTab.centralButton ? (
            <IonTabButton
              mode="ios"
              className={`${styles.tabBtnSty} ${
                isPlatform('android') ? styles.tabBtnStyAndroid : ''
              } ${navTab.noPermission ? styles.tabDisabled : ''}`}
              key={navTab.id}
              tab={navTab.tab}
              href={actionsButtonUrl}
            >
              <div className={styles.centralButton}>
                <Icon
                  color="tertiary"
                  className={`icon ${
                    actionsMenuIsOpen ? 'icon-minus' : 'icon-plus'
                  } ${styles.centralIcon}`}
                  onClick={() => handleClick()}
                />
                <IonLabel
                  className={styles.visuallyHidden}
                  onClick={() => handleClick()}
                >
                  {navTab.text}
                </IonLabel>
              </div>
            </IonTabButton>
          ) : (
            <IonTabButton
              mode="ios"
              className={`${styles.tabBtnSty} ${
                isPlatform('android') ? styles.tabBtnStyAndroid : ''
              } ${navTab.noPermission ? styles.tabDisabled : ''}`}
              key={navTab.id}
              tab={navTab.tab}
              disabled={navTab.disabled}
              href={accessPermission ? navTab.href : '#'}
            >
              <>
                <Icon
                  className={navTab.icon}
                  onClick={() =>
                    handleClick(
                      navTab.accessAllowedInOfflineMode,
                      navTab.noPermission,
                    )
                  }
                />
                <IonLabel
                  onClick={() =>
                    handleClick(
                      navTab.accessAllowedInOfflineMode,
                      navTab.noPermission,
                    )
                  }
                >
                  {navTab.text}
                </IonLabel>
              </>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default NavBar;
