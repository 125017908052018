import styles from './styles.module.scss';

const HeaderImage: React.FC = () => {
  return (
    <div className={styles.img__container}>
      <img
        className={styles.img__logo}
        src="./assets/images/logoCampusAcciona.png"
      />
      <img
        className={styles.img__bg}
        src="./assets/images/restaurant_table_bg.png"
      />
      <img
        className={styles.img__icon}
        src="./assets/images/restaurant_table_icon.png"
      />
    </div>
  );
};

export default HeaderImage;
