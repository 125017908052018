import _ from 'lodash';
import i18n from '../i18nextConf';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, listFormat, transformUtcToCampusTime } from './functions';

export enum EWeekday {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 0,
}

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Get a string date from a Datejs.format() string
 * @param {string} dateTime as dayjs().format()
 * @returns {string} date string 'YYYY-MM-DD'
 */
export const getDateFromISOTime = (dateTime: string) => {
  if (!dateTime) {
    return '';
  }
  return dateTime.substring(0, 10);
};

/**
 * Return true if dateTime is the same as today
 * @param {string} dateTime
 * @returns {boolean} true or false
 */
export const getIsToday = (dateTime: string) => {
  if (!dateTime) {
    return false;
  }
  //TODO: refactor next two lines to getCampusDate
  const todayUtc = dayjs().utc().format();
  const todayCampus = transformUtcToCampusTime(todayUtc);
  const todayCampusDate = getDateFromISOTime(todayCampus);
  const comparedDate = getDateFromISOTime(dateTime);

  return todayCampusDate === comparedDate;
};

/**
 * Get a string date from a Date object
 * @param {Date} date
 * @returns {string} date string 'yyyy-MM-dd'
 */
export const getSortDateString = (date: Date) => {
  if (!date) {
    return '';
  }
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const dayString = day < 10 ? '0' + day.toString() : day.toString();
  return `${year}-${month}-${dayString}`;
};

/**
 * Generate a locale date string with the weekDay, day and month from a string date
 * @param {string} date in format 'yyyy-MM-dd'
 * @returns {string} in format Weekday, day and Month. Example: "Lun 15 de abr"
 */
export const formatDateToWeekDayAndMonth = (
  date: string,
  options: { fullday?: boolean } = {},
): string => {
  return dayjs(date).format(
    `${options.fullday ? 'dddd' : 'ddd'} D [${i18n.t('lbl_of_date')}]MMM`,
  );
};

/**
 * Converts a string date to "<number of day> of <month>" format
 * @param {string} date date string
 * @returns {string} date string in "<number of day> of <month>"
 */
export const convertDateToWrittenFormat = (date: string): string => {
  return dayjs(date).format(`D [${i18n.t('lbl_of_date')}] MMMM`);
};

const validateDateTimeString = (time: string): boolean => {
  return /^\d{2}:\d{2}$/.test(time);
};

/**
 * Checks if a provided time is later than the current time
 * @param {string} time time in format hh:mm
 * @returns true if the provided time is later than the current time
 * and undefined on wrong time format
 */
export const isLaterThan = (time: string): boolean | undefined => {
  if (!validateDateTimeString(time)) return undefined;
  const [hour, minute] = time.split(':').map(v => +v);
  const now = dayjs().tz();
  const checkTime = dayjs().tz().set('hour', hour).set('minute', minute);
  return checkTime.isBefore(now);
};

/**
 * Checks if today is the provided weekday
 * @param {number} weekday number of the weekday to check
 * @returns true if today is the provided weekday
 */
export const isWeekDay = (weekday: number): boolean => {
  return dayjs().tz().weekday() === weekday;
};

export const getNowTimeCampus = (): Dayjs => {
  return dayjs().tz();
};

/**
 * Sets the time and weekday of a dayjs object
 * @param dayjsObj a dayjs object
 * @param params object containing the weekday and time string
 * @returns a dayjs object
 */
export const setWeekdayAndTime = (
  dayjsObj: Dayjs,
  params: { weekday?: number; time?: string },
): Dayjs => {
  if (params.weekday !== undefined) {
    dayjsObj = dayjsObj.weekday(params.weekday);
  }
  if (params.time !== undefined) {
    const [hour, minute] = params.time.split(':').map(v => +v);
    dayjsObj = dayjsObj
      .set('hour', hour)
      .set('minute', minute)
      .set('second', 0);
  }
  return dayjsObj;
};

export const isToday = (dateToCheck: string) => {
  if (!dateToCheck) {
    return false;
  }

  let dateParam = dateToCheck;

  if (typeof dateToCheck === 'string') {
    dateParam = dateToCheck.substring(0, 10);
  }

  const today = new Date();
  const date = new Date(dateParam);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const utcDateIsToday = (dateToCheck: string): boolean => {
  if (!dateToCheck) {
    return false;
  }
  const today = dayjs().utc().startOf('day');
  const utcDate = dayjs(dateToCheck).utc().startOf('day');
  return utcDate.isSame(today);
};

export const currentTime = () => {
  const today = new Date();
  return dateTimeFormat(today);
};

export const dateTimeFormat = date => {
  const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  return `${hours}:${minutes}`;
};

export const diffMsDates = (dateIni, dateEnd, minutes) => {
  dateIni.setMinutes(dateIni.getMinutes() - minutes);
  const diffMs = dateIni - dateEnd;
  return diffMs;
};

export const addHours = (initialHour: string, hoursToAdd: number) => {
  const actualTime = new Date(`2023-01-01T${initialHour}`);
  actualTime.setHours(actualTime.getHours() + hoursToAdd);
  const newHour = actualTime.getHours().toString().padStart(2, '0');
  const newTime = `${newHour}:00`;
  return newTime;
};

export const calcOneHourMoreOrLess = (date: string, more: boolean): string => {
  if (more) {
    return addHours(date, 1);
  } else {
    return addHours(date, -1);
  }
};

export const roundUpQuarter = (date: Dayjs) => {
  return date.minute() > 45
    ? dayjs().add(1, 'hour').minute(0).format('HH:mm')
    : dayjs(Math.ceil(date.valueOf() / 900000) * 900000).format('HH:mm');
};

export const roundUpQuarterWithOffset = (date: Dayjs) => {
  return date.minute() > 45
    ? dayjs().add(1, 'hour').minute(0).format('HH:mm')
    : dayjs(Math.ceil(date.valueOf() / 900000) * 900000)
        .utcOffset(0, true)
        .format('HH:mm');
};

export const setUtcOffset = date => {
  return dayjs(date)
    .startOf('day')
    .utcOffset(0, true)
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};

export const formatDayAndMonth = (date: string) => {
  return dayjs(date).format(`D [${i18n.t('lbl_of_date')}] MMMM`);
};

/**
 * Returns a string representing the date in the format 'YYYY-MM-DD'
 * @param {Date} date - The input date
 * @returns {string} The formatted date string
 */
export const getSortDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const groupDatesByMonth = (
  dates: string[],
  language: string,
  showDayText: boolean,
) => {
  if (isEmpty(dates)) {
    return '';
  }
  const uniqueDates = _.uniq(dates.map(e => e.substring(0, 10)));

  const dateObject = uniqueDates.sort().map((dateItem: string) => {
    const [year, month, day] = dateItem.split('-');
    return { id: `${year} ${month}`, year: year, month: month, day: day };
  });
  const text = [];
  const groupedByMonth = _.groupBy(dateObject, 'id');
  const datesArray = Object.keys(groupedByMonth).reduce(
    (acc, key) => ({
      ...acc,
      [key]: groupedByMonth[key],
    }),
    {},
  );

  _.forEach(datesArray, (value, key) => {
    const days = listFormat(
      value.map(v => parseInt(v.day).toString()),
      language,
    );

    const month = dayjs()
      .month(parseInt(key.replace(' ', '').substr(4, 2)) - 1)
      .format('MMMM');
    text.push(`${days} ${i18n.t('lbl_of_date')}${month}`);
  });

  const textdays = showDayText
    ? dates.length > 1
      ? i18n.t('days')
      : i18n.t('day')
    : '';
  return `${textdays}${listFormat(text, language)}`;
};

export const addDays = (date: Date, numberOfDays: number = 1) =>
  new Date(date.setDate(date.getDate() + numberOfDays));

export const checkIfDateIsDisabled = (
  date: Date,
  publicHolidays: string[],
  weekendDays: number[],
) => {
  const isWeekendDay = weekendDays.includes(dayjs(date).day());
  const isPublicHoliday = publicHolidays.includes(
    dayjs(date).format('YYYY-MM-DD'),
  );
  return isWeekendDay || isPublicHoliday;
};

export const getFirstEnabledDate = (
  publicHolidays: string[],
  today: string,
  weekendDays: number[],
) => {
  let date = new Date(today);
  while (checkIfDateIsDisabled(date, publicHolidays, weekendDays)) {
    date = addDays(date, 1);
  }
  return date;
};

export const roundOfMinutes = (date: string, minutes: number = 15): string => {
  const offsetCampus = dayjs().utcOffset() / 60;
  const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
  const roundUpToXMinutes = roundUpTo(1000 * 60 * minutes);
  const timeRound = new Date(roundUpToXMinutes(new Date(date)));
  const offsetDate = timeRound.getTimezoneOffset() / 60;

  const timeString =
    ('0' + (timeRound.getHours() + offsetCampus + offsetDate)).slice(-2) +
    ':' +
    ('0' + timeRound.getMinutes()).slice(-2);
  return timeString;
};

export const getDateParts = (dateString: string) => {
  if (!dateString) {
    return null;
  }

  const date = dayjs(dateString);

  if (!date.isValid()) {
    return null;
  }

  const time = date.format('HH:mm');
  const day = date.format('DD');
  const month = date.format('MM');
  const year = date.format('YYYY');

  return { time, day, month, year };
};

export const getDaysFromTo = (from: any, to: any) => {
  const dates = [];
  let currentDate = from;

  while (currentDate.isBefore(to) || currentDate.isSame(from)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};

// Input: '2024-07-26T10:31:15.305355'
// Output: 26 de Jul
export const getDateString = (date: string) => {
  try {
    const day = parseInt(date.substring(8, 10));
    const monthNumber = parseInt(date.substring(5, 7));

    const months = {
      1: 'dateFormats.month.Jan',
      2: 'dateFormats.month.Feb',
      3: 'dateFormats.month.Mar',
      4: 'dateFormats.month.Apr',
      5: 'dateFormats.month.May',
      6: 'dateFormats.month.Jun',
      7: 'dateFormats.month.Jul',
      8: 'dateFormats.month.Aug',
      9: 'dateFormats.month.Sep',
      10: 'dateFormats.month.Oct',
      11: 'dateFormats.month.Nov',
      12: 'dateFormats.month.Dec',
    };

    return i18n.t('new_available_parking_ahead', {
      day: day,
      month: i18n.t(`${months[monthNumber]}`),
    });
  } catch {
    return i18n.t('new_available_parking_fallback');
  }
};

export const getDeviceTimeZoneOffset = () => {
  const now = new Date();
  return now.getTimezoneOffset() / 60;
};

// Input: '11/09/2024T08:54:07.952Z'
// Output: Jueves 12 Jul 2024, 14:43
export const formatDate = (date: string) => {
  try {
    const day = parseInt(date.substring(0, 2));
    const month = parseInt(date.substring(3, 5));
    const year = parseInt(date.substring(6, 10));
    const hour = parseInt(date.substring(11, 13));
    const min = parseInt(date.substring(14, 16));
    const sec = parseInt(date.substring(17, 19));
    const milisec = parseInt(date.substring(20, 23));

    const formattedDate = new Date(
      year,
      month - 1,
      day,
      hour,
      min,
      sec,
      milisec,
    );

    const months = {
      1: 'dateFormats.month.Jan',
      2: 'dateFormats.month.Feb',
      3: 'dateFormats.month.Mar',
      4: 'dateFormats.month.Apr',
      5: 'dateFormats.month.May',
      6: 'dateFormats.month.Jun',
      7: 'dateFormats.month.Jul',
      8: 'dateFormats.month.Aug',
      9: 'dateFormats.month.Sep',
      10: 'dateFormats.month.Oct',
      11: 'dateFormats.month.Nov',
      12: 'dateFormats.month.Dec',
    };

    const days = {
      1: 'dateFormats.shortDay.Mon',
      2: 'dateFormats.shortDay.Tue',
      3: 'dateFormats.shortDay.Wed',
      4: 'dateFormats.shortDay.Thu',
      5: 'dateFormats.shortDay.Fri',
      6: 'dateFormats.shortDay.Sat',
      7: 'dateFormats.shortDay.Sun',
    };

    return `${i18n.t(days[formattedDate.getDay()])} ${day} ${i18n.t(
      months[month],
    )} ${year}, ${date.substring(11, 13)}:${date.substring(14, 16)}`;
  } catch {
    return '';
  }
};
