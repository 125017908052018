import { IonPage, IonRouterOutlet } from '@ionic/react';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import ServiceCover from './pages/ServiceCover/ServiceCover';
import ServiceDetailView from './pages/ServiceDetailView/ServiceDetailView';
import { Redirect } from 'react-router';
import { EPermissionsIds } from '../../../utils/constants';

const ServicesRouter: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <PrivateRoute path="/services/:id">
          <ServiceCover />
        </PrivateRoute>
        <PrivateRoute path="/services/howtoget">
          <Redirect to={`/services/${EPermissionsIds.HOW_TO_GET}`} />
        </PrivateRoute>
        <PrivateRoute path="/services/restaurants">
          <Redirect to={`/services/${EPermissionsIds.RESTAURANT}`} />
        </PrivateRoute>
        <PrivateRoute path="/services/medicalservices">
          <Redirect to={`/services/${EPermissionsIds.MEDICAL_SERVICES}`} />
        </PrivateRoute>
        <PrivateRoute path="/services/detail">
          <ServiceDetailView />
        </PrivateRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ServicesRouter;
