import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

const TransactionError: React.FC<any> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToLogin = () => {
    history.push('/');
  };

  return (
    <IonPage>
      <IonContent className={`${styles.generalContent} ion-no-border`}>
        <IonGrid className={styles.grid}>
          <IonRow>
            <IonButton className={styles.backButton} onClick={goToLogin}>
              <IonIcon
                className={`${styles.backButtonIcon} icon icon-chevron-left icon-24`}
              ></IonIcon>
              Volver al menú principal
            </IonButton>
          </IonRow>
          <IonGrid className={styles.gridContent}>
            <IonRow
              className={`${styles.imgContent} ion-justify-content-center`}
            >
              <div className={styles.iconItem}>
                <IonIcon
                  className={`${styles.checkIcon} icon icon-error icon-250`}
                />
              </div>
            </IonRow>
            <IonRow
              className={`${styles.txtContent} ion-justify-content-center`}
            >
              <IonText className={styles.subhead} color="light">
                {t('msg_error_payment_service')}
              </IonText>
            </IonRow>
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default TransactionError;
