import { HttpResponse } from '@capacitor/core';
import { PassengerData } from '../../models/passengers.model';
import muleSoftInstance from '../../utils/httpCommon';
import {
  EInvitationStatus,
  IPassegerInvitation,
  IPassengerResponse,
} from './types';

const getAvaliablePassengers = async () => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions/available`;
    const res: PassengerData[] = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getDefaultPassengers = async () => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions`;
    const res: PassengerData[] = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const addDefaultPassengers = async passengers => {
  try {
    passengers.forEach(item => {
      item.defaultCompanion = true;
    });
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions`;
    const res = (await muleSoftInstance.post(path, passengers)).data;

    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteDefaultPassengers = async passengers => {
  try {
    const idPassenger = passengers.employeeId;
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions`;
    const res = (await muleSoftInstance.delete(path, { data: [idPassenger] }))
      .data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPassengerInvitationByDriver = async (
  driverId: string,
  idSede: number,
): Promise<IPassegerInvitation> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v2/parking/companion/request/detail/${driverId}?headOffice=${idSede}`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const answerPassengerInvitation = async (
  input: IPassengerResponse[],
): Promise<HttpResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companion/answer`;
    const resp = await muleSoftInstance.put(url, input);
    return resp;
  } catch (err) {
    throw new Error(err);
  }
};

const getPassengerInvitationStatus = async (
  driverId: string,
): Promise<EInvitationStatus> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companion/checkNotification/${driverId}`;
    const resp = await muleSoftInstance.get(url);
    return resp.data.status;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteAcceptedPassengerInvitation = async (data: {
  requestIds: number[];
}): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companion/request`;
    const resp = await muleSoftInstance.delete(url, { data });
    return resp;
  } catch (error) {
    throw new Error(error);
  }
};

export const passengersServices = {
  getAvaliablePassengers,
  getDefaultPassengers,
  addDefaultPassengers,
  deleteDefaultPassengers,
  getPassengerInvitationByDriver,
  answerPassengerInvitation,
  getPassengerInvitationStatus,
  deleteAcceptedPassengerInvitation,
};
