import {
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, moreActions } from '../../../_redux/actions';
import { UserStore } from '../../../_redux/models/user.model';
import { DetailList } from '../../../components/Base/DetailList';
import { useExternalLink } from '../../../hooks/useExternalLink';
import { MoreStore } from './_redux/models';
import MoreMenuItem from './components/MoreMenuItem';
import styles from './styles.module.scss';
import { getItemLink } from './utils/itemRouting';

const More: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openLink } = useExternalLink();

  const { config: menuConfig } = useSelector((state: MoreStore) => {
    return state.more;
  });
  const permissions = useSelector(
    (state: UserStore) => state.user.user.permissions,
  );

  useIonViewDidEnter(() => {
    dispatch(appActions.getAppSettings()); // For links refresh in case of language change
    dispatch(moreActions.getMenuConfig()); // For menu config refresh on page visit
  });

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className={styles.toolbar}>
          <IonTitle>{t('tab_more')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar className={styles.toolbar}>
            <IonTitle size="large" className={styles.largeTitleBold}>
              {t('tab_more')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {menuConfig
              .filter(
                section =>
                  section.items.filter(item =>
                    permissions.includes(item.permission),
                  ).length > 0,
              )
              .sort((a, b) => a.order - b.order)
              .map(section => {
                const sectionTitle = section.translations[0].text;
                return (
                  <div key={section.id} className={styles.section}>
                    {sectionTitle && (
                      <IonItem className={styles.sectionTitle}>
                        {sectionTitle}
                      </IonItem>
                    )}
                    <DetailList className={styles.listActions}>
                      {section.items
                        .filter(item => permissions.includes(item.permission))
                        .sort((a, b) => a.order - b.order)
                        .map(item => {
                          const itemTitle = item.translations[0].text;
                          const linkProps = getItemLink(item, openLink);
                          return (
                            <MoreMenuItem
                              key={item.id}
                              className={styles.itemSty}
                              mode="ios"
                              lines="inset"
                              type="button"
                              startIcon={item.startIconUrl}
                              titleLight={itemTitle}
                              endIcon={item.endIcon}
                              endIconColor="primary"
                              {...(linkProps.routerLink
                                ? { routerLink: linkProps.routerLink }
                                : {})}
                              {...(linkProps.onClick
                                ? { onClick: linkProps.onClick }
                                : {})}
                            />
                          );
                        })}
                    </DetailList>
                  </div>
                );
              })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default More;
