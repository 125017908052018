import { Icon, Item } from '@acciona/ui-ionic-kit';
import { IonText } from '@ionic/react';
import SafeImage from '../../../../components/SafeImage/SafeImage';
import styles from './styles.module.scss';

export const MoreMenuItem = props => {
  const { className, ...res } = props;
  return (
    <Item
      lines={res.lines || 'none'}
      className={`${styles.item} ${className}`}
      {...res}
    >
      <div className={styles.startIconWrapper}>
        <SafeImage
          src={res.startIcon}
          fallback={'/assets/images/globe.svg'}
          className={`${res.startIcon} ${styles.startIcon}`}
        />
      </div>
      <div className={`ion-text-wrap ${styles.columnList}`}>
        <IonText
          color={res.titleColor}
          className={`${styles.body} ion-text-wrap  ${styles.block}`}
        >
          {res.titleLight}
        </IonText>
      </div>

      <Icon
        slot="end"
        color={res.endIconColor}
        className={`${res.endIcon} ${styles.endIcon}`}
      />
    </Item>
  );
};

export default MoreMenuItem;
