import styles from './styles.module.scss';
import { Props } from './types';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  Chip,
  Icon,
  SocialToolBarItem,
} from '@acciona/ui-ionic-kit';
import { IonGrid, IonItem, IonRow, IonText } from '@ionic/react';
import PlayImage from '../../../assets/images/play-button.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../../utils/functions';
import { NewsImg } from '../NewsImg';
import { UserStore } from '../../../_redux/models/user.model';
import VideoPlayer from '../VideoPlayer';
import { useVideoPlayer } from '../../../hooks/useVideoPlayer';
import SafeImage from '../../SafeImage/SafeImage';

export const ListCard: React.FC<Props> = props => {
  const { data, headerTranslucent, className, ...res } = props;
  const { t } = useTranslation();
  const { district } = useSelector((state: UserStore) => state.user.user);
  const { closeVideo, playVideo, showVideo } = useVideoPlayer(data.videoUrl);

  const getActionItem = actionItem => {
    switch (actionItem.type) {
      case 'icon':
        return (
          <SocialToolBarItem
            key={'Card' + actionItem.id}
            icon={actionItem.icon}
            onClick={actionItem.onClick}
            className={`${styles.cardIcon} ${styles[actionItem.slot]}`}
            text={actionItem.text}
            slot={actionItem.slot}
          />
        );
      default:
        return (
          <div
            key={'Card' + actionItem.id}
            className={styles.cardLabel}
            slot={actionItem.slot}
          >
            {actionItem.icon}
          </div>
        );
    }
  };

  const getActionIcons = actions => {
    return actions
      .filter(action => action.visible)
      .map(item => getActionItem(item));
  };

  return (
    <Card className={`${styles.cardWrapper} ${className}`} key={data.id}>
      {data.imageURL && (
        <div className={styles.centerImgButton} onClick={res.onClick}>
          <span className={styles.chipPosition}>
            {data.label && <Chip type="soft">{data.label}</Chip>}
            {/* TODO: waiting UX validation to remove */}
            {/* !isEmpty(data.videoUrl) && (
              <Chip type="info" className="icon icon-play-XXS" />
            )} */}
          </span>
          {!isEmpty(data.videoUrl) && (
            <button className={styles.videoButton} onClick={() => playVideo()}>
              <img src={PlayImage} alt="" />
            </button>
          )}
          <VideoPlayer
            videoUrl={data.videoUrl}
            onClose={closeVideo}
            isOpen={showVideo}
          />
          {data.enableOverlay || !isEmpty(data.videoUrl) ? (
            <div className={styles.imgOverlay} />
          ) : null}
          {props.isNews ? (
            <NewsImg
              image_id={data.imageURL}
              className={styles.imgCard}
              classSkeleton={styles.skeleton}
            />
          ) : (
            <SafeImage src={data.imageURL} fallback={props.fallbackImg} />
          )}
        </div>
      )}
      <CardHeader
        className={styles.cardHeader}
        translucent={headerTranslucent}
        onClick={res.onClick}
      >
        <CardTitle
          className={`${styles.cardTitle} ${styles.body} ${styles.color_primary}`}
        >
          {props?.isWorkroom && (
            <span className={styles.roomSpan}>{t('workroom')} </span>
          )}
          {data.title}
        </CardTitle>

        {district === data.district && (
          <IonRow className={`${styles.color_primary} ${styles.subhead}`}>
            <Icon
              color="primary"
              className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
            />
            {t('lbl_your_district')}
          </IonRow>
        )}
      </CardHeader>
      <CardContent
        mode={props.mode}
        className={`${styles.cardDescription} ${styles.subhead}`}
      >
        <IonText className={styles.subhead}> {data.description}</IonText>
        <IonGrid className={styles.noPadding}>
          <IonRow>
            <IonItem className={styles.cardActions}>
              {data.equipmentItems?.length > 0 && (
                <span className={styles.equipment}>
                  {data.equipmentItems.join(', ')}
                </span>
              )}
              {data.actions && getActionIcons(data.actions)}
            </IonItem>
          </IonRow>
        </IonGrid>
      </CardContent>
    </Card>
  );
};
