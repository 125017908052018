import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const DetailSection: React.FC<any> = props => {
  const { t } = useTranslation();
  return (
    <div className={styles.containerSection}>
      <p className={styles.titleSection}>{props.title}</p>
      {props.subtitle && (
        <p className={styles.subtitleSection}>{props.subtitle}</p>
      )}
      {props.summary &&
        props.summary.map(order => {
          return (
            <div key={order.name} className={styles.summaryContainer}>
              <p className={styles.summarySection}>{order.name}</p>
              <p className={styles.summarySection}>
                {order.quantity}
                <span>
                  {order.quantity === 1
                    ? t('wallet_detail_unit')
                    : t('wallet_detail_units')}
                </span>
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default DetailSection;
