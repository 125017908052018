import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { DetailList } from '../../../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../../../components/Base/DetailListItem';
import ServiceDetailContent from '../../ServiceDetail/components/ServiceDetailContent';
import styles from './styles.module.scss';

const gap = 32;

export const CoverVerticalSlider = ({ menuItems, itemDetailUrl, title }) => {
  const history = useHistory();

  const contentRef = useRef(null);
  const [animateContent, setAnimateContent] = useState(false);
  const animationTime = 0.5;
  const [contentPosition, setContentPosition] = useState(32);

  const onClickItemHandler = (itemId: number) => {
    const detail = menuItems.find(i => i.id === itemId);
    if (!detail) return;
    history.push(itemDetailUrl, {
      title,
      details: detail.content,
      name: detail.name,
    });
  };

  const handleTouch = (e: any) => {
    setContentPosition(
      Math.min(
        Math.max(e.view.parent.outerHeight - e.touches?.[0].pageY, gap),
        window.innerHeight,
      ),
    );
  };

  const toggleShowContent = () => {
    setAnimateContent(true);
    setTimeout(() => {
      setContentPosition(oldState => {
        const elementHeight = contentRef.current.offsetHeight - gap;
        setTimeout(() => setAnimateContent(false), animationTime * 1000);
        if (oldState === elementHeight) {
          return gap;
        }
        return elementHeight;
      });
    }, 0);
  };

  useEffect(() => {
    if (!contentRef.current) return;
    setTimeout(toggleShowContent, 100);
  }, [contentRef]);

  return (
    <div className={`${styles.contentReference}`} ref={contentRef}>
      <div
        className={`${styles.sheetModalPosition} ${styles.gridHeader}`}
        style={{
          top: `calc(100% - ${contentPosition}px)`,
          transition: animateContent
            ? `all ${animationTime}s`
            : `border-radius ${animationTime}s`,
          height: contentPosition + 'px',
          borderRadius:
            contentPosition === window.innerHeight ? '0px' : '32px 32px 0 0',
        }}
        onClick={toggleShowContent}
        onTouchMove={handleTouch}
      >
        <div
          className={styles.sheetModalContain}
          onClick={e => {
            e.stopPropagation();
          }}
          onTouchMove={e => {
            e.stopPropagation();
          }}
        >
          {menuItems.length > 0 && (
            <>
              {menuItems.length === 1 ? (
                <ServiceDetailContent
                  name={menuItems[0].name}
                  details={menuItems[0].content}
                ></ServiceDetailContent>
              ) : (
                <DetailList>
                  {menuItems.map(item => (
                    <DetailListItem
                      type="button"
                      key={item.id}
                      lines="inset"
                      titleLight={item.name}
                      titleColor="primary"
                      endIcon="icon icon-chevron-right"
                      onClick={() => onClickItemHandler(item.id)}
                    />
                  ))}
                </DetailList>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoverVerticalSlider;
