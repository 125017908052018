import { useRef, useState } from 'react';
import {
  IonButton,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonModal,
} from '@ionic/react';
import styles from './styles.module.scss';
import { TopBar } from '@acciona/ui-ionic-kit';
import { hourToDateFormat } from '../../_helpers/datetime';

export type Props = {
  name: string;
  className: string;
  displayFormat?: string;
  pickerFormat?: string;
  minuteValues?: string;
  min?: string;
  max?: string;
  value: string;
  placeholder: string;
  onIonFocus?: any;
  onIonChange?: any;
  disabled?: boolean;
  cancelText: string;
  doneText: string;
  displayTimezone?: string;
};

export const DateTime = ({
  name,
  value,
  min,
  doneText,
  cancelText,
  max,
  disabled,
  ...rest
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const minTimeString = min && hourToDateFormat(min);
  const maxTimeString = max && hourToDateFormat(max);
  const datetimeRef = useRef(null);

  const handleOpenModal = () => {
    !disabled && setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmDatetime = () => {
    datetimeRef?.current?.confirm();
    handleCloseModal();
  };

  return (
    <>
      <span
        hidden={!!value}
        className={
          disabled ? styles.dateTimeDisabled : styles.dateTimePlaceholder
        }
        onClick={handleOpenModal}
      >
        {rest.placeholder}
      </span>
      <IonDatetimeButton
        style={value ? {} : { maxHeight: 0, overflow: 'hidden' }}
        datetime={name}
        className={styles.dateTimeButton}
        disabled={disabled}
      />

      <IonModal
        id="datetime"
        keepContentsMounted={true}
        isOpen={showModal}
        onDidDismiss={handleCloseModal}
        onDidPresent={handleOpenModal}
      >
        <IonHeader mode="ios">
          <TopBar
            primaryActions={
              <IonButton className={styles.button} onClick={handleCloseModal}>
                {cancelText}
              </IonButton>
            }
            secondaryActions={
              <IonButton
                className={styles.button}
                onClick={handleConfirmDatetime}
              >
                {doneText}
              </IonButton>
            }
          />
        </IonHeader>
        <IonDatetime
          ref={datetimeRef}
          id={name}
          presentation="time"
          value={value || minTimeString}
          min={min && minTimeString}
          max={max && maxTimeString}
          {...rest}
        />
      </IonModal>
    </>
  );
};
