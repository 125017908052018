import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import styles from './styles.module.scss';
import { Props } from './types';

const RoomTypeModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const isIos = isPlatform('ios');
  const [selectedWrTypes, setSelectedWrType] = useState([]);
  const update = true;
  const workroomTypes = useSelector(
    (state: WorkroomStore) => state.workroom.workroomTypes,
  );

  useEffect(() => {
    props.value && setSelectedWrType(props.value);
  }, [props.value]);

  const handleSelectWrType = value => {
    if (update) {
      const idSelected = parseInt(value.detail.value);

      if (value.detail.checked) {
        !selectedWrTypes.includes(idSelected) &&
          setSelectedWrType(prevState => [...prevState, idSelected]);
      } else {
        const filterWr = [...selectedWrTypes].filter(id => id !== idSelected);
        setSelectedWrType(filterWr);
      }
    }
  };

  const confirmWorkroom = () => {
    props.setValue(selectedWrTypes);
    props.showModal(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={confirmWorkroom}
      backdropDismiss={false}
    >
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButton fill="clear" onClick={() => props.showModal(false)}>
            <IonIcon className="icon icon-chevron-left" />
            <IonTitle className={styles.title}>{t('type_workrooms')}</IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <div className={`${styles.body} ${styles.bodyMargin}`}>
            {t('workrooms_modal_desc')}
          </div>
          <DetailList>
            {workroomTypes &&
              workroomTypes.map((wrType, i) => (
                <DetailListItem
                  key={i}
                  lines="inset"
                  type="button"
                  className={styles.workroomsContainer}
                >
                  <IonCheckbox
                    slot={isPlatform('ios') ? 'start' : 'end'}
                    mode="ios"
                    value={wrType.id.toString()}
                    onIonChange={value => handleSelectWrType(value)}
                    checked={selectedWrTypes.includes(wrType.id)}
                    className={styles.checkbox}
                  />

                  <IonLabel>
                    <div className={styles.row}>
                      <Icon
                        className={`${styles.icon} icon icon-room icon-24`}
                      />
                      {wrType.name}
                    </div>
                    <p
                      className={styles.description}
                      style={{ marginLeft: isIos ? '' : '37px' }}
                    ></p>
                  </IonLabel>
                </DetailListItem>
              ))}
          </DetailList>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={confirmWorkroom}>
              {t('btn_confirm_type_room')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </Modal>
  );
};
export default RoomTypeModal;
