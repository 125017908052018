import { useLocationState } from '../../../../../hooks/useLocationState';
import { ServiceDetail } from '../../templates/ServiceDetail/ServiceDetail';

const ServiceDetailView: React.FC = () => {
  const { title, name, details } = useLocationState() as any;

  return (
    <ServiceDetail
      title={title}
      info={{
        name: name,
        details: details,
      }}
    />
  );
};

export default ServiceDetailView;
