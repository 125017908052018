import styles from './styles.module.scss';
import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const PaymentConfirmation: React.FC<any> = () => {
  const { orderId } = useParams() as { orderId: string };
  const { t } = useTranslation();

  const regexUUID =
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;

  const goToApp = () => {
    window.location.href = `accmash://app`;
  };

  return (
    <IonPage>
      <IonContent className={`${styles.generalContent} ion-no-border`}>
        <IonGrid className={styles.grid}>
          {(regexUUID.test(orderId) || orderId === 'error') && (
            <IonRow>
              <IonButton className={styles.backButton} onClick={goToApp}>
                <IonIcon
                  className={`${styles.backButtonIcon} icon icon-chevron-left icon-24`}
                ></IonIcon>
                {t('txt_return_to_app')}
              </IonButton>
            </IonRow>
          )}
          <IonGrid className={styles.gridContent}>
            <IonRow
              className={`${styles.imgContent} ion-justify-content-center`}
            >
              <div className={styles.iconItem}>
                <IonIcon
                  className={`${styles.checkIcon} icon icon-${
                    regexUUID.test(orderId) ? 'confirmation' : 'error'
                  } icon-250`}
                />
              </div>
            </IonRow>
            <IonRow
              className={`${styles.imgContent} ion-justify-content-center`}
            >
              <IonText
                className={styles.title_2}
                color="light"
                style={{ padding: '0 15px' }}
              >
                {regexUUID.test(orderId)
                  ? t('payment_confirmation_ok')
                  : orderId === 'error'
                  ? t('payment_confirmation_ko')
                  : t('payment_confirmation_error')}
              </IonText>
            </IonRow>
          </IonGrid>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PaymentConfirmation;
