import dayjs from 'dayjs';
import _ from 'lodash';
import { isEmpty } from '../../../utils/functions';
import { reservationActions } from '../../../_redux/actions';
import { ReservationList } from '../../../_redux/models/reservation.model';
import { Visualization } from '../../../_redux/models/app.model';
import { Dispatch } from 'redux';
import { Sede } from '../../../_redux/models/user.model';

const getSelectedDateSpaces = (composedSpaces, selectedDate, sedesList) => {
  const selectedDateSpaces = [];
  // TODO refactor
  composedSpaces.forEach(space => {
    const sedeSpace = sedesList.find(e => e.id == space?.idSede);
    const newSelectedDates = space.dates?.filter(e => {
      return ['Room', 'Room-WaitingList', 'Canteen'].includes(space.useType)
        ? dayjs(e.reservationDateFrom)
            .tz(sedeSpace?.campusTimeZone)
            .format('YYYY-MM-DD') === dayjs(selectedDate).format('YYYY-MM-DD')
        : dayjs(e.reservationDate).format('YYYY-MM-DD') === // Desk and parking does not need to apply campusTimeZone
            dayjs(selectedDate).format('YYYY-MM-DD');
    });

    if (newSelectedDates?.length > 0) {
      selectedDateSpaces?.push({
        ...space,
        dates: newSelectedDates,
      });
    }
  });
  return selectedDateSpaces;
};

const composedByReservationRaffle = (data: any[]) => {
  return [
    {
      useType: data[0].useType,
      dates: data.map(obj => ({
        requestId: obj.id,
        reservationDate: obj.dates[0].reservationDate,
        startDate: obj.dates[0].reservationDate,
        homeOffice: obj.dates[0].homeOffice,
      })),
      id: data[0].useType,
      idSede: data[0].idSede,
      descriptionSede: data[0].descriptionSede,
    },
  ];
};

const composedParkingByReservationRaffle = (data: any[]) => {
  return data.map(item => {
    return {
      useType: item[0].useType,
      dates: item.map(obj => ({
        requestId: obj.id,
        reservationDate: obj.dates[0].reservationDate,
        startDate: obj.dates[0].reservationDate,
        homeOffice: obj.dates[0].homeOffice,
      })),
      id: item[0].useType,
      idSede: item[0].idSede,
      descriptionSede: item[0].descriptionSede,
    };
  });
};
const composedPassengerInvitation = (data: any[]) => {
  return data.map(item => {
    return {
      useType: item[0].useType,
      dates: item.map(obj => ({
        requestId: obj.id,
        reservationDate: obj.dates[0].reservationDate,
        startDate: obj.dates[0].reservationDate,
        homeOffice: obj.dates[0].homeOffice,
      })),
      id: item[0].useType,
      idSede: item[0].idSede,
      descriptionSede: item[0].descriptionSede,
      driverName: item[0].driverName,
      driverId: item[0].driverId,
    };
  });
};

const multiGroupBy = (
  array: ReservationList[],
  group: string,
  restGroups: string,
) => {
  if (!group) {
    return array;
  }
  const currGrouping = _.groupBy(array, group);
  if (isEmpty(restGroups)) {
    return currGrouping;
  }
  return _.transform(
    currGrouping,
    (result, value, key) => {
      result[key] = multiGroupBy(value, restGroups, '');
    },
    {},
  );
};

const getComposedSpaces = (
  reservations: ReservationList[],
  dispatch: Dispatch<any>,
) => {
  const allReservations = reservations.filter(
    item =>
      item.useType !== 'Desk-Request' &&
      item.useType !== 'Parking-Request' &&
      item.useType !== 'PassengerInvitation' &&
      item.useType !== 'Passenger',
  );
  const groupedSpaces = multiGroupBy(reservations, 'useType', 'idSede');
  const wsRequest = !isEmpty(groupedSpaces['Desk-Request'])
    ? composedByReservationRaffle(groupedSpaces['Desk-Request'])
    : [];

  const parkingRequest = !isEmpty(groupedSpaces['Parking-Request'])
    ? composedParkingByReservationRaffle(
        Object.values(groupedSpaces['Parking-Request']),
      )
    : [];

  const passengerInvitations = !isEmpty(groupedSpaces['PassengerInvitation'])
    ? (Object.values(
        groupedSpaces['PassengerInvitation'],
      ).flat() as ReservationList[])
    : [];

  //group passengerInvitations by Driver
  const groupedPassengerInvitationBydriverId: Record<
    number,
    Record<number, ReservationList[]>
  > = multiGroupBy(passengerInvitations, 'driverId', 'idSede');

  const definitivePassengerInvitation: ReservationList[] =
    composedPassengerInvitation(
      Object.values(groupedPassengerInvitationBydriverId)
        .map((obj: Record<number, ReservationList[]>) => {
          return Object.values(obj).map((v: ReservationList[]) => v);
        })
        .flat(),
    );

  // PASSENGER ACCEPTED REQUESTS
  const passenger = !isEmpty(groupedSpaces['Passenger'])
    ? Object.values(multiGroupBy(reservations, 'useType', '')['Passenger'])
    : [];
  // REQUEST GROUPED BY DRIVER ID AND SEDE ID
  const groupedPassengerBydriverId = multiGroupBy(
    passenger as ReservationList[],
    'driverId',
    'idSede',
  );
  // DESTRUCTURE PASSENGER OBJECT
  const destructuredPassengerObject = Object.values(groupedPassengerBydriverId)
    .map((obj: any) => {
      return Object.values(obj).map(v => v);
    })
    .flat();
  // FORMAT DESTUCTURED PASSENGER OBJECT
  const definitivePassenger = composedPassengerInvitation(
    destructuredPassengerObject as any,
  );

  !isEmpty(wsRequest) &&
    dispatch(reservationActions.setReservedRafflesWr(wsRequest[0].dates));

  const composedSpaces = [
    ...allReservations,
    ...wsRequest,
    ...parkingRequest,
    ...definitivePassengerInvitation,
    ...definitivePassenger,
  ];

  return composedSpaces;
};

const getLockers = (reservations: ReservationList[]) => {
  return reservations.filter(e => e.useType == 'Locker');
};

const getSelectedDatePermanentSpaces = (
  spaces: ReservationList[],
  selectedDate: Date,
) => {
  const filterNotReleased = space => {
    const released = _.has(space, 'releasedDates') ? space.releasedDates : [];
    const endDate = _.has(space, 'endDate') ? space.endDate : '2099-12-31';

    return (
      !released.includes(dayjs(selectedDate).tz().format('YYYY-MM-DD')) &&
      dayjs(selectedDate).isSameOrBefore(dayjs(endDate))
    );
  };

  return spaces.filter(filterNotReleased);
};

export const getSpacesList = (
  sedeList: Sede[],
  selectedDate: Date,
  viewReservations: Visualization,
  reservations: ReservationList[],
  permanentParkings: ReservationList[],
  permanentDesks: ReservationList[],
  dispatch: Dispatch<any>,
) => {
  const selectedDatePermanentParkings = getSelectedDatePermanentSpaces(
    permanentParkings,
    selectedDate,
  );
  const selectedDatePermanentDesks = getSelectedDatePermanentSpaces(
    permanentDesks,
    selectedDate,
  );
  const lockers = getLockers(reservations);

  if (!isEmpty(reservations)) {
    const composedSpaces = getComposedSpaces(reservations, dispatch);
    const selectedDateSpaces = getSelectedDateSpaces(
      composedSpaces,
      selectedDate,
      sedeList,
    );
    return viewReservations === 'all'
      ? [...composedSpaces, ...permanentParkings, ...permanentDesks]
      : [
          ...selectedDateSpaces,
          ...selectedDatePermanentParkings,
          ...selectedDatePermanentDesks,
          ...lockers,
        ];
  } else {
    return viewReservations === 'all'
      ? [...permanentParkings, ...permanentDesks]
      : [
          ...selectedDatePermanentParkings,
          ...selectedDatePermanentDesks,
          ...lockers,
        ];
  }
};

const getSedesListReservations = (
  reservations: ReservationList[],
  selectedSedes: string[],
) => {
  return reservations.filter(r => {
    const sedeId = r?.idSede ? r.idSede.toString() : null;
    return sedeId && selectedSedes.includes(sedeId);
  });
};

export const getReservationsOnly = (
  spaces: ReservationList[],
  selectedFilter: string,
  selectedSedes: string[],
) => {
  const reservationsPermanents = spaces.filter(
    space =>
      ['all', 'Assigned'].includes(selectedFilter) &&
      ['PermanentDesk', 'PermanentParking'].includes(space.useType),
  );

  const reservations = _.orderBy(
    spaces.filter(
      space =>
        ['all', 'Assigned'].includes(selectedFilter) &&
        ['Room', 'Parking', 'Desk', 'Locker', 'Canteen', 'Passenger'].includes(
          space.useType,
        ),
    ),
    'dates[0].reservationDate',
  );
  const selectedSedesReservations = getSedesListReservations(
    [...reservationsPermanents, ...reservations],
    selectedSedes,
  );

  return sortReservations(selectedSedesReservations);
};

export const getRequestsOnly = (
  spaces: ReservationList[],
  selectedFilter: string,
  selectedSedes: string[],
) => {
  const requests = spaces.filter(
    space =>
      ['all', 'Pending'].includes(selectedFilter) &&
      [
        'Room-WaitingList',
        'Parking-Request',
        'Desk-Request',
        'PassengerInvitation',
      ].includes(space.useType),
  );

  const selectedSedesRequests = getSedesListReservations(
    requests,
    selectedSedes,
  );

  return selectedSedesRequests;
};

export const updateSedesSelection = (prev: string[], newSedeId: number) => {
  const newId = newSedeId.toString();
  if (!prev.includes(newId)) {
    return [...prev, newId];
  } else if (prev.length > 1) {
    return prev.filter(sedeId => sedeId !== newId);
  } else {
    return [newId];
  }
};

export const getReservedDays = (
  reservations: ReservationList[],
  selectedSedes: string[],
  sedesList: Sede[],
) => {
  const currentSedesReservations = getSedesListReservations(
    reservations,
    selectedSedes,
  );

  if (currentSedesReservations.length === 0) {
    return [];
  } else {
    const days = [];
    currentSedesReservations.forEach(e => {
      const sedeSpace = sedesList.find(sede => sede.id === e.idSede);
      if (['Room', 'Canteen'].includes(e.useType)) {
        days.push(
          dayjs(e.dates[0].reservationDateFrom)
            .tz(sedeSpace.campusTimeZone)
            .format('YYYY-MM-DD'),
        );
      } else if (e?.dates) {
        e.dates.map(e => days.push(e.reservationDate));
      }
    });
    return days;
  }
};

export const sortReservations = (filteredReservations: ReservationList[]) => {
  const permanentParking = [];
  const parking = [];
  const permanentDesk = [];
  const desk = [];
  const locker = [];
  const workroom = [];
  const canteen = [];
  const passenger = [];
  if (_.isEmpty(filteredReservations)) {
    return [];
  }
  filteredReservations.forEach(r => {
    switch (r.useType) {
      case 'PermanentDesk':
        permanentDesk.push(r);
        break;
      case 'Desk':
        desk.push(r);
        break;
      case 'Locker':
        locker.push(r);
        break;
      case 'PermanentParking':
        permanentParking.push(r);
        break;
      case 'Parking':
        parking.push(r);
        break;
      case 'Room':
        workroom.push(r);
        break;
      case 'Canteen':
        canteen.push(r);
        break;
      case 'Passenger':
        passenger.push(r);
        break;
      default:
        break;
    }
  });

  return [
    ...permanentDesk,
    ...desk,
    ...locker,
    ...permanentParking,
    ...parking,
    ...canteen,
    ...workroom,
    ...passenger,
  ];
};

export const getInitialSelectedSedes = (
  sedeFilterReservations: string[],
  sedesList: Sede[],
) => {
  const availableSedeIds = sedesList.map(sede => sede.id.toString()); // from employeeHeadOffice
  const localStoredSedeValidOptions = sedeFilterReservations.filter(sede =>
    availableSedeIds.includes(sede),
  );

  return isEmpty(localStoredSedeValidOptions)
    ? availableSedeIds
    : localStoredSedeValidOptions;
};
