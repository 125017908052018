import { isPlatform } from '@ionic/react';
import { Dispatch } from 'redux';
import { App } from '@capacitor/app';
import dayjs from 'dayjs';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import i18n from '../i18nextConf';
import _ from 'lodash';
import packageJson from '../../package.json';
import { history } from '../_helpers/history';
import { NewsActions } from '../_redux/actions';
import { USER_LOCKER_MODE } from './constants';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { getMinAndMaxDatesLastMinute } from '../pages/Spaces/Parking/Calendar/helpers';
import { Raffle, ReservationList } from '../_redux/models/reservation.model';
import {
  getMinAndMaxDates,
  getParkingRequestCalendarConfig,
} from '../pages/Spaces/Parking/CalendarPlanned/helpers';
import { ScheduleReservation } from '../_redux/models/workstation.model';
import { PassengerData } from '../_redux/models/passengers.model';
import {
  checkIfDateIsDisabled,
  getDaysFromTo,
  getSortDate,
  groupDatesByMonth,
  utcDateIsToday,
} from './dateTime';

export const isEmpty = (obj: any): boolean => {
  return obj == null || Object.keys(obj).length === 0;
};

export const getAppVersionName = async () => {
  if (isPlatform('capacitor')) {
    const info = await App.getInfo();
    return info?.version ?? '';
  } else {
    return packageJson.version;
  }
};

export const getAppVersion = async () => {
  if (isPlatform('capacitor')) {
    const info = await App.getInfo();
    if (!info) {
      return '';
    }
    return `Campus ACCIONA-${info.id}-${info.build}-${info.version}`;
  } else {
    return `Campus ACCIONA- ${packageJson.version}`;
  }
};

export const getDeviceOS = async () => {
  const deviceInfo = await Device.getInfo();
  const { platform = '', osVersion = '' } = deviceInfo;

  if (platform === 'web') {
    return 'Web';
  } else if (platform === 'android') {
    return `Android ${osVersion}`;
  } else if (platform === 'ios') {
    return `iOS ${osVersion}`;
  } else {
    return `${platform} - ${osVersion}`;
  }
};

export const groupBy = (array: any[], property: string) => {
  return array.reduce((r, a) => {
    r[a[property]] = r[a[property]] || [];
    r[a[property]].push(a);
    return r;
  }, Object.create(null));
};

export const base64FromPath = async (
  path: string,
): Promise<{ data: string; mimeType: string }> => {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve({ data: reader.result, mimeType: blob.type });
      } else {
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
};

/**
 * Converts any of this: '["item"]' or ["item"]
 * into this: ["item"]
 * @param arr - a JSON string or an array
 * @returns parsed array or empty array if param is not convertible to array;
 */
export const parseJsonArray = (arr: string | any[]) => {
  if (Array.isArray(arr)) {
    return arr;
  } else {
    const parsed = JSON.parse(arr);
    return Array.isArray(parsed) ? parsed.filter(item => item !== '') : [];
  }
};

export const detectBrowser = () => {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) != -1
  ) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox';
  } else if (navigator.userAgent.indexOf('MSIE') != -1) {
    return 'IE'; //crap
  } else {
    return 'Unknown';
  }
};

/**
 * Returns an array of marks with the coordinates adjusted based on the size of the image.
 * @param coordinates
 * @param widhtImage
 * @param heightImage
 * @param orgWidth
 * @param orgHeight
 * @returns
 */
export const adaptCoordinate = (
  coordinate: {
    x: number;
    y: number;
  },
  widhtImage: number,
  heightImage: number,
  orgWidth: number,
  orgHeight: number,
  markSize: number,
) => {
  return {
    y: `${(
      computeCoordinate(coordinate.y, heightImage, orgHeight) -
      markSize +
      2
    ).toFixed(0)}px`,
    x: `${(
      computeCoordinate(coordinate.x, widhtImage, orgWidth) -
      markSize / 2
    ).toFixed(0)}px`,
  };
};

/**
 * Returns the new value of the coordinate based on the ratio between the parameterised sizes
 * @param {number} coordinate
 * @param {number} width
 * @param {number} widthOrg
 * @returns
 */
const computeCoordinate = (
  coordinate: number,
  size: number,
  sizeOrg: number,
) => {
  const ratio = size / sizeOrg;
  return coordinate * ratio;
};

export const isExternalLink = (url: string) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  const isExternal = tmp.host !== window.location.host;
  tmp.remove();
  return isExternal;
};

export const openHtmlLink = async (link: string, cache: boolean = true) => {
  if (!link) return;
  const options = `location=no,closebuttoncaption=OK${
    cache ? ',clearcache=yes' : ''
  }`;

  let destinationUrl = link;

  if (!isExternalLink(link)) {
    destinationUrl = 'https://' + link;
  }

  if (!isPlatform('capacitor')) {
    await Browser.open({
      url: destinationUrl,
      windowName: '_blank',
      presentationStyle: 'popover',
    });
    return;
  }

  InAppBrowser.create(destinationUrl, '_blank', options);
};
export const getDocumentCookies = () => {
  return document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map(c => c.trim());
    cookies[name] = value;
    return cookies;
  }, {});
};

export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const deleteAnalyticsCookies = () => {
  const documentCookies = getDocumentCookies();
  const analyticsCookies = Object.keys(documentCookies).filter(name =>
    name.includes('_ga'),
  );
  analyticsCookies.forEach(cookie => deleteCookie(cookie));
};

/**
 * Returns a string, with all elements separated by commas,
 * except the last one, which is separated by "y" or "and",
 * depending on the language passed as a parameter
 * @param {string[]} arr
 * @param {string} language
 * @returns
 */
export const listFormat = (arr: string[], language: string) => {
  if (arr.length === 1) {
    return arr[0];
  }
  const str = arr.join(', ');
  const lastIndex = str.lastIndexOf(',');
  const languageSelected = ['es', 'en', 'pt'].includes(language)
    ? language
    : 'es';
  const replacement = {
    es: 'y',
    en: 'and',
    pt: 'e',
  };
  return (
    str.substring(0, lastIndex) +
    ' ' +
    replacement[languageSelected] +
    str.substring(lastIndex + 1)
  );
};

export const handleBackMinimizeButton = (ev: any) => {
  ev.detail.register(10, () => {
    ev.preventDefault();
    ev.stopPropagation();
    App.minimizeApp();
  });
};

export const getSlotString = <
  T extends {
    endHour?: string;
    startHour?: string;
    type?: string;
    reservationType?: string;
  },
>(
  slotDate: T,
) => {
  const {
    endHour = '',
    startHour = '',
    type = null,
    reservationType = null,
  } = slotDate;
  const typeOfReservation = type ?? reservationType ?? '';

  switch (typeOfReservation) {
    case 'Dia completo':
      return `${i18n.t('lbl_complete_day')}`;
    case 'Morning':
      return `${i18n.t('morning_schedule', { endHour })}`;
    case 'Tarde':
      return `${i18n.t('afternoon_schedule', { startHour })}`;
    case 'Personalizada': {
      const schedule = startHour && endHour ? ` (${startHour}-${endHour})` : '';
      return `${i18n.t('lbl_custom_schedule')}${schedule}`;
    }
    default:
      return '';
  }
};

export const getImageFormat = format => {
  if (format === 'png') {
    return format;
  } else if (format === 'jpeg' || format === 'jpg') {
    return 'jpg';
  } else {
    return null;
  }
};

export const updateCssVariable = (variableName: string, value: string) => {
  document.documentElement.style.setProperty(variableName, value);
};

export const applyMacFontStyles = () => {
  updateCssVariable(
    '--ion-font-family',
    "-apple-system, BlinkMacSystemFont, 'SF Pro Text', Roboto, 'Segoe UI', system-ui, sans-serif !important",
  );
  updateCssVariable(
    'font-family',
    "-apple-system, BlinkMacSystemFont, Roboto, 'SF Pro Text', 'Segoe UI', system-ui, sans-serif !important",
  );
  updateCssVariable(
    '--display-font',
    "-apple-system, BlinkMacSystemFont, 'SF Pro Display', Roboto, 'Segoe UI', system-ui, sans-serif !important",
  );
  updateCssVariable('--font-weight-headline', '600');
};

export const updateGlobalColors = (colors: { id: string; color: string }[]) => {
  colors.forEach(color => {
    if (color?.color) {
      updateCssVariable(`--color-${color.id}`, color.color.toString());
    }
  });
};

/**
 * Promise-based alternative to setTimeout.
 * The promise resolves after x milliseconds,
 * so we can add .then to it, like this:
 * delay(3000).then(() => alert('runs after 3 seconds'));
 * @param {number} milliseconds
 * @returns {Promise}
 */
export const delay = (milliseconds: number): Promise<any> => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const getDatesToReleaseText = (
  dates: {
    id?: number;
    status: boolean;
    date: Date;
    homeOffice?: boolean;
  }[],
) => {
  if (isEmpty(dates)) {
    return '';
  }

  const daysToRelease = dates.filter(date => date.status);

  if (isEmpty(daysToRelease)) {
    return '';
  }

  const daysList = groupDatesByMonth(
    daysToRelease.map(d => getSortDate(d.date)),
    'es',
    true,
  );

  return daysList;
};

export const getDatesHomeOfficeText = (
  dates: {
    id?: number;
    status: boolean;
    date: Date;
    homeOffice?: boolean;
  }[],
  language: string,
) => {
  if (isEmpty(dates)) {
    return '';
  }

  const daysString = groupDatesByMonth(
    dates.map(item => getSortDate(item.date)),
    language,
    false,
  );

  return dates.length > 1
    ? i18n.t('lbl_reservations_homeOffice_1', { date: daysString })
    : i18n.t('lbl_reservation_homeOffice_1', { date: daysString });
};

/**
 * Remove accents (diacritical marks) from any text
 * @param {string} str - text
 * @returns
 */
export const diacriticalReplace = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Compare if a string includes a query string
 * (case and diacritic-insensitive)
 * @param {string}  str - string
 * @param {string} query - query string
 */
export const includesQueryString = (str: string, query: string) => {
  return diacriticalReplace(str)
    .toLowerCase()
    .includes(diacriticalReplace(query).toLowerCase());
};

/**
 * Get random vehicle id
 * @returns
 */
export const getRandomVehicleId = () => {
  return Math.round(Math.random() * (100 - 50 + 1) + 50);
};

/**
 * Checks if a string matches any of the regex provided
 * If no regex provided, returns true
 * @param str string to validate
 * @param regex array of regex to apply to the string
 * @returns true if at least one regex matches the string
 */
export const someRegexValidation = (str: string, regex: string[]): boolean => {
  if (regex.length === 0) return true;
  return regex.some(r => str.match(new RegExp(r)));
};

/**
 * Validate plate depending on the type of vehicle
 * @param {any} plate - text plate
 * @returns
 */
export const validatePlate = plate => {
  const plateFormat1 = /^[C]{1}[0-9]{4}[a-zA-Z]{3}$/; // microcar, moped
  const plateFormat2 = /^[0-9]{4}[a-zA-Z]{3}$/; // car, motorcycle

  const validPlate = plateFormat1.test(plate) || plateFormat2.test(plate);
  return validPlate;
};

/**
 * Return message of workstation reservations confirmation
 * @param workstations
 * @returns
 */

export const getMsgWorkstation = (workstations, lockerEmployeeKey) => {
  let message = '';

  if (!workstations) {
    return message;
  }

  const isPlural = workstations.length > 1;
  if (isPlural) {
    message = i18n.t('lbl_reserved_multiple_txt');
  } else {
    const { name, buildingName, floorName, lockerId } = workstations[0];
    const templateKey =
      lockerId &&
      lockerEmployeeKey &&
      lockerEmployeeKey === USER_LOCKER_MODE.DESK_LOCKER
        ? 'lbl_reserved_wrk_txt'
        : 'lbl_reserved_wrk_txt_noLocker';
    message = i18n.t(templateKey, {
      number: name,
      building: buildingName,
      floor: floorName,
      ...(lockerId &&
      lockerEmployeeKey &&
      lockerEmployeeKey.toString() === USER_LOCKER_MODE.DESK_LOCKER
        ? { locker: lockerId }
        : {}),
    });
  }
  return message;
};

export const backToDetail = (
  idOrigin: string,
  backUrl: string,
  dispatch: Dispatch<any>,
  pathOrigin: string = '/dashboard/home',
) => {
  if (backUrl) {
    switch (backUrl) {
      case '/news/detail':
        idOrigin
          ? dispatch(NewsActions.selectNews(idOrigin, pathOrigin))
          : history.replace('/dashboard/news');
        break;
      case '/notification-detail':
        idOrigin
          ? dispatch(NewsActions.selectNotice(idOrigin, pathOrigin))
          : history.replace('/dashboard/home');
        break;
      default:
        history.replace('/dashboard/news');
        break;
    }
  } else {
    history.replace('/dashboard/home');
  }
};

export const getNumberOfWeeks = (dateFrom, dateTo) => {
  const dateFromObj = dayjs(dateFrom, 'YYYY-MM-DD').startOf('week');
  const dateToObj = dayjs(dateTo, 'YYYY-MM-DD').endOf('week');
  const dateFromThisweek = dayjs().startOf('week');
  const todayWeekday = dayjs().day();
  const isWeekend = [6, 0].includes(todayWeekday);

  let weeksOfDifference = 2;

  if (dateFromObj.isValid() && dateToObj.isValid()) {
    weeksOfDifference = dateToObj.diff(dateFromObj, 'week') + 1;
  }

  if (isWeekend) {
    weeksOfDifference++;
  }

  if (
    dateFromThisweek.format('YYYY-MM-DD') !== dateFromObj.format('YYYY-MM-DD')
  ) {
    weeksOfDifference++;
  }

  return weeksOfDifference;
};

export const didCameraPermission = async () => {
  const status = await BarcodeScanner.checkPermission({ force: false });

  if (status.granted) return true;

  if (status.denied) {
    const c = confirm(i18n.t('msg_access_camera_settings'));
    if (c) {
      BarcodeScanner.openAppSettings();
    }
  }

  if (status.neverAsked) {
    const c = confirm(i18n.t('msg_enable_camera'));

    if (!c) return false;
  }
  // ios only
  if (status.restricted || status.unknown) return false;

  const statusRequest = await BarcodeScanner.checkPermission({ force: true });

  if (statusRequest.granted) return true;

  return false;
};

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getUniqueValues = <T>(data: T[], keyField: keyof T): T[] => {
  if (isEmpty(data)) {
    return [];
  }

  const uniqueItems = [];
  const keyValues = [];

  data.forEach(date => {
    if (!keyValues.includes(date[keyField])) {
      uniqueItems.push(date);
      keyValues.push(date[keyField]);
    }
  });
  return uniqueItems;
};

export const getSortedUniqueValues = <T>(data: T[], keyField: keyof T): T[] => {
  if (isEmpty(data)) {
    return [];
  }
  return _.orderBy(getUniqueValues(data, keyField), keyField);
};

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const filterReservableDaysAfterLastEndDate = (
  reservableDays: string[],
  endDates: string[],
): string[] => {
  endDates.sort();
  const lastEndDate = _.last(endDates);
  return reservableDays.filter(day => dayjs(day) > dayjs(lastEndDate));
};

export const filterReservableDaysBeforeFirstEndDate = (
  reservableDays: string[],
  endDates: string[],
): string[] => {
  endDates.sort();
  const firstEndDate = _.first(endDates);
  return reservableDays.filter(day => dayjs(day) <= dayjs(firstEndDate));
};

export const getReservableDaysConsideringPermanency = (
  reservableDays: string[],
  permanentDesks: any[],
  defaultSedeId: number,
) => {
  const thisSedePermanentDesks = permanentDesks.filter(
    p => p?.idSede === defaultSedeId,
  );

  if (isEmpty(thisSedePermanentDesks)) {
    return {
      after: reservableDays,
      before: reservableDays,
    };
  }

  if (isEmpty(reservableDays)) {
    return {
      after: [],
      before: [],
    };
  }

  const endDates = thisSedePermanentDesks
    .map(desk => desk?.endDate)
    .filter(v => v);

  if (!isEmpty(endDates)) {
    return {
      before: filterReservableDaysBeforeFirstEndDate(reservableDays, endDates),
      after: filterReservableDaysAfterLastEndDate(reservableDays, endDates),
    };
  }

  return {
    before: reservableDays,
    after: [],
  };
};

export const getReservableDaysPKAfterPermanency = (
  publicHolidays: string[],
  permanentPk: any[],
  raffle: Raffle,
  defaultSedeId: number,
): string[] => {
  const { minimumDate = null, maximumDate = null } =
    getMinAndMaxDatesLastMinute(raffle);

  if (!minimumDate || !maximumDate) {
    return [];
  }

  const dateMin = dayjs(minimumDate.format('YYYY-MM-DD 00:00:00'));
  const dateMax = dayjs(maximumDate.format('YYYY-MM-DD 23:59:00'));

  const reservableDaysResult = [];

  getDaysFromTo(dateMin, dateMax).forEach(d => {
    if (!checkIfDateIsDisabled(dayjs(d).toDate(), publicHolidays, [0, 6])) {
      reservableDaysResult.push(dayjs(d).format('YYYY-MM-DD'));
    }
  });

  const thisSedePermanentPk = permanentPk.filter(
    p => p?.idSede === defaultSedeId,
  );

  if (isEmpty(thisSedePermanentPk)) {
    return reservableDaysResult;
  }

  const endDates = thisSedePermanentPk
    .map(desk => desk?.endDate)
    .filter(v => v);

  if (!isEmpty(endDates)) {
    return filterReservableDaysAfterLastEndDate(reservableDaysResult, endDates);
  }
  return [];
};

export const getReservableDaysPlannedPKAfterPermanency = (
  publicHolidays: string[],
  permanentPk: any[],
  raffle: Raffle,
  defaultSedeId: number,
  dayParkingPlannedTurningPoint,
  hourParkingPlannedTurningPoint,
  parkingPlannedWeeksExtend,
) => {
  const { minDate: min, maxDate: max } = getMinAndMaxDates(
    raffle,
    dayParkingPlannedTurningPoint,
    hourParkingPlannedTurningPoint,
    parkingPlannedWeeksExtend,
  );

  if (!min || !max) {
    return [];
  }

  const config = getParkingRequestCalendarConfig(
    new Date(dayjs(min.format('YYYY-MM-DD 00:00:00')).toISOString()),
    new Date(dayjs(max.format('YYYY-MM-DD 23:59:00')).toISOString()),
    publicHolidays,
  );

  const { reservableDays = [] } = config;

  const thisSedePermanentPk = permanentPk.filter(
    p => p?.idSede === defaultSedeId,
  );

  if (isEmpty(thisSedePermanentPk)) {
    return reservableDays;
  }

  const endDates = thisSedePermanentPk
    .map(desk => desk?.endDate)
    .filter(v => v);

  if (!isEmpty(endDates)) {
    return filterReservableDaysAfterLastEndDate(reservableDays, endDates);
  }
  return [];
};

const openBrowser = (url: string) => {
  Browser.open({
    url,
    windowName: '_blank',
    presentationStyle: 'popover',
  });
};

const openExternalApp = async (item: {
  deepLinkIOS: string;
  downloadAppIOS: string;
  deepLinkAndroid: string;
  downloadAppAndroid: string;
}) => {
  let deepLinkUrl = '';
  let downloadUrl = '';
  // android e ios

  const isIos = isPlatform('ios');
  const isAndroid = isPlatform('android');

  if (isIos) {
    deepLinkUrl = item.deepLinkIOS;
    downloadUrl = item.downloadAppIOS;
  }
  if (isAndroid) {
    deepLinkUrl = item.deepLinkAndroid;
    downloadUrl = item.downloadAppAndroid;
  }
  let appInstalled = false;

  const handleAppStateChange = state => {
    if (!state.isActive) {
      appInstalled = true;
    }
  };

  const subscription = await App.addListener(
    'appStateChange',
    handleAppStateChange,
  );

  window.location.href = deepLinkUrl;

  setTimeout(() => {
    subscription.remove();
    if (!appInstalled) {
      openBrowser(downloadUrl);
    }
  }, 2000);
};

export const openExternalAppLink = async (item: {
  webUrl: string;
  deepLinkIOS: string;
  downloadAppIOS: string;
  deepLinkAndroid: string;
  downloadAppAndroid: string;
}) => {
  // web
  if (!isPlatform('capacitor')) {
    openBrowser(item.webUrl);
    return;
  }
  openExternalApp(item);
};

export const getCampusOffset = () => {
  return dayjs().tz().utcOffset() / 60;
};

export const transformCampusTimeToUTC = date => {
  const offset = getCampusOffset();
  return dayjs(date).subtract(offset, 'hours').toISOString();
};
export const transformUtcToCampusTime = date => {
  const offset = getCampusOffset();
  return dayjs(date).add(offset, 'hours').toISOString();
};

export const showPolicies = (policies: ScheduleReservation[]) => {
  if (!policies) {
    return false;
  } else if (policies?.length < 1) {
    return false;
  } else if (
    policies?.length === 1 &&
    policies[0]?.nombre === 'Personalizada'
  ) {
    return true;
  } else if (policies?.length > 1) {
    return true;
  } else {
    return false;
  }
};

export const getRoomReservationHours = (
  dateFrom: string,
  dateTo: string,
  timezone: string,
  visualization: string = 'perDay',
) => {
  if (!timezone || timezone === '') {
    return '';
  }

  return visualization === 'all'
    ? `${dayjs(dateFrom).tz(timezone).format('D MMM [|] HH:mm')} - ${dayjs(
        dateTo,
      )
        .add(1, 'seconds')
        .tz(timezone)
        .format('HH:mm')}`
    : `${dayjs(dateFrom).tz(timezone).format('HH:mm')} - ${dayjs(dateTo)
        .add(1, 'seconds')
        .tz(timezone)
        .format('HH:mm')}`;
};
export const getCanteenReservationHours = (
  dateFrom: string,
  dateTo: string,
  timezone: string,
  visualization: string = 'perDay',
) => {
  if (!timezone || timezone === '') {
    return '';
  }

  return visualization === 'all'
    ? capitalizeFirstLetter(
        `${dayjs(dateFrom).tz(timezone).format('ddd D MMM[,] HH:mm')} - ${dayjs(
          dateTo,
        )
          .add(1, 'seconds')
          .tz(timezone)
          .format('HH:mm')}`,
      )
    : `${dayjs(dateFrom).tz(timezone).format('HH:mm')} - ${dayjs(dateTo)
        .add(1, 'seconds')
        .tz(timezone)
        .format('HH:mm')}`;
};

export const isCanteenReservationActive = (
  reservations: ReservationList[],
  limitHour: string,
) => {
  const canteenReservs = getCanteenReservationsSorted(reservations);
  if (canteenReservs.length === 0) return false;
  if (utcDateIsToday(canteenReservs[0]?.dates[0]?.reservationDateFrom)) {
    if (dayjs() >= dayjs(limitHour)) {
      return canteenReservs.length > 1;
    }
  } else {
    return true;
  }
  return true;
};

export const getCanteenReservActiveModalDescription = (
  canteenReserv,
  timezone,
) => {
  const descriptionTexts = {
    date: utcDateIsToday(canteenReserv.dates[0].reservationDateFrom)
      ? i18n.t('today').toLowerCase()
      : `<b>${dayjs(canteenReserv.dates[0].reservationDateFrom)
          .tz(timezone)
          .format('dddd D MMM YYYY')}</b>`,
    from: dayjs(canteenReserv.dates[0].reservationDateFrom)
      .tz(timezone)
      .format('HH:mm'),
    to: dayjs(canteenReserv.dates[0].reservationDateTo)
      .add(1, 'seconds')
      .tz(timezone)
      .format('HH:mm'),
  };
  return descriptionTexts;
};

export const getCanteenReservationsSorted = (
  reservations: ReservationList[],
) => {
  return reservations
    .filter(r => r.useType === 'Canteen')
    .sort((a, b) => {
      return a.dates[0].reservationDateFrom.localeCompare(
        b.dates[0].reservationDateFrom,
      );
    });
};

export const getCompanionsFilteredByParkingPermission = (
  companions: PassengerData[],
): {
  withParkingPermissions: PassengerData[];
  withoutParkingPermissions: PassengerData[];
} => {
  const { withParkingPermissions, withoutParkingPermissions } =
    companions.reduce(
      (acc, companion) => {
        if (companion.parkingPermissions) {
          acc.withParkingPermissions.push(companion);
        } else {
          acc.withoutParkingPermissions.push(companion);
        }
        return acc;
      },
      { withParkingPermissions: [], withoutParkingPermissions: [] },
    );
  return { withParkingPermissions, withoutParkingPermissions };
};

export const convertListToText = (
  list: string[],
  separator: string,
  lastSeparator: string,
): string => {
  if (list.length === 0) return '';
  if (list.length === 1) return list[0];
  return (
    list.slice(0, list.length - 1).join(separator) + lastSeparator + list.at(-1)
  );
};
