import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { NotificationsActions } from '../../../../../_redux/actions';
import { useRequestData } from '../../../../../hooks/useRequestData';
import { moreServices } from '../../_redux/services';
import { Cover } from '../../templates/Cover/Cover';

const ServiceCover: React.FC = () => {
  const { id } = useParams() as any;
  const dispatch = useDispatch();
  const { data, isLoading, error } = useRequestData(
    () => moreServices.getServiceData(id),
    {
      title: '',
      images: { backgroundDesktop: '' },
      details: [],
    },
  );

  useEffect(() => {
    if (error) {
      dispatch(NotificationsActions.setGenericErrorToast(error));
      // history.goBack();
    }
  }, [error]);

  return (
    <Cover
      backgroundImageDesktop={data.images.backgroundDesktop}
      backgroundImageMobile={data.images.backgroundMobile}
      title={data.title}
      subtitle={data.subtitle}
      menuItems={data.linkList?.length > 0 ? data.linkList : [data.fullContent]}
      isLoading={isLoading}
    />
  );
};

export default ServiceCover;
