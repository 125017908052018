import {
  UserData,
  UserAction,
  Policies,
  Message,
  Wallet,
  Holidays,
  Sede,
  UserWorkPreferences,
  Permissions,
  OtherPolicies,
  EmployeeHeadOffice,
  WalletV2,
} from '../models/user.model';
import { userServices } from '../services/user/user.services';
import { userTypesEnum } from '../types/user.types.enum';
import { history } from '../../_helpers/history';
import _ from 'lodash';
import { Locale } from '../models/app.model';
import i18n from '../../i18nextConf';
import { appActions } from './app.actions';
import { authServices } from '../services/auth/auth.services';
import { tokenServices } from '../services/token/token.services';
import { UserParkingPreferences } from '../models/parking.model';
import { workstationActions } from './workstation.actions';
import { authActions } from './auth.actions';
import { isEmpty } from '../../utils/functions';
import { NotificationsActions } from './notifications.actions';

const setUserDetails = (user: UserData): UserAction => ({
  type: userTypesEnum.SET_USER,
  user: user,
});
const setUserHeadOffice = (headOffice: EmployeeHeadOffice): UserAction => ({
  type: userTypesEnum.SET_USER_HEADOFFICE,
  headOffice: headOffice,
});

const setCardActive = (): UserAction => ({
  type: userTypesEnum.SET_CARD_ACTIVE,
});
const setCardRegisteredFromInvitationCode = (): UserAction => ({
  type: userTypesEnum.SET_CARD_FROM_INVITATION,
});
const setCardRegistered = (): UserAction => ({
  type: userTypesEnum.SET_CARD_REGISTERED,
});
const setCardUnRegistered = (): UserAction => ({
  type: userTypesEnum.SET_CARD_UNREGISTERED,
});

const resetSetCardFromInvitation = (): UserAction => ({
  type: userTypesEnum.RESET_SET_CARD_FROM_INVITATION,
});
const resetCard = (): UserAction => ({
  type: userTypesEnum.RESET_CARD,
});
const resetSetCardActiveFromInvitation = (): UserAction => ({
  type: userTypesEnum.RESET_SET_CARD_ACTIVE_FROM_INVITATION,
});

const setCardInActive = (): UserAction => ({
  type: userTypesEnum.SET_CARD_INACTIVE,
});

const setPolicies = (policies: Policies[]): UserAction => ({
  type: userTypesEnum.SET_POLICIES,
  policies: policies,
});

const setReservationPolicies = (policies: Policies[]): UserAction => ({
  type: userTypesEnum.SET_RESERVATION_POLICIES,
  policies: policies,
});

const setOtherPolicies = (policies: OtherPolicies[]): UserAction => ({
  type: userTypesEnum.SET_OTHER_POLICIES,
  otherPolicies: policies,
});

const setInvitationCode = (code: string): UserAction => ({
  type: userTypesEnum.SET_INVITATION_CODE,
  code: code,
});

const setUserPermissions = (permissions: string[]): UserAction => ({
  type: userTypesEnum.SET_PERMISSIONS,
  permissions: permissions,
});

const setAllPermissions = (allPermissions: Permissions[]): UserAction => ({
  type: userTypesEnum.SET_ALL_PERMISSIONS,
  allPermissions: allPermissions,
});

const setUserLangDefined = (): UserAction => ({
  type: userTypesEnum.SET_USER_LANG_DEFINED,
});

const setUserAcceptedPolicies = (): UserAction => ({
  type: userTypesEnum.SET_USER_ACCEPTED_POLICIES,
});

const setMsg = (msg: Message): UserAction => ({
  type: userTypesEnum.SET_MSG,
  msg: msg,
});

const setSede = (userSede: Sede): UserAction => ({
  type: userTypesEnum.SET_SEDE,
  userSede: userSede,
});

const unSetInvitationCode = (): UserAction => ({
  type: userTypesEnum.UNSET_INVITATION_CODE,
});

const userRequest = (): UserAction => {
  return {
    type: userTypesEnum.REQUEST,
  };
};

const fetchUserFailure = (error: string): UserAction => {
  return {
    type: userTypesEnum.FAILURE_USER,
    error: error,
  };
};

const resetMsgUser = (): UserAction => ({
  type: userTypesEnum.RESET_MESSAGE,
});

const resetUser = (): UserAction => ({
  type: userTypesEnum.RESET_USER,
});

const setWallet = (dataWallet: Wallet): UserAction => ({
  type: userTypesEnum.SET_WALLET,
  wallet: dataWallet,
});

const setWalletV2 = (dataWallet: WalletV2): UserAction => ({
  type: userTypesEnum.SET_WALLETV2,
  walletV2: dataWallet,
});

const setPublicHolidays = (days: string[]): UserAction => ({
  type: userTypesEnum.SET_PUBLIC_HOLIDAYS,
  publicHolidays: days,
});

const setHasValidDistrict = (userDistrictIsValid: boolean): UserAction => ({
  type: userTypesEnum.SET_HAS_VALID_DISTRICT,
  hasValidDistrict: userDistrictIsValid,
});

const setUserIsRegisteredInDatabase = (
  userIsNotRegisteredInDatabase: boolean,
): UserAction => ({
  type: userTypesEnum.SET_USER_IS_REGISTERED_IN_DATABASE,
  userIsRegisteredInDatabase: userIsNotRegisteredInDatabase,
});

const deepLinkCodeReceived = (code: string) => {
  return async (dispatch: any): Promise<any> => {
    await dispatch(setInvitationCode(code));
    history.replace('/dashboard/home');
  };
};

const setCacheUserDetails = (dispatch: any) => {
  const cachedUserDetails = JSON.parse(
    localStorage.getItem('userDetails') || '{}',
  );
  dispatch(setUserDetails(cachedUserDetails));
};

const getUser = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const userDetails = await userServices.getUserData();
      dispatch(setUserDetails(userDetails));
      const {
        employeeId,
        name,
        firstSurname,
        secondSurname,
        defaultSede,
        sedesList,
      } = userDetails;
      localStorage.setItem(
        'userDetails',
        JSON.stringify({
          employeeId: employeeId,
          name: name,
          firstSurname: firstSurname,
          secondSurname: secondSurname,
          defaultSede: defaultSede,
          sedesList: sedesList,
          professionalMobile: null,
          landlinePhone: null,
          professionalEmail: null,
          personalEmail: null,
          district: '',
          districtName: '',
          permissions: [],
          movingDate: null,
        }),
      );
    } catch (e) {
      setCacheUserDetails(dispatch);
      if (e.message === 'not_available_head_office') {
        dispatch(fetchUserFailure(e.message));
      }
      throw e;
    }
  };
};

const getUserPermissions = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const allPermissions: Permissions[] =
        await userServices.getUserPermissions();
      if (!_.isEmpty(allPermissions)) {
        dispatch(setAllPermissions(allPermissions));
        const userDefaultSedeData = allPermissions.find(
          e => e.id === state().user.user.defaultSede.id,
        );
        const userDefaultSedePermissions =
          userDefaultSedeData?.permissions ?? [];
        dispatch(setUserPermissions(userDefaultSedePermissions));
      } else {
        dispatch(setUserPermissions([]));
      }
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
      throw e;
    }
  };
};

const loadPolicies = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const policies: Policies[] = await userServices.getPolicies();
      dispatch(setPolicies(policies));
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getPoliciesAndStatus = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const policies: Policies[] = await userServices.getPolicies();

      dispatch(setPolicies(policies));
      if (!_.isEmpty(policies.filter(p => !p.hasAccepted))) {
        return '/policies';
      } else {
        dispatch(userActions.setUserAcceptedPolicies());
        return '/dashboard/home';
      }
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
      throw e;
    }
  };
};

const getReservationPolicies = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const policies: Policies[] = await userServices.getReservationPolicies();

      dispatch(setReservationPolicies(policies));
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getOtherPolicies = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const policies: OtherPolicies[] = await userServices.getOtherPolicies();
      dispatch(setOtherPolicies(policies));
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const acceptPolicies = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const accepted = await userServices.acceptPolicies(
        state()
          .user.policies.filter(p => !p.hasAccepted)
          .map(p => {
            return p.id;
          }),
      );
      if (accepted) {
        const path = await dispatch(getPoliciesAndStatus());
        history.replace(path);
      }
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getLanguageSettings = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(userRequest());
      const locale: Locale = (await userServices.getUserLanguage())?.code;
      if (_.isEmpty(locale)) {
        const localLanguage = localStorage.getItem('i18nextLng') as Locale;
        await userServices.setUserLanguage(localLanguage);
      } else if (locale !== state().app.localSettings.localLanguage) {
        i18n.changeLanguage(locale);
        localStorage.setItem('i18nextLng', locale);
        await dispatch(
          appActions.setLocalSettings({
            ...state().app.localSettings,
            localLanguage: locale,
          }),
        );
      }
      dispatch(setUserLangDefined());
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
      throw e;
    }
  };
};

const setActivationCodeRequested = (activationCodeRequestStatus: boolean) => {
  return {
    type: userTypesEnum.SET_CARD_ACTIVATION_REQUESTED,
    activationCodeRequestStatus: activationCodeRequestStatus,
  };
};

const setCodeRequestDate = (codeRequestDate: string) => {
  return {
    type: userTypesEnum.SET_CODE_REQUEST_DATE,
    codeRequestDate: codeRequestDate,
  };
};

const sendEmailNewCardCode = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      await userServices.sendEmailNewCardCode();

      dispatch(
        setMsg({
          description: i18n.t('email_new_card_code_OK'),
          type: 'success',
        }),
      );

      dispatch(setActivationCodeRequested(true));
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getWallet = (from = null) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const dataWallet: any = await userServices.getWallet(from);
      if (state().user.wallet?.transactions?.length) {
        const newTransactions = state()
          .user.wallet.transactions.concat(dataWallet.transactions)
          .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);

        dataWallet.transactions = _.orderBy(newTransactions, 'date', 'desc');
      }
      dispatch(setWallet({ ...dataWallet, lastUpdated: new Date() }));
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getWalletV2 = (clientId: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const data: any = await userServices.getWalletV2(clientId);
      dispatch(
        setWalletV2({
          administrativeBalance: data.administrativeBalance,
          movements: data.movements,
          lastUpdated: new Date(),
        }),
      );
    } catch (e) {
      dispatch(
        NotificationsActions.setGenericErrorToast(
          i18n.t('msg_error_wallet_list'),
        ),
      );
      history.replace('/dashboard/home');
    }
  };
};

const getPublicHolidays = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const dataHolidays: Holidays[] = await userServices.getPublicHolidays();

      dispatch(
        setPublicHolidays(
          dataHolidays.map(e => {
            return e.absenceDate;
          }),
        ),
      );
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const updateUserDefaultHeadOffice = (sede: Sede) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const responseSede: Sede = await userServices.setDefaultHeadOffice(
        sede.id,
      );
      dispatch(setSede(responseSede));
      dispatch(setPolicies([]));
      dispatch(setReservationPolicies([]));
      await dispatch(userActions.getUserConfig(true));
      // Must be "/" instead of "/login" in Ionic 7+
      history.replace('/'); // Redirects to "/login" in <App> router
      dispatch(
        setMsg({
          description: i18n.t('lbl_head_office_change_success'),
          type: 'success',
        }),
      );
    } catch (e) {
      dispatch(
        setMsg({
          description: e.message,
          type: 'error',
        }),
      );
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const getUserHeadOffice = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      const data = await userServices.getUserHeadOffice();
      if (!data?.defaultSede && !isEmpty(data?.sedesList)) {
        data.defaultSede = data.sedesList[0];
      }
      dispatch(setUserHeadOffice(data));
    } catch (e) {
      if (e.status === 403) {
        dispatch(setUserIsRegisteredInDatabase(false));
        dispatch(authActions.setIsLoading(false));
        return;
      }
      dispatch(setUserIsRegisteredInDatabase(true)); // Required for activation of the offline service mode
      dispatch(fetchUserFailure(e.message));
    }
  };
};

/**
 * Obtains user, language, permissions and policies.
 * If it fails, applies _offlineServices_ mode to the application.
 * @param {boolean} initial - It is called at the start of the app or not (default: false)
 */
const getUserConfig = (initial: boolean = false) => {
  return async (dispatch: any, state: any): Promise<any> => {
    let hasError = false;
    let errorMessage = '';

    const account = await tokenServices.getAccount();

    if (account === undefined || account === null) {
      return;
    }

    try {
      await dispatch(getUserHeadOffice());
    } catch (error) {
      if (error.status != 401) {
        hasError = true;
        errorMessage = error.message || 'error';
      }
    }

    try {
      await dispatch(getUser());
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = error.message || 'error';
    }

    try {
      await dispatch(getLanguageSettings());
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = errorMessage || error.message || 'error';
    }

    try {
      await dispatch(appActions.getAppSettings());
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = errorMessage || error.message || 'error';
    }

    try {
      await dispatch(getUserPermissions());
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = errorMessage || error.message || 'error';
    }

    try {
      const path = await dispatch(getPoliciesAndStatus());
      if (!hasError && (initial || path !== '/dashboard/home')) {
        history.replace(path);
      }
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = errorMessage || error.message || 'error';
    }

    if (hasError && state().user.user.userIsRegisteredInDatabase) {
      dispatch(fetchUserFailure(errorMessage));
      if (!state().app.offlineNetwork) {
        // Only set offline services mode if there is an active internet connection
        dispatch(appActions.setOfflineServices(true));
      }
    } else {
      dispatch(appActions.setOfflineServices(false));
    }

    try {
      authServices.registerUserHabitat();
      dispatch(workstationActions.setWorkstations([]));
    } catch (error) {
      console.log(error);
    }
  };
};

const saveUserDeskPreferences = (
  preferencias: UserWorkPreferences,
  onSuccess?: () => void,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      await userServices.setUserDeskPreferences(preferencias);
      dispatch(getUser());
      onSuccess && onSuccess();
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};
const saveUserParkingPreferences = (preferencias: UserParkingPreferences) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(userRequest());
    try {
      await userServices.setUserParkingPreferences(preferencias);
      dispatch(getUser());
    } catch (e) {
      dispatch(fetchUserFailure(e.message));
    }
  };
};

const validateUserDistrict = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(userRequest());

    if (!state().user.user.district || _.isEmpty(!state().user.user.district)) {
      dispatch(getUser());
    }

    try {
      dispatch(userRequest());
      const data = await userServices.validateUserDistrict();
      const districtIsValid = _.has(data, 'response') ? data.response : data;
      dispatch(setHasValidDistrict(districtIsValid));
    } catch (error) {
      dispatch(fetchUserFailure(error));
    }
  };
};

export const userActions = {
  getUser,
  setCardActive,
  setCardInActive,
  resetMsgUser,
  resetUser,
  setInvitationCode,
  unSetInvitationCode,
  getUserPermissions,
  setUserPermissions,
  acceptPolicies,
  getPoliciesAndStatus,
  loadPolicies,
  setUserLangDefined,
  setUserAcceptedPolicies,
  getReservationPolicies,
  getOtherPolicies,
  setCardRegistered,
  setCardRegisteredFromInvitationCode,
  setCardUnRegistered,
  resetSetCardFromInvitation,
  resetSetCardActiveFromInvitation,
  getLanguageSettings,
  setReservationPolicies,
  setPolicies,
  setCodeRequestDate,
  sendEmailNewCardCode,
  resetCard,
  getWallet,
  getWalletV2,
  getPublicHolidays,
  setActivationCodeRequested,
  updateUserDefaultHeadOffice,
  getUserConfig,
  deepLinkCodeReceived,
  saveUserDeskPreferences,
  validateUserDistrict,
  saveUserParkingPreferences,
  getUserHeadOffice,
};
