import { IonGrid, IonCol, IonRow } from '@ionic/react';
import styles from './styles.module.scss';

import { Icon } from '@acciona/ui-ionic-kit';

const Transactions: React.FC<any> = props => {
  return (
    <IonGrid
      fixed
      className={`${styles.gridTransactions}`}
      onClick={props.goToDetailTransaction}
    >
      <IonRow>
        <IonCol size="8" className="ion-no-padding">
          {/* <p className={styles.concept}>
            {props.commerceName} - #{props.productCode}
          </p> */}
          <p className={styles.concept}>{props.commerceName}</p>
        </IonCol>
        <IonCol className="ion-text-end ion-no-padding" size="4">
          <IonRow className="ion-align-items-center ion-justify-content-end ion-padding-end">
            <p
              className={`${styles.expense} ${
                props.expense < 0 ? styles.negative : styles.positive
              }`}
            >
              {props.expense}
            </p>
            <Icon
              className={`icon icon-chevron-right ${styles.iconChevronRight}`}
            />
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="9" className="ion-no-padding">
          <p className={styles.placeTr}>
            {props.date} - {props.place}
          </p>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Transactions;
