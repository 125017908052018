import { IonImg } from '@ionic/react';
import { useLoadSafeImageUrl } from '../../hooks/useLoadSafeImageUrl';

type IonImgProps = React.ComponentProps<typeof IonImg>;

type Props = IonImgProps & {
  fallback?: string;
};
export const SafeImage: React.FC<Props> = ({ src, fallback = '', ...rest }) => {
  const { data: imageUrl } = useLoadSafeImageUrl(src, fallback);
  if (imageUrl === '') return <></>;
  return <IonImg src={imageUrl} {...rest} />;
};

export default SafeImage;
