import _ from 'lodash';
import dayjs from 'dayjs';
import { ReserveVehicles } from '../../../_redux/models/parking.model';
import { Vehicle } from '../../../_redux/models/vehicles.model';
import { getDeviceTimeZoneOffset } from '../../../utils/dateTime';

export const getAllDatesMachWithVehicle = (
  selectedDates: Date[],
  reserveVehicles: ReserveVehicles[],
  suggestedVehicle: Vehicle,
) => {
  const selectedDateToStrings = selectedDates?.map(
    date => new Date(date).toISOString().split('T')[0],
  );

  return selectedDateToStrings?.every(date => {
    return (
      _.isEmpty(reserveVehicles) ||
      reserveVehicles.some(v => {
        const dateIncludedInVehicleReservation = v.day.includes(date);
        if (!dateIncludedInVehicleReservation) return true;
        return v.plate === suggestedVehicle.plate;
      })
    );
  });
};

export const getFormatedDateString = (date: any) => {
  const deviceTimeZoneOffset = getDeviceTimeZoneOffset();
  const dateObject = new Date(date.substring(0, 10)); // 00:00 UTC
  dateObject.setHours(dateObject.getHours() + deviceTimeZoneOffset); // Local time as UTC, i.e.: if offset = 6, then 06:00 UTC or 00:00 local time
  return dayjs(dateObject).format('ddd DD MMM');
};

export const getDayCalendarDate = (date: any) => {
  return new Date(date.substring(0, 10)); // 00:00 UTC
};
