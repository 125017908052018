import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import styles from './styles.module.scss';
import { Icon, Snackbar, Toast, TopBar } from '@acciona/ui-ionic-kit';
import HeaderImage from './components/HeaderImage/HeaderImage';
import { useEffect, useMemo, useState } from 'react';
import SummarySection from './components/SummarySection/SummarySection';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useFormattedCurrency from '../../../../hooks/useFormattedCurrency';
import { restaurantTableServices } from '../../../../_redux/services/restaurantTable/restaurantTable.services';
import { App } from '@capacitor/app';
import { NotificationsActions } from '../../../../_redux/actions/notifications.actions';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../../_redux/models/user.model';
import { userActions } from '../../../../_redux/actions/user.actions';
import { useExternalLink } from '../../../../hooks/useExternalLink';
import { useBlobOpener } from '../../../../hooks/useBlobOpener';

interface Props {
  orderId: string;
}

const OrderDetail: React.FC = () => {
  const history = useHistory();
  const { orderId } = useParams<Props>();
  const { otherPolicies } = useSelector((store: UserStore) => store.user);
  const { t } = useTranslation();
  const formatCurrency = useFormattedCurrency();
  const [orderData, setOrderData] = useState([]);
  const [total, setTotal] = useState('0');
  const [ptsAcciona, setPtsAcciona] = useState('0');
  const [commerceName, setCommerceName] = useState('');
  const dispatch = useDispatch();
  const { openLink } = useExternalLink();

  const refundPolicy = useMemo(
    () =>
      otherPolicies
        ? otherPolicies
            .find(category =>
              category.documents.some(document =>
                document.name.toLowerCase().includes('reembolso'),
              ),
            )
            ?.documents.find(
              document =>
                document.name.toLowerCase().includes('reembolso') ||
                document.name.toLowerCase().includes('refund'),
            )?.url
        : '',
    [otherPolicies],
  );
  const { openBlob, error: pdfError } = useBlobOpener();

  useEffect(() => {
    getOrder();
    dispatch(userActions.getOtherPolicies());
  }, []);

  useEffect(() => {
    const setupBackButtonListener = async () => {
      const handleBackButton = () => {
        goBackHome();
      };
      const backButtonListener = await App.addListener(
        'backButton',
        handleBackButton,
      );
      return () => {
        backButtonListener.remove();
      };
    };

    setupBackButtonListener(); // Llama a la función para configurar el listener
  }, [history]);

  const getOrder = async () => {
    await restaurantTableServices
      .getOrder(orderId)
      .then(response => {
        if (response.success && response.data) {
          setTotal(formatCurrency(response.data.totalRest || 0));
          setPtsAcciona(response.data.totalPoints || 0);
          setOrderData(response.data.associatedData?.items);
          setCommerceName(response.data.commerceName || '');
        } else {
          handleBackWithError();
        }
      })
      .catch(() => {
        handleBackWithError();
      });
  };

  const handleBackWithError = () => {
    dispatch(
      NotificationsActions.setGenericErrorToast(t('msg_error_payment_service')),
    );
    history.replace('/dashboard/actions');
  };

  const handlePolicyError = () => {
    dispatch(
      NotificationsActions.setGenericErrorToast(
        'No se ha podido obtener el archivo',
      ),
    );
  };

  const goBackHome = () => {
    history.push('/dashboard/home');
  };

  const openReceipt = async () => {
    try {
      const receiptResponse = await restaurantTableServices.getReceipt(orderId);
      if (receiptResponse) {
        openBlob(receiptResponse, 'recibo.pdf');
      } else {
        showToastError();
      }
    } catch (error) {
      showToastError();
    }
  };

  const showToastError = () => {
    dispatch(
      NotificationsActions.setGenericErrorToast(t('msg_error_open_receipt')),
    );
  };

  return (
    <>
      <IonPage>
        <IonHeader mode="ios" className={styles.toolbarGrid}>
          <TopBar
            primaryActions={
              <IonButton className={styles.button} onClick={() => goBackHome()}>
                {t('close_text')}
              </IonButton>
            }
            title={commerceName}
          />
        </IonHeader>

        <IonContent>
          <HeaderImage />
          <div className={styles.content}>
            <h1 className={styles.title}>
              {t('restaurant_orderSummary_title')}
            </h1>
            <div className={styles.orderSummary}>
              <div className={styles.orderSummary__detail}>
                {orderData &&
                  orderData.map(item => {
                    return <SummarySection key={item.name} order={item} />;
                  })}
              </div>
            </div>
          </div>
          <Toast
            isOpen={!!pdfError}
            message={t('msg_error_open_receipt')}
            position="bottom"
            type="error"
          />
        </IonContent>
        <IonFooter>
          <Snackbar
            type="white"
            text={
              <IonText className={styles.messageFooter}>
                {t('restaurant_snackbar_text1')}
                <a
                  className={styles.defaultLink}
                  onClick={() =>
                    refundPolicy ? openLink(refundPolicy) : handlePolicyError
                  }
                >
                  {t('restaurant_snackbar_text2')}
                </a>
              </IonText>
            }
            icon="icon icon-info"
            align="left"
          ></Snackbar>
          <div className={styles.total}>
            <p>{t('total')}</p>
            <p className={styles.totalPrice}>{`${ptsAcciona} ${t(
              'balance',
            )} | ${total}`}</p>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.downloadButton}
              onClick={() => openReceipt()}
            >
              <Icon
                slot="icon-only"
                className={`${styles.downloadIcon} icon icon-24 icon-share-2`}
              ></Icon>
              <p>{t('msg_download_receipt')}</p>
            </button>
          </div>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default OrderDetail;
