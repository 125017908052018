import { Icon, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonText,
  IonGrid,
  IonCol,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonLabel,
} from '@ionic/react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../_redux/actions';
import { UserStore } from '../../../_redux/models/user.model';
import styles from './styles.module.scss';
import isYesterday from 'dayjs/plugin/isYesterday';
import useFormattedCurrency from '../../../hooks/useFormattedCurrency';
dayjs.extend(isYesterday);

const WalletOld: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatCurrency = useFormattedCurrency();
  const { isLoading, wallet, error } = useSelector(
    (state: UserStore) => state.user,
  );

  useEffect(() => {
    dispatch(userActions.getWallet());
  }, []);

  const handleUpdateTransactions = async () => {
    dispatch(userActions.getWallet(dayjs().tz().toISOString()));
  };

  const getFormatDate = (date: string) => {
    const dateFormat = dayjs(date).utc();
    if (dateFormat.isToday()) {
      return t('lbl_today');
    } else if (dateFormat.isYesterday()) {
      return t('lbl_yesterday');
    } else {
      return dateFormat.format('DD/MM/YYYY');
    }
  };

  const LastMovements = () => {
    return wallet !== null || !!error ? (
      <IonGrid fixed className={styles.lastMovementsContainer}>
        <IonRow>
          <IonCol size="10" className="ion-no-padding">
            <IonText className={styles.headline}>{t('last_movments')}</IonText>
          </IonCol>
          <IonCol className="ion-text-end ion-no-padding" size="2">
            <div className={styles.iconContainer}>
              {!isLoading ? (
                <Icon
                  onClick={handleUpdateTransactions}
                  className={`icon icon-refresh ${styles.iconFont}`}
                />
              ) : (
                <IonSpinner name="crescent" className={styles.spinner} />
              )}
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonText className={styles.lastMovementsTitle}>
              {t('last_update')}
              {wallet &&
                dayjs(wallet.lastUpdated).format(' DD MMM YYYY[,] HH:mm')}
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    ) : (
      <IonGrid fixed className={styles.lastMovementsContainer}>
        <IonRow>
          <IonCol size="10">
            <IonSkeletonText animated style={{ width: '50%' }} />
          </IonCol>
          <IonCol className="ion-text-end" size="2">
            <IonSkeletonText animated style={{ width: '80%' }} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="10">
            <IonSkeletonText animated style={{ width: '50%' }} />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const Transactions = ({ ...props }) => (
    <IonGrid fixed className={`${styles.gridTransactions}`}>
      <IonRow>
        <IonCol size="9" className="ion-no-padding">
          <p className={styles.concept}>{props.concept}</p>
        </IonCol>
        <IonCol className="ion-text-end ion-no-padding" size="3">
          <p
            className={`${styles.expense} ${
              props.expense < 0 ? styles.negative : styles.positive
            }`}
          >
            {formatCurrency(props.expense)}
          </p>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="9" className="ion-no-padding">
          <p className={styles.placeTr}>
            {getFormatDate(props.date)} - {props.place}
          </p>
        </IonCol>
        <IonCol className="ion-text-end ion-no-padding" size="3">
          <p className={styles.balanceTr}>
            {formatCurrency(props.postBalance)}
          </p>
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_wallet')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.container}>
          <div className={styles.itemBalances}>
            <div
              className={`${styles.balance} ${styles.lightStyle}`}
              color="secondary"
            >
              <div className={styles.saldoEuro}>
                {wallet !== null ? (
                  <>{formatCurrency(wallet.administrativeBalance)}</>
                ) : (
                  <h1>-</h1>
                )}
              </div>
              <div className={styles.saldoText}>
                {t('administrative_balance')}
              </div>
            </div>
            <div className={`${styles.balance} ${styles.darkStyle}`}>
              <div className={styles.saldoEuro}>
                {wallet !== null ? (
                  <>{formatCurrency(wallet.personalBalance)}</>
                ) : (
                  <h1>-</h1>
                )}
              </div>
              <div className={styles.saldoText}>{t('personal_balance')}</div>
            </div>
          </div>
          <div className={styles.itemLastMovements}>
            {wallet?.transactions.length > 0 && <LastMovements />}
          </div>
          <div className={styles.itemMovements}>
            {wallet?.transactions.length
              ? wallet.transactions.map(tr => {
                  return <Transactions key={tr.id} {...tr} />;
                })
              : !isLoading && (
                  <div className={styles.noTransactionsContainer}>
                    <IonRow className="ion-justify-content-center">
                      <IonCol className={styles.header}>
                        <IonLabel className={styles.title_2}>
                          {t('no_transactions.header')}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol className={styles.subheader}>
                        <IonLabel className={styles.subhead}>
                          {t('no_transactions.firstSubheader')}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol className={styles.subheader}>
                        <IonLabel className={styles.subhead}>
                          {t('no_transactions.secondSubheader')}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  </div>
                )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default WalletOld;
