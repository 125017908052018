import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonLoading,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import styles from './styles.module.scss';

const ScanQRCode: React.FC<Props> = ({
  handleBackWithError,
  handleClose,
  handleData,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(5, () => {
        handleClose();
      });
    };
    scanCode();
    document.addEventListener('ionBackButton', backFunction);
    return () => {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  const scanCode = async () => {
    BarcodeScanner.hideBackground();
    setShowCamera(true);
    const data = await BarcodeScanner.startScan();
    try {
      handleData(data.content);
    } catch (error) {
      setShowCamera(false);
      BarcodeScanner.showBackground();
      handleBackWithError();
    }
  };

  const close = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    handleClose();
  };

  return (
    <IonPage>
      <IonHeader className={`ion-no-border`}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="secondary">
            <IonButton className={styles.icon} onClick={close}>
              <IonIcon slot="icon-only" className="icon icon-close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={showCamera && styles.transparentContent}>
        <div className={styles.container}>
          <div className={styles.barcodeScannerAreaContainer}>
            <div className={`${styles.square} ${styles.surroundCover}`}>
              <div
                className={`${styles.barcodeScannerAreaOuter} ${styles.surroundCover}`}
              >
                <div className={styles.barcodeScannerAreaInner}></div>
              </div>
            </div>
          </div>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ScanQRCode;
