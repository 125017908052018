import { useState } from 'react';
import { isPlatform } from '@ionic/react';
import { FileSystemService } from '../_redux/services/fileSystem/fileSystem.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import {
  DocumentViewer,
  DocumentViewerOptions,
} from '@awesome-cordova-plugins/document-viewer';

export const useBlobOpener = () => {
  const [error, setError] = useState('');

  const openBlob = async (blob: Blob, fileName: string) => {
    try {
      if (isPlatform('capacitor')) {
        const base64File = await blobToBase64(blob);
        const localFilePath = await FileSystemService.saveFileToLocalStorage(
          base64File,
          fileName,
        );

        if (isPlatform('android')) {
          await openAndroidFile(localFilePath);
        } else {
          openIOSFile(localFilePath, fileName);
        }
      } else {
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank'); // Abre el PDF en una nueva pestaña
      }
    } catch (err) {
      setError(err);
    }
  };

  const blobToBase64 = (blob: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result?.toString().split(',')[1]; // Elimina el encabezado "data:application/pdf;base64,"
        if (base64data) {
          resolve(base64data);
        } else {
          reject('Error converting Blob to Base64');
        }
      };
      reader.onerror = () => reject('Error reading Blob');
    });
  };

  const openAndroidFile = async (localFilePath: string) => {
    await FileOpener.open(localFilePath, 'application/pdf');
  };

  const openIOSFile = (filePath: string, fileName: string) => {
    const documentType = 'application/pdf';
    const options: DocumentViewerOptions = { title: fileName };
    DocumentViewer.viewDocument(filePath, documentType, options);
  };

  return { error, openBlob };
};
