import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { IonLoading, IonPage } from '@ionic/react';
import { restaurantTableServices } from '../../../../../_redux/services/restaurantTable/restaurantTable.services';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationsActions } from '../../../../../_redux/actions/notifications.actions';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';

const IFrameContainer: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const locationState = history.location.state as {
    url: string;
    orderId: string;
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handlePendingPayment = (event: MessageEvent) => {
      if (event?.data?.type === 'exit_order') {
        handleBack();
      }
      if (event?.data?.type === 'payment_pending') {
        setIsLoading(true);
        payOrder();
      }
    };

    window.addEventListener('message', handlePendingPayment);

    return () => {
      window.removeEventListener('message', handlePendingPayment);
    };
  }, []);

  const payOrder = async () => {
    const SUCCESS_URL = `${process.env.REACT_APP_BASE_URL}/payment-confirmation/${locationState.orderId}/success`;
    const ERROR_URL = `${process.env.REACT_APP_BASE_URL}/payment-confirmation/${locationState.orderId}/error`;

    try {
      const payOrderResponse = await restaurantTableServices.payOrder(
        locationState.orderId,
        SUCCESS_URL,
        ERROR_URL,
      );
      if (!payOrderResponse.success || !payOrderResponse.data) {
        handleBackWithError();
      }
      if (payOrderResponse.data.paid) {
        setIsLoading(false);
        history.push(`/spaces/restaurant/payment/${locationState.orderId}`);
        return;
      } else {
        const url = payOrderResponse.data.urlStripe;
        handleIOSPayment(url);
      }
    } catch (error) {
      handleBackWithError();
      setIsLoading(false);
    }
  };

  const handleIOSPayment = url => {
    const options = `location=no,clearcache=yes`;

    const externalBrowser = InAppBrowser.create(url, '_blank', options);

    externalBrowser.on('loadstart').subscribe(event => {
      if (event.url.includes('success')) {
        setIsLoading(false);
        externalBrowser.close();
        history.push(`/spaces/restaurant/payment/${locationState.orderId}`);
      }

      if (event.url.includes('error')) {
        setIsLoading(false);
        externalBrowser.close();
        history.push('/restaurant/error');
      }
    });

    externalBrowser.on('loaderror').subscribe(event => {
      setIsLoading(false);
      if (event.url.includes('error')) {
        externalBrowser.close();
        history.push('/restaurant/error');
      }
    });

    externalBrowser.on('exit').subscribe(() => {
      setIsLoading(false);
    });
  };

  const handleBackWithError = () => {
    dispatch(
      NotificationsActions.setGenericErrorToast(t('msg_error_payment_service')),
    );
    history.replace('/dashboard/actions');
  };

  const handleBack = () => {
    history.push('/dashboard/actions');
  };
  return (
    <>
      <IonPage>
        {isLoading ? (
          <IonLoading
            isOpen={isLoading}
            message={t('msg_redirect_payment')}
            duration={0}
          />
        ) : (
          <div>
            <iframe
              className={styles.iframeContainer}
              src={locationState?.url}
              title={'Waitry'}
            />
          </div>
        )}
      </IonPage>
    </>
  );
};

export default IFrameContainer;
