import { useEffect, useState } from 'react';

export const useRequestData = (
  requestFn: () => Promise<any>,
  initialData: any,
) => {
  const [data, setData] = useState<any>(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await requestFn();
      setData(data);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return { data, isLoading, error };
};
