import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

const DetailHeader: React.FC<any> = props => {
  return (
    <div className={styles.container}>
      <div className={styles.iconSection}>
        <Icon className={`icon icon-24 icon-documentation`} />
      </div>
      <div className={styles.dataSection}>
        <div className={styles.nameSection}>
          <p className={styles.description}>{props.description}</p>
          <p className={styles.title}>{props.nameDetail}</p>
        </div>
        <div className={styles.pointsSection}>
          <p className={styles.numberPoints}>{props.points}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailHeader;
