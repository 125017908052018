import type {
  ReleasedDeskDays,
  Reservation,
  ReservationAction,
  ReservationList,
  reservedDay,
} from '../models/reservation.model';
import {
  Message,
  ConfigurationDeskReservationForToday,
} from '../models/reservation.model';
import { reservationServices } from '../services';
import { tokenServices } from '../services/token/token.services';
import { reservationsTypesEnum } from '../types';
import { history } from '../../_helpers/history';
import { Workstation, WorkstationRequest } from '../models/workstation.model';
import { Host, WaitingList, Workroom } from '../models/workroom.model';
import {
  Companions,
  Parking,
  ParkingReleaseItem,
  ParkingRequest,
} from '../models/parking.model';
import { isEmpty } from '../../utils/functions';
import { groupDatesByMonth } from '../../utils/dateTime';
import { workroomActions } from './workroom.actions';
import dayjs from 'dayjs';
import i18n from '../../i18nextConf';
import _ from 'lodash';
import { getDatesToReleaseText } from './../../utils/functions';
import { POLICIES_SCHEDULES } from '../../utils/constants';
import { LockerDetail } from '../models/locker.model';
import { Canteen } from '../models/canteen.model';

const reservationRequest = (): ReservationAction => {
  return {
    type: reservationsTypesEnum.REQUEST,
  };
};

const fetchReservationFailure = (
  error: string,
  isOffline: boolean = false,
): ReservationAction => {
  return {
    type: reservationsTypesEnum.FAILURE_RESERVATIONS,
    error: error,
    reservationsServiceIsOffline: isOffline,
  };
};

const setReservations = (
  reservations: ReservationList[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_RESERVATIONS,
  reservations: reservations,
});

const setPermanentParkings = (
  permanentParkings: ReservationList[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_PERMANENT_PARKINGS,
  permanentParkings: permanentParkings,
});

const setPermanentDesks = (
  permanentDesks: ReservationList[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_PERMANENT_DESKS,
  permanentDesks: permanentDesks,
});

const addReservation = (reservation: Reservation): ReservationAction => ({
  type: reservationsTypesEnum.ADD_RESERVATION,
  reservation: reservation,
});

const setReservedWorkstation = (
  workstation: Workstation,
): ReservationAction => ({
  type: reservationsTypesEnum.SET_WORKSTATION_RESERVATION,
  reservedWorkstation: workstation,
});
const setReservedLocker = (locker: LockerDetail): ReservationAction => ({
  type: reservationsTypesEnum.SET_LOCKER_RESERVATION,
  reservedLocker: locker,
});

const setReservedHomeOffice = (
  homeOfficeDate: string[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_HOMEOFFICE,
  homeOfficeDays: homeOfficeDate,
});

const setReservedRafflesWr = (
  workstationRaffles: WorkstationRequest[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_WORKSTATION_RAFFLES,
  workstationRaffles: workstationRaffles,
});

const setReservedRafflesPk = (
  parkingRaffles: ParkingRequest[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_PARKING_RAFFLES,
  parkingRaffles: parkingRaffles,
});

const deleteRaffleCompanion = (
  companionId: number,
  requestId: number,
): ReservationAction => {
  return {
    type: reservationsTypesEnum.DELETE_PK_RAFFLE_COMPANION,
    companionId: companionId,
    requestId: requestId,
  };
};

const addRaffleCompanion = (
  companions: Companions[],
  requestId: number,
): ReservationAction => {
  return {
    type: reservationsTypesEnum.ADD_PK_RAFFLE_COMPANIONS,
    companions: companions,
    requestId: requestId,
  };
};

const setReservedWorkroom = (workroom: Workroom): ReservationAction => ({
  type: reservationsTypesEnum.SET_WORKROOM_RESERVATION,
  reservedWorkroom: workroom,
});

const setReservedCanteen = (canteen: Canteen): ReservationAction => ({
  type: reservationsTypesEnum.SET_CANTEEN_RESERVATION,
  reservedCanteen: canteen,
});

const setReservedParking = (parking: Parking): ReservationAction => ({
  type: reservationsTypesEnum.SET_PARKING_RESERVATION,
  reservedParking: parking,
});

const cancelReservedWorkstation = (
  workstation: Workstation,
): ReservationAction => ({
  type: reservationsTypesEnum.CANCEL_RESERVED_WORKSTATION,
  reservedWorkstation: workstation,
});

const cancelReservedWorkroom = (workroom: Workroom): ReservationAction => ({
  type: reservationsTypesEnum.CANCEL_RESERVED_WORKROOM,
  reservedWorkroom: workroom,
});
const cancelReservedCanteen = (canteen: Canteen): ReservationAction => ({
  type: reservationsTypesEnum.CANCEL_RESERVED_CANTEEN,
  reservedCanteen: canteen,
});

const cancelReservedParking = (parking: Parking): ReservationAction => ({
  type: reservationsTypesEnum.CANCEL_RESERVED_PARKING,
  reservedParking: parking,
});
const resetMsgReservation = (): ReservationAction => ({
  type: reservationsTypesEnum.RESET_MESSAGE,
});

const msgReservation = (msgAction: Message): ReservationAction => ({
  type: reservationsTypesEnum.MSG_RESERVATION,
  msg: msgAction,
});

const setReservedDaysWs = (reservedDays: reservedDay[]): ReservationAction => ({
  type: reservationsTypesEnum.SET_DATES_WS_RESERVED,
  reservedWorkstationDays: reservedDays,
});

const setIsUserInCampus = (isUserInCampus: boolean): ReservationAction => ({
  type: reservationsTypesEnum.SET_IS_USER_IN_CAMPUS,
  isUserInCampus: isUserInCampus,
});

const setReservedDaysPk = (reservedDays: reservedDay[]): ReservationAction => ({
  type: reservationsTypesEnum.SET_DATES_PK_RESERVED,
  reservedParkingDays: reservedDays,
});

const setReservedDaysWsAndHomeOffice = (
  reservedDays: reservedDay[],
  homeOfficeDate: string[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_DATES_WS_RESERVED_AND_HOMEOFFICE,
  reservedWorkstationDays: reservedDays,
  homeOfficeDays: homeOfficeDate,
});

const setReservedDaysPkAndHomeOffice = (
  reservedDays: reservedDay[],
  homeOfficeDate: string[],
): ReservationAction => ({
  type: reservationsTypesEnum.SET_DATES_PK_RESERVED_AND_HOMEOFFICE,
  reservedParkingDays: reservedDays,
  homeOfficeDays: homeOfficeDate,
});

const resetLoading = (): ReservationAction => {
  return {
    type: reservationsTypesEnum.RESET_LOADING,
  };
};

const resetErrorReservations = (): ReservationAction => ({
  type: reservationsTypesEnum.RESET_ERROR,
});

const setLastUpdate = (lastUpdate: string): ReservationAction => ({
  type: reservationsTypesEnum.SET_LAST_UPDATE,
  lastUpdate: lastUpdate,
});
const setConfigurationDeskReservationForToday = (
  configurationDeskReservationForToday: ConfigurationDeskReservationForToday,
): ReservationAction => ({
  type: reservationsTypesEnum.SET_CONFIGURATION_DESK_RESERVATIONFORTODAY,
  configurationDeskReservationForToday: configurationDeskReservationForToday,
});
const setCacheReservations = (dispatch: any) => {
  const cachedReservations = JSON.parse(
    localStorage.getItem('reservations') || '[]',
  );
  dispatch(setReservations(cachedReservations));
  const dateCacheReservation =
    localStorage.getItem('dateCacheReservation') ?? '';
  dispatch(setLastUpdate(dateCacheReservation));
};

const getReservationsAndPermanentSpaces = (displayLoading: boolean = false) => {
  return async (dispatch: any): Promise<any> => {
    let hasError = false;
    let errorMessage = '';

    displayLoading && dispatch(reservationRequest());

    try {
      const permanentDesks: ReservationList[] =
        await reservationServices.getPermanentDesks();

      permanentDesks.forEach(e => {
        e.id = e.spaceId;
        e.useType = 'PermanentDesk';
      });

      dispatch(setPermanentDesks(permanentDesks));
      localStorage.setItem('permanentDesks', JSON.stringify(permanentDesks));
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = error.message || 'error';
      setCachePermanentDesks(dispatch);
    }

    try {
      const permanentParkings: ReservationList[] =
        await reservationServices.getPermanentParkings();

      permanentParkings.forEach(e => {
        e.id = e.spaceId;
        e.useType = 'PermanentParking';
        e.releasedDates = e.releasedDates.filter(
          e => !dayjs(e).isBefore(dayjs().startOf('day')),
        );
      });

      dispatch(setPermanentParkings(permanentParkings));
      localStorage.setItem(
        'permanentParkings',
        JSON.stringify(permanentParkings),
      );
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = error.message || 'error';
      setCachePermanentParkings(dispatch);
    }

    try {
      const reservations: ReservationList[] =
        await reservationServices.getReservations();
      dispatch(setReservations(reservations));
      localStorage.setItem('reservations', JSON.stringify(reservations));
    } catch (error) {
      if (error.status != 401) hasError = true;
      errorMessage = error.message || 'error';
      setCacheReservations(dispatch);
    }

    if (hasError) {
      dispatch(fetchReservationFailure(errorMessage, true));
    } else {
      const dateCacheReservation = dayjs().toISOString();
      localStorage.setItem('dateCacheReservation', dateCacheReservation);
      dispatch(setLastUpdate(dateCacheReservation));
    }
  };
};

const cancelParkingReservation = (
  reservation: Parking,
  idsReservation: number[],
  haveMoreReservationsToday: boolean,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const idsDeleted: number[] =
        await reservationServices.cancelParkingReservation({
          spaceId: reservation?.spaceId,
          reservationsId: idsReservation,
          haveMoreReservationsToday: haveMoreReservationsToday,
        });
      const parking = {
        ...reservation,
        reservations: reservation.reservations.filter(date => {
          return !idsDeleted.includes(date.reservationId);
        }),
      };

      isEmpty(parking.reservations) &&
        history.replace('/dashboard/reservations');
      dispatch(cancelReservedParking(parking));
      dispatch(
        reservationActions.msgReservation({
          type: 'success',
          description: i18n.t('cancel_parking_reservation', {
            code: reservation.name,
            date: reservation.reservations
              .filter(e => idsDeleted.includes(e.reservationId))
              .map(e =>
                dayjs(e.from).format(`DD [${i18n.t('lbl_of_date')}] MMMM`),
              ),
          }),
        }),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const cancelWorkstationReservation = (
  workstation: Workstation,
  idsReservation: number[],
  deletingToday: boolean = false,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const idsDeleted: string[] =
        await reservationServices.cancelWorkstationReservation(
          idsReservation,
          deletingToday,
        );
      const wr = {
        ...workstation,
        reservations: workstation.reservations.filter(item => {
          return !idsDeleted.includes(item.reservationId.toString());
        }),
      };

      isEmpty(wr.reservations) && history.replace('/dashboard/reservations');
      dispatch(cancelReservedWorkstation(wr));
      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('msg_cancel_success'),
        }),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};
const cancelWorkstationRequest = (
  workstationRaffles: WorkstationRequest[],
  raffleIds: number[],
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const deletedIds: number[] =
        await reservationServices.cancelWorkstationRequest(raffleIds);
      const raffles = workstationRaffles.filter(
        item => !deletedIds.includes(item.requestId),
      );

      isEmpty(raffles) && history.replace('/dashboard/reservations');
      dispatch(setReservedRafflesWr(raffles));
      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('msg_cancel_success'),
        }),
      );
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const modifyParkingReservation = (
  reservationsToModify: ParkingReleaseItem[],
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      await reservationServices.modifyParkingReservation(reservationsToModify);
      history.replace('/dashboard/reservations');

      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('msg_reservation successfully modified'),
        }),
      );
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const cancelParkingRequest = (
  parkingRequest: ParkingRequest[],
  idsToDelete: number[],
  datesRequest: any[],
  onSuccess: () => void,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const deletedIds: number[] =
        await reservationServices.cancelParkingRequest(idsToDelete);
      const requests = parkingRequest.filter(
        item => !deletedIds.includes(item.dates[0].requestId),
      );
      isEmpty(requests) && history.replace('/dashboard/reservations');
      dispatch(setReservedRafflesPk(requests));

      const updatedDatesRequest = datesRequest.map(date => ({
        ...date,
        status: deletedIds.includes(date.id), // true = deleted
      }));

      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('msg_parking_cancel', {
            code:
              parkingRequest[0].name != undefined
                ? parkingRequest[0].name
                : i18n.t('lbl_pending'),
            days: getDatesToReleaseText(updatedDatesRequest),
          }),
        }),
      );

      onSuccess();
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};
const cancelWorkroomReservation = (reservation: Workroom) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      await reservationServices.cancelWrReservation(
        reservation.date.reservationId,
      );

      history.replace('/dashboard/reservations');
      dispatch(cancelReservedWorkroom(reservation));
      dispatch(
        msgReservation({
          type: 'success',
          description: 'Reserva cancelada correctamente',
        }),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};
const cancelCanteenReservation = (reservation: Canteen) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      await reservationServices.cancelCanteenReservation(
        reservation.date.reservationId,
      );

      dispatch(cancelReservedCanteen(reservation));
      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('reserv_delete_success'),
        }),
      );
      history.replace('/dashboard/reservations');
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationWorkroom = reservationId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const wrReservation: Reservation =
        await reservationServices.getWrReservation(reservationId);
      dispatch(setReservedWorkroom(wrReservation));
      history.push(`/spaces/workroom/reservationdetail`);
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};
const getReservationCanteen = (
  reservationId: string,
  visitDetail: boolean = true,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const canteenReservation: Reservation =
        await reservationServices.getCanteenReservation(reservationId);
      dispatch(setReservedCanteen(canteenReservation));
      visitDetail && history.push(`/spaces/canteen/reservationdetail`);
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};
const getReservationLocker = (spaceId: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const lockerReservation: LockerDetail =
        await reservationServices.getLockerReservation(spaceId);
      dispatch(setReservedLocker(lockerReservation));
      history.push(`/spaces/locker/reservationdetail`);
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationWorkstation = (
  spaceId: any,
  type?: string,
  startHour?: string,
  endHour?: string,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const workstationReservation: Reservation =
        await reservationServices.getWsReservation(spaceId);

      if (isEmpty(workstationReservation)) {
        throw new Error(i18n.t('error_default'));
      }

      dispatch(setReservedWorkstation(workstationReservation));
      history.push(`/spaces/workstation/reservationdetail`, {
        type: type,
        startHour: startHour,
        endHour: endHour,
      });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationNoOwnerWorkstation = (
  reservationId,
  employeeIdSearched,
  isPermanent,
  type,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const workstationReservation: Reservation =
        await reservationServices.getWsReservationSearch(
          reservationId,
          employeeIdSearched,
          isPermanent,
        );
      dispatch(setReservedWorkstation(workstationReservation));
      history.push(`/profile/search/reservationdetail`, { type: type });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationDeleteWorkstation = reservationId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const workstationReservation: Reservation =
        await reservationServices.getWsReservation(reservationId);
      dispatch(setReservedWorkstation(workstationReservation));
      history.push('/spaces/workstation/reservationdetail', {
        deleteToday: true,
      });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationDeleteParking = parkingId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const pkReservation: Reservation =
        await reservationServices.getPkReservation(parkingId);
      dispatch(setReservedParking(pkReservation));
      history.replace('/spaces/parking/detail', { deleteToday: true });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationWorkstationFromLastMinute = reservationId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const workstationReservation: Reservation =
        await reservationServices.getWsReservation(reservationId);
      dispatch(setReservedWorkstation(workstationReservation));
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getWaitingListWr = reservationId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const waitingListWr: WaitingList =
        await reservationServices.getWaitingListWr(reservationId);
      dispatch(workroomActions.setWaitingListWr(waitingListWr));
      history.push(`/spaces/workroom/waitingdetail`);
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationParking = (parkingId, type, startHour, endHour) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());

    try {
      const pkReservation: Reservation =
        await reservationServices.getPkReservation(parkingId);
      dispatch(setReservedParking(pkReservation));
      history.push('/spaces/parking/detail', { type, startHour, endHour });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservationParkingDetail = parkingId => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());

    try {
      const pkReservation: Reservation =
        await reservationServices.getPkReservation(parkingId);
      dispatch(setReservedParking(pkReservation));
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getRequestParking = (idSede: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const pkRequest: ParkingRequest[] =
        await reservationServices.getParkingRequest(+idSede);
      dispatch(setReservedRafflesPk(pkRequest));
      history.push('/spaces/parking/requestdetail', {
        parmsRouter: { prevRouterConfirm: false },
        idSede: idSede,
      });
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservedDaysWsAndHomeOffice = (startDate: string, endDate: string) => {
  return async (dispatch: any): Promise<any> => {
    // dispatch(reservationRequest());
    try {
      const [reservedDatesWs, reservedDatesHomeOffice] = await Promise.all([
        reservationServices.getReservedDaysWs(startDate, endDate),
        reservationServices.getReservedDaysHomeOffice(startDate, endDate),
      ]);
      dispatch(
        setReservedDaysWsAndHomeOffice(
          reservedDatesWs,
          reservedDatesHomeOffice,
        ),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservedDaysPkAndHomeOffice = (startDate: string, endDate: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const [reservedDatesPk, reservedDatesHomeOffice] = await Promise.all([
        reservationServices.getReservedDaysPk(startDate, endDate),
        reservationServices.getReservedDaysHomeOffice(startDate, endDate),
      ]);
      dispatch(
        setReservedDaysPkAndHomeOffice(
          reservedDatesPk,
          reservedDatesHomeOffice,
        ),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservedDaysWs = (startDate: string, endDate: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const reservedDatesWs: reservedDay[] =
        await reservationServices.getReservedDaysWs(startDate, endDate);
      dispatch(setReservedDaysWs(reservedDatesWs));
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservedDaysHomeOffice = (startDate: string, endDate: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const reservedDatesHomeOffice: string[] =
        await reservationServices.getReservedDaysHomeOffice(startDate, endDate);
      dispatch(setReservedHomeOffice(reservedDatesHomeOffice));
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getReservedDaysPk = (startDate: string, endDate: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const reservedDatesPk: reservedDay[] =
        await reservationServices.getReservedDaysPk(startDate, endDate);
      dispatch(setReservedDaysPk(reservedDatesPk));
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const addReservationHost = (host: Host, reservationId: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      await reservationServices.addReservationHost(host, reservationId);
      const wrReservation: Reservation =
        await reservationServices.getWrReservation(reservationId);
      dispatch(setReservedWorkroom(wrReservation));
      dispatch(
        msgReservation({
          description: i18n.t('msg_host_added_success', {
            name: host.fullName,
          }),
          type: 'success',
        }),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const deleteReservationHost = (host: Host, reservationId: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(reservationRequest());
    try {
      const newReservationId = await reservationServices.deleteReservationHost(
        reservationId,
      );
      const user = await tokenServices.getUser();
      if (host.employeeId === parseInt(user.owner)) {
        history.push('/dashboard/reservations');
      } else {
        const wrReservation: Reservation =
          await reservationServices.getWrReservation(
            newReservationId?.reservationId,
          );
        dispatch(setReservedWorkroom(wrReservation));
      }

      dispatch(
        msgReservation({
          description: i18n.t('msg_host_deleted_success', {
            name: host.fullName,
          }),
          type: 'success',
        }),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const setCachePermanentParkings = (dispatch: any) => {
  const cachedPermanentParkings = JSON.parse(
    localStorage.getItem('permanentParkings') || '[]',
  );
  dispatch(setPermanentParkings(cachedPermanentParkings));
};

const getPermanentParkings = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      const permanentParkings: ReservationList[] =
        await reservationServices.getPermanentParkings();

      permanentParkings.forEach(e => {
        e.id = e.spaceId;
        e.useType = 'PermanentParking';
        e.releasedDates = e.releasedDates.filter(
          e => !dayjs(e).isBefore(dayjs().startOf('day')),
        );
      });

      dispatch(setPermanentParkings(permanentParkings));
      localStorage.setItem(
        'permanentParkings',
        JSON.stringify(permanentParkings),
      );
    } catch (e) {
      setCachePermanentParkings(dispatch);
      dispatch(fetchReservationFailure(e.message, true));
    }
  };
};

const setPermanentParkingReleasedDays = (
  spaceId: number,
  name: string,
  releasedDays: string[],
  reservedDays: string[],
  type: number,
  idSede: number,
) => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(reservationRequest());

      const daysToSave = _.union(
        releasedDays,
        state().reservation.reservedParking?.releasedDates?.filter(
          date => !reservedDays.includes(date),
        ),
      );

      await reservationServices.setPermanentParkingReleasedDays(
        spaceId,
        daysToSave,
        type,
        idSede,
      );

      const liberateText = i18n.t('cancel_parking_reservation', {
        code: name,
        date: groupDatesByMonth(
          releasedDays,
          state().app.localSettings.localLanguage,
          true,
        ),
      });

      const reservedText = i18n.t('lbl_place_unliberate', {
        parking: name,
        days: groupDatesByMonth(
          reservedDays,
          state().app.localSettings.localLanguage,
          true,
        ),
      });

      dispatch(
        msgReservation({
          type: 'success',
          description: `${!_.isEmpty(releasedDays) ? `${liberateText} ` : ''}${
            !_.isEmpty(reservedDays) ? reservedText : ''
          }`,
        }),
      );

      dispatch(getPermanentParkings());
      dispatch(resetLoading());
      dispatch(
        getPermanentParkingDetail({
          ...state().reservation.reservedParking,
          releasedDates: daysToSave,
        }),
      );
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const modifyPermanentDeskReleasedDays = (
  releasedDeskDays: ReleasedDeskDays,
) => {
  const { dates, spaceId } = releasedDeskDays;
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(reservationRequest());

      await reservationServices.modifyPermanentDeskReleasedDays(spaceId, dates);

      dispatch(
        msgReservation({
          type: 'success',
          description: i18n.t('release_success'),
        }),
      );
      const permanentDesks: ReservationList[] =
        await reservationServices.getPermanentDesks();

      permanentDesks.forEach(e => {
        e.id = e.spaceId;
        e.useType = 'PermanentDesk';
      });

      dispatch(setPermanentDesks(permanentDesks));
      // update the released dates in the detail without making a new request
      const updatedReservedWorkstation = {
        ...state().reservation.reservedWorkstation,
        releasedDatesTypes: [
          ...permanentDesks.find(e => e.id === spaceId).releasedDatesTypes,
        ],
      };
      dispatch(getPermanentDeskDetail(updatedReservedWorkstation));
      history.replace('/spaces/workstation/reservationdetail');

      dispatch(resetLoading());
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const setPermanentDeskReleasedDays = (
  releasedDeskDays: ReleasedDeskDays,
  name: string,
) => {
  const { dates, spaceId, type } = releasedDeskDays;
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(reservationRequest());

      await reservationServices.setPermanentDeskReleasedDays(
        spaceId,
        dates,
        type,
      );
      const typeCode =
        POLICIES_SCHEDULES.find(e => e.id === dates[0].type).value || '';

      const liberateText = i18n.t(`cancel_desk_reservation_${typeCode}`, {
        code: name,
        date: groupDatesByMonth(
          dates.map(singleDate => singleDate.date),
          state().app.localSettings.localLanguage,
          true,
        ),
        startHour: dates[0].startHour,
        endHour: dates[0].endHour,
      });

      dispatch(
        msgReservation({
          type: 'success',
          description: `${!_.isEmpty(dates) ? liberateText : ''}`,
        }),
      );

      dispatch(getPermanentDesks());
      dispatch(resetLoading());
      dispatch(
        getPermanentDeskDetail({
          ...state().reservation.reservedWorkstation,
          releasedDatesTypes: [
            ...state().reservation.reservedWorkstation.releasedDatesTypes,
            ...dates,
          ],
        }),
      );
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const setCachePermanentDesks = (dispatch: any) => {
  const cachedPermanentDesks = JSON.parse(
    localStorage.getItem('permanentDesks') || '[]',
  );
  dispatch(setPermanentDesks(cachedPermanentDesks));
};

const getPermanentDesks = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      const permanentDesks: ReservationList[] =
        await reservationServices.getPermanentDesks();

      permanentDesks.forEach(e => {
        e.id = e.spaceId;
        e.useType = 'PermanentDesk';
      });

      dispatch(setPermanentDesks(permanentDesks));
      localStorage.setItem('permanentDesks', JSON.stringify(permanentDesks));
    } catch (e) {
      setCachePermanentDesks(dispatch);
      dispatch(fetchReservationFailure(e.message, true));
    }
  };
};

const getPermanentParkingDetail = (data: Reservation | Parking) => {
  return async (dispatch: any): Promise<any> => {
    try {
      await dispatch(setReservedParking(data));
      history.push('/spaces/parking/detail');
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getPermanentDeskDetail = (data: Reservation) => {
  return async (dispatch: any): Promise<any> => {
    try {
      await dispatch(setReservedWorkstation(data));
      history.push(`/spaces/workstation/reservationdetail`);
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

const getConfigurationDeskReservationForToday = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(reservationRequest());
      const configurationDeskReservationForToday: ConfigurationDeskReservationForToday =
        await reservationServices.getConfigurationDeskReservationForToday();
      dispatch(
        setConfigurationDeskReservationForToday(
          configurationDeskReservationForToday,
        ),
      );
    } catch (e) {
      dispatch(fetchReservationFailure(e.message, true));
    }
  };
};

const getIfIsUserInCampus = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(reservationRequest());
      const isUserInCampus: boolean =
        await reservationServices.getIfUserIsInCampus();
      dispatch(setIsUserInCampus(isUserInCampus));
    } catch (e) {
      dispatch(
        msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      dispatch(fetchReservationFailure(e.message));
    }
  };
};

export const reservationActions = {
  setReservedParking,
  setReservedWorkroom,
  setReservedWorkstation,
  setReservedRafflesWr,
  setReservedRafflesPk,
  cancelWorkstationReservation,
  cancelWorkroomReservation,
  cancelParkingReservation,
  cancelWorkstationRequest,
  cancelParkingRequest,
  modifyParkingReservation,
  getReservationWorkroom,
  getReservationWorkstation,
  getWaitingListWr,
  getReservationParking,
  getRequestParking,
  msgReservation,
  addReservation,
  resetMsgReservation,
  deleteRaffleCompanion,
  addRaffleCompanion,
  getReservationParkingDetail,
  getReservationWorkstationFromLastMinute,
  getReservedDaysWs,
  getReservedDaysHomeOffice,
  getReservedDaysPk,
  addReservationHost,
  deleteReservationHost,
  getReservationDeleteWorkstation,
  getReservationDeleteParking,
  getPermanentParkings,
  getPermanentDesks,
  getPermanentParkingDetail,
  getPermanentDeskDetail,
  reservationRequest,
  setPermanentParkingReleasedDays,
  getReservationNoOwnerWorkstation,
  resetLoading,
  getReservedDaysWsAndHomeOffice,
  getReservedDaysPkAndHomeOffice,
  resetErrorReservations,
  setPermanentDeskReleasedDays,
  getReservationsAndPermanentSpaces,
  modifyPermanentDeskReleasedDays,
  getReservationLocker,
  getReservationCanteen,
  setReservedCanteen,
  cancelCanteenReservation,
  getConfigurationDeskReservationForToday,
  getIfIsUserInCampus,
  setIsUserInCampus,
};
