import { IonContent, IonHeader, IonPage } from '@ionic/react';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import DetailSection from '../components/DetailSection/DetailSection';
import { Button, Icon, TopBar } from '@acciona/ui-ionic-kit';
import DetailHeader from '../components/DetailHeader/DetailHeader';
import { formatDate } from '../../../../utils/dateTime';
import { useTranslation } from 'react-i18next';
import { NotificationsActions } from '../../../../_redux/actions';
import { useBlobOpener } from '../../../../hooks/useBlobOpener';
import { restaurantTableServices } from '../../../../_redux/services';
import { getStatusMessage } from '../helpers/getMovementStatus';

const MovementDetail: React.FC<any> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openBlob } = useBlobOpener();

  const downloadFile = async () => {
    try {
      const receiptResponse = await restaurantTableServices.getReceipt(
        props?.transactionDetail?.externalId,
      );
      if (receiptResponse) {
        openBlob(receiptResponse, 'recibo.pdf');
      } else {
        dispatch(
          NotificationsActions.setGenericErrorToast(
            t('msg_error_open_receipt'),
          ),
        );
      }
    } catch (error) {
      dispatch(
        NotificationsActions.setGenericErrorToast(t('msg_error_open_receipt')),
      );
    }
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <div className={styles.headerIcon}>
              <Button
                onClick={() => props.navigateBack()}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            </div>
          }
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailHeader
          description={t('wallet_detail_commerceName')}
          nameDetail={props.transactionDetail?.commerceName ?? '-'}
          points={props.transactionDetail?.points ?? '-'}
        />
        <DetailSection
          title={t('wallet_detail_positionDetail')}
          summary={props.transactionDetail?.positionDetail ?? '-'}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_additionalPayment')}
          subtitle={`${
            props.transactionDetail?.additionalPayment?.quantity ?? '-'
          } ${props.transactionDetail?.additionalPayment?.currency ?? '-'} `}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_dateAndTime')}
          subtitle={`${
            formatDate(props.transactionDetail?.dateAndTime) ?? '-'
          }`}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_site')}
          subtitle={`${props.transactionDetail?.site ?? '-'}`}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_location')}
          subtitle={`${props.transactionDetail?.location ?? '-'}`}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_status')}
          subtitle={`${
            getStatusMessage(props.transactionDetail?.status) ?? '-'
          }`}
        ></DetailSection>
        <DetailSection
          title={t('wallet_detail_orderId')}
          subtitle={`${props.transactionDetail?.orderId ?? '-'}`}
        ></DetailSection>
        <div className={styles.buttonContainer}>
          <button
            className={styles.downloadButton}
            onClick={() => downloadFile()}
          >
            <Icon
              slot="icon-only"
              className={`${styles.downloadIcon} icon icon-24 icon-share-2`}
            ></Icon>
            <p>{t('msg_download_receipt')}</p>
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MovementDetail;
