import { IonText, IonGrid, IonCol, IonRow, IonSpinner } from '@ionic/react';
import styles from './styles.module.scss';
import { Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';

const LastMovements: React.FC<any> = props => {
  const { t } = useTranslation();
  return (
    <IonGrid fixed className={styles.lastMovementsContainer}>
      <IonRow>
        <IonCol size="10" className="ion-no-padding">
          <IonText className={styles.headline}>
            {t('wallet_lastMovements_title')}
          </IonText>
        </IonCol>
        <IonCol className="ion-text-end ion-no-padding" size="2">
          <div className={styles.iconContainer}>
            {!props.isLoading ? (
              <Icon
                onClick={props.handleUpdateTransactions}
                className={`icon icon-refresh ${styles.iconFont}`}
              />
            ) : (
              <IonSpinner name="crescent" className={styles.spinner} />
            )}
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-no-padding">
          <IonText className={styles.lastMovementsTitle}>
            {t('wallet_lastMovements_subtitle')}
          </IonText>
          <IonText className={styles.lastMovementsTitle}>
            {props.dateLastUpdated}
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default LastMovements;
