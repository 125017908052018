import { IonLoading, IonPage } from '@ionic/react';
import styles from './styles.module.scss';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { restaurantTableServices } from '../../../../_redux/services/restaurantTable/restaurantTable.services';
import { useTranslation } from 'react-i18next';

interface Props {
  orderId: string;
}

const OrderLoader: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { orderId } = useParams<Props>();

  useEffect(() => {
    payWallet();
  }, [orderId]);

  const payWallet = async () => {
    setIsLoading(true);
    try {
      const payWalletResponse = await restaurantTableServices.payWallet(
        orderId,
      );
      if (!payWalletResponse.success) {
        handleBackWithError();
      } else {
        confirmOrder();
      }
    } catch (error) {
      handleBackWithError();
    }
  };

  const confirmOrder = async () => {
    try {
      const confirmOrderResponse = await restaurantTableServices.confirmOrder(
        orderId,
      );
      if (!confirmOrderResponse.success) {
        handleBackWithError();
      } else {
        getOrder();
      }
    } catch (error) {
      handleBackWithError();
    }
  };

  const getOrder = async () => {
    try {
      const getOrderResponse = await restaurantTableServices.getOrder(orderId);
      if (!getOrderResponse.success) {
        handleBackWithError();
      }
      history.replace('/restaurant/confirmation', {
        title: t('msg_payment_confirmation'),
        router: `/spaces/restaurant/orderdetail/${orderId}`,
        parmsRouter: { prevRouterConfirm: true },
      });
    } catch (error) {
      handleBackWithError();
    }
  };

  const handleBackWithError = () => {
    setIsLoading(false);
    history.push('/restaurant/error');
  };

  return (
    <IonPage>
      <div className={styles.container}>
        {isLoading && (
          <IonLoading
            isOpen={isLoading}
            message={t('msg_pending_payment')}
            duration={0}
          />
        )}
      </div>
    </IonPage>
  );
};

export default OrderLoader;
