import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface InAppBrowserOnlyRouteProps extends RouteProps {
  children?: any;
}
const InAppBrowserOnlyRoute: React.FC<InAppBrowserOnlyRouteProps> = ({
  children,
  ...rest
}) => {
  const isInAppBrowser =
    window.navigator.userAgent.search('inAppBrowser') !== -1;

  return (
    <Route {...rest}>
      {isInAppBrowser ? children : <Redirect to="/dashboard/home" />}
    </Route>
  );
};
export default InAppBrowserOnlyRoute;
