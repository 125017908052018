import { useEffect, useState } from 'react';
import { IonSkeletonText, isPlatform } from '@ionic/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { newsServices } from '../../../_redux/services';
import { NewsStore } from '../../../_redux/models/news.model';
import { isEmpty } from '../../../utils/functions';
import SafeImage from '../../SafeImage/SafeImage';

export type Props = {
  image_id: string;
  classSkeleton?: string;
  className?: string;
};

export const NewsImg: React.FC<Props> = props => {
  const { image_id, classSkeleton, className } = props;
  const [image, setImage] = useState('');
  const sessionId = useSelector((store: NewsStore) => store.news.sessionId);

  const first = window.matchMedia(
    '(min-width: 0px) and (max-width:300px)',
  ).matches;
  const second = window.matchMedia(
    '(min-width: 301px) and (max-width:480px)',
  ).matches;
  const third = window.matchMedia(
    '(min-width: 481px) and (max-width:750px)',
  ).matches;
  const fourth = window.matchMedia(
    '(min-width: 751px) and (max-width:1024px)',
  ).matches;
  const fifth = window.matchMedia(
    '(min-width: 1025px) and (max-width:1600px)',
  ).matches;
  const sixth = window.matchMedia(
    '(min-width: 1601px) and (max-width:2560px)',
  ).matches;
  const seventh = window.matchMedia('(min-width:2561px) ').matches;
  const resolution = [
    { id: 0, value: first },
    { id: 1, value: second },
    { id: 2, value: third },
    { id: 3, value: fourth },
    { id: 4, value: fifth },
    { id: 5, value: sixth },
    { id: 6, value: seventh },
  ];

  const getImage = async id => {
    const noImagePath = './assets/images/noImage.png';
    try {
      let resolutionValue = 6;
      if (isPlatform('capacitor')) {
        resolutionValue = 3;
      } else if (!_.isEmpty(_.first(resolution.filter(v => v.value)))) {
        resolutionValue = _.first(resolution.filter(v => v.value)).id;
      }
      const img = await newsServices.getNewsImgById(
        id,
        sessionId,
        resolutionValue,
      );
      return img.size > 0 ? URL.createObjectURL(img) : noImagePath;
    } catch {
      return noImagePath;
    }
  };

  useEffect(() => {
    if (image_id && !isEmpty(image_id)) {
      getImage(image_id).then(setImage);
    }
    return () => {
      setImage('');
    };
  }, [image_id]);

  return (
    <>
      {image ? (
        <SafeImage src={image} className={className} />
      ) : (
        <div className={classSkeleton}>
          <IonSkeletonText
            className={classSkeleton}
            animated={!_.isEmpty(image_id)}
          />
        </div>
      )}
    </>
  );
};
