export enum workroomTypesEnum {
  REQUEST = 'FETCH_WORKROOM_REQUEST',
  FAILURE_WORKROOM = 'FETCH_WORKROOM_FAILURE',
  RESET_WORKROOMS = 'RESET_WORKROOMS_LIST',
  RESET_ERROR = 'RESET_ERROR',

  SET_WORKROOMS = 'SET_WORKROOMS',
  SET_WORKROOM = 'SET_WORKROOM',
  RESERVE_WORKROOM = 'RESERVE_WORKROOM',
  GET_WORKROOMS_AVAILABLE = 'GET_WORKROOMS_AVAILABLE',
  SET_WORKROOMTYPES = 'SET_WORKROOMTYPES',
  SET_WORKROOMEQUIPMENT = 'SET_WORKROOMEQUIPMENT',
  SET_WORKROOM_ENABLED_FILTERS = 'SET_WORKROOM_ENABLED_FILTERS',
  SET_BUILDINGS = 'SET_BUILDINGS',
  SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS',
  SET_WAITING_LIST = 'SET_WAITING_LIST',
  SET_HOSTS = 'SET_HOSTS',
  SET_PRESELECTED_HOST = 'SET_PRESELECTED_HOST',
  DELETE_PRESELECTED_HOST = 'DELETE_PRESELECTED_HOST',
  RESET_LOADING = 'RESET_LOADING',
  RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS',
  SET_WORKROOM_DETAIL_RESERVATION = 'SET_WORKROOM_DETAIL_RESERVATION',
  SET_WORKROOM_DETAIL = 'SET_WORKROOM_DETAIL',
  SET_RESERVNOW = 'SET_RESERVNOW',
}
