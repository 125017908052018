import React, { useEffect, useState } from 'react';
import { Button, Toast } from '@acciona/ui-ionic-kit';
import { IonContent, IonLoading, IonPage, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  appActions,
  authActions,
  userActions,
  workstationActions,
} from '../../../_redux/actions';
import { tokenServices } from '../../../_redux/services/token/token.services';
import { AuthStore } from '../../../_redux/models/auth.model';
import { AppStore } from '../../../_redux/models/app.model';
import { UserStore } from '../../../_redux/models/user.model';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useNotificationService } from '../../../hooks/useNotficationService';
import { handleBackMinimizeButton } from '../../../utils/functions';
import { useHistory } from 'react-router';
import { PreLoadIconsFont } from '../../../components/Base/PreLoadIconsFont/PreLoadIconsFont';
import styles from './styles.module.scss';

const Login: React.FC<any> = () => {
  const { notificationService } = useNotificationService();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    error: errorAuth,
    isLoading: isLoadingAuth,
    isAuth,
  } = useSelector((state: AuthStore) => state.auth);
  const { offlineServices, offlineNetwork } = useSelector(
    (state: AppStore) => state.app,
  );

  const { userIsRegisteredInDatabase } = useSelector(
    (state: UserStore) => state.user.user,
  );

  const [isCheckingAccess, setIsCheckingAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const imageURL = './assets/images/bgXXL.jpg';
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('ionBackButton', handleBackMinimizeButton);
    return () => {
      document.removeEventListener('ionBackButton', handleBackMinimizeButton);
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(userActions.getUserHeadOffice());
    }
  }, [isAuth]);

  useEffect(() => {
    if (userIsRegisteredInDatabase) {
      dispatch(userActions.getUserConfig(true));
      dispatch(workstationActions.getWsConfigurations());
    }
  }, [userIsRegisteredInDatabase]);

  useEffect(() => {
    if ((offlineServices || offlineNetwork) && isAuth) {
      history.push('/dashboard/home');
    }
  }, [offlineServices, offlineNetwork, isAuth]);

  useEffect(() => {
    if (!offlineNetwork) {
      dispatch(authActions.getToken());
    }
  }, [offlineNetwork]);

  useEffect(() => {
    if (userIsRegisteredInDatabase === false) {
      setIsLoading(false);
    } else {
      setIsLoading(isLoadingAuth || isCheckingAccess);
    }
  }, [isLoadingAuth, isCheckingAccess, userIsRegisteredInDatabase]);

  const openLogin = async () => {
    await tokenServices.removeAccount();
    dispatch(userActions.resetUser());
    if (!isPlatform('capacitor')) {
      window.location.href = process.env.REACT_APP_COGNITO_WEB_LINK;
      return;
    }

    const loginPage = InAppBrowser.create(
      process.env.REACT_APP_COGNITO_APP_LINK,
      '_blank',
      'location=no,closebuttoncaption=OK,hidden=yes',
    );

    loginPage.on('loaderror').subscribe(async () => {
      loginPage.close();
      dispatch(appActions.appStateReset());
      dispatch(authActions.logout());
      dispatch(userActions.resetUser());
    });

    loginPage.on('loadstart').subscribe(async event => {
      setIsCheckingAccess(true);
      const url = event.url;
      let code = '';
      if (
        url.includes(`${process.env.REACT_APP_COGNITO_APP_REDIRECT_URI}?code=`)
      ) {
        code = url.split(
          `${process.env.REACT_APP_COGNITO_APP_REDIRECT_URI}?code=`,
        )[1];
        if (code != '') {
          try {
            dispatch(authActions.getToken(code, true, true));
            const data = await tokenServices.getUser();
            await notificationService.subscribeTo(data.owner);
          } catch (error) {
            console.log(error);
          }
        }
        loginPage.close();
      }
    });

    loginPage.on('loadstop').subscribe(async () => {
      loginPage.show();
      setIsCheckingAccess(false);
    });
  };

  return (
    <IonPage id="login-page">
      <IonContent>
        <PreLoadIconsFont />
        <div className={styles.imgContent}>
          <div className={styles.imgOverlay} />
          <img src={imageURL} alt="acciona" />
        </div>
        <div className={styles.title_group}>
          <img
            src="../assets/images/campus_acciona.svg"
            alt="logo Campus ACCIONA"
            className={styles.login_logo}
          />
          <div>
            <p className={styles.login_title}>
              {t('hello_welcome_to_acciona')}
            </p>
          </div>
        </div>
        {!isLoadingAuth && (
          <div className={styles.buttons_group}>
            <Button disabled className={styles.btn_sty} onClick={openLogin}>
              {t('btn_guest_access')}
            </Button>
            <Button
              className={styles.btn_sty}
              disabled={offlineNetwork || isAuth}
              color="secondary"
              onClick={openLogin}
            >
              {t('btn_employee_access')}
            </Button>
          </div>
        )}
        <Toast
          isOpen={!!errorAuth && userIsRegisteredInDatabase}
          message={t('msg_system_unavailable')}
          position="bottom"
          type="error"
        />
        <IonLoading isOpen={isLoading} message={t('msg_loading')} />
      </IonContent>
    </IonPage>
  );
};

export default Login;
