import i18n from '../../../../i18nextConf';

export const addMinutesAndRound = (
  time: string,
  minutes: number = 0,
  round: number = 15,
) => {
  let [h, m] = time.split(':').map(v => +v);

  m += minutes;
  m = Math.ceil(m / round) * round;

  while (m >= 60) {
    h += 1;
    m -= 60;
  }
  if (h >= 24) {
    return '23:45';
  }
  return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
};

export const getSelectedItemsDisplayText = (
  itemIds: any[],
  itemsData: any[],
  placeholder: string,
) => {
  if (itemIds.length === 0) {
    return placeholder;
  }
  if (itemIds.length === 1) {
    return itemsData.find(b => b.id === itemIds[0]).name;
  } else {
    return `${itemIds.length} ${i18n.t('selected')}`;
  }
};
