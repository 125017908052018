import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import {
  NotificationsActions,
  workroomActions,
} from '../../../../_redux/actions';
import { UserStore } from '../../../../_redux/models/user.model';
import { restaurantTableServices } from '../../../../_redux/services';
import { AppStore } from '../../../../_redux/models/app.model';
import { useCheckUserPermissions } from '../../../../hooks/useCheckUserPermissions';
import { didCameraPermission } from '../../../../utils/functions';
import ScanQRCode from '../../../../components/ScanQRCode/ScanQRCode';

const SpacesScanQRCode: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoading, error } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );
  const { employeeId } = useSelector((store: UserStore) => store.user.user);
  const { localLanguage } = useSelector(
    (store: AppStore) => store.app.localSettings,
  );
  const { hasPermission } = useCheckUserPermissions();

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(5, () => {
        handleClose();
      });
    };
    checkPermission();
    document.addEventListener('ionBackButton', backFunction);
    return () => {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(workroomActions.resetErrorWorkroom());
      handleBackWithError();
    }
  }, [error]);

  const checkPermission = async () => {
    const permission = await didCameraPermission();
    if (!permission) {
      history.replace('/dashboard/actions');
      return;
    }
  };

  const handleData = data => {
    const regexRoom = /(\bcode\b)/;
    const regexTable = /(\bacciona\.waitry\.net\b)/;

    const handleError = () => handleBackWithError(`${t('qr_error')}`);
    const handlePermissionError = () =>
      handleBackWithError(`${t('qr_permission_error')}`);

    if (!data) {
      return handleError();
    }

    if (regexTable.test(data)) {
      const urlPattern =
        /^https:\/\/acciona\.waitry\.net\/([A-Za-z0-9]+)\?externalId=([A-Za-z0-9-]+)$/;
      const urlMatch = data.match(urlPattern);

      if (urlMatch && urlMatch.length >= 3) {
        const pin = urlMatch[1];
        const externalId = urlMatch[2];

        if (hasPermission('order_restaurant_menu')) {
          handleDataRestaurantTable(pin, externalId);
          return;
        } else {
          return handlePermissionError();
        }
      } else {
        return handleError();
      }
    }

    if (regexRoom.test(data)) {
      let parsedJson;
      try {
        parsedJson = JSON.parse(data);
      } catch (error) {
        return handleError();
      }

      if (parsedJson?.code) {
        if (hasPermission('room')) {
          handleDataWorkroom(parsedJson.code);
          return;
        } else {
          return handlePermissionError();
        }
      } else {
        return handleError();
      }
    }
    return handleError();
  };

  const handleDataRestaurantTable = async (pin, commerceId) => {
    const newOrderResponse = await restaurantTableServices.newOrder(
      pin,
      employeeId,
      commerceId,
    );
    if (newOrderResponse.success && newOrderResponse.data?.externalId) {
      getMenu(newOrderResponse.data.pin, newOrderResponse.data.externalId);
    } else {
      handleBackWithError(`${t('qr_error')}`);
    }
  };

  const getMenu = async (pin, orderId) => {
    try {
      const menuResponse = await restaurantTableServices.getMenu(
        pin,
        orderId,
        localLanguage,
      );
      if (menuResponse.success && menuResponse.data) {
        const modifiedUrl = menuResponse.data;
        if (modifiedUrl) {
          history.push('/spaces/restaurant', {
            url: modifiedUrl,
            orderId,
          });
        }
      } else {
        handleBackWithError(`${t('qr_error')}`);
      }
    } catch (error) {
      handleBackWithError(`${t('qr_error')}`);
    }
  };

  const handleDataWorkroom = spaceCodeQR => {
    dispatch(workroomActions.getWorkroomDetail(spaceCodeQR));
  };

  const handleBackWithError = (message?: string) => {
    dispatch(
      NotificationsActions.setGenericErrorToast(
        message || t('msg_err_scan_qr_code'),
      ),
    );
    history.replace('/dashboard/actions');
  };

  const handleClose = () => {
    history.replace('/dashboard/actions');
  };

  return (
    <ScanQRCode
      handleBackWithError={handleBackWithError}
      handleData={handleData}
      handleClose={handleClose}
      isLoading={isLoading}
    />
  );
};

export default SpacesScanQRCode;
