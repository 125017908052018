import { useTranslation } from 'react-i18next';

enum OrderStatus {
  PREPARED = 1,
  CANCELLED = 2,
  PENDING_CONFIRMATION = 3,
  CONFIRMED = 4,
  COMPLETED = 5,
  PARTIAL_REFUNDED = 6,
  REFUNDED = 7,
  PAID = 8,
  ERROR = 9,
}

export const getStatusMessage = (status: number): string => {
  const { t } = useTranslation();

  switch (status) {
    case OrderStatus.PREPARED:
      return t('wallet_order_status_prepared');
    case OrderStatus.CANCELLED:
      return t('wallet_order_status_cancelled');
    case OrderStatus.PENDING_CONFIRMATION:
      return t('wallet_order_status_pending_confirmation');
    case OrderStatus.CONFIRMED:
      return t('wallet_order_status_confirmed');
    case OrderStatus.COMPLETED:
      return t('wallet_order_status_completed');
    case OrderStatus.PARTIAL_REFUNDED:
      return t('wallet_order_status_partial_refunded');
    case OrderStatus.REFUNDED:
      return t('wallet_order_status_refunded');
    case OrderStatus.PAID:
      return t('wallet_order_status_paid');
    case OrderStatus.ERROR:
      return t('wallet_order_status_error');
    default:
      return t('wallet_order_status_prepared');
  }
};
