import {
  EmployeeHeadOffice,
  OtherPolicies,
  UserData,
  UserWorkPreferences,
} from '../../models/user.model';
import muleSoftInstance from '../../utils/httpCommon';
import { Locale } from '../../models/app.model';
import { UserParkingPreferences } from '../../models/parking.model';

const getUserHeadOffice = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/employeeHeadOffice`;

  try {
    const res: EmployeeHeadOffice = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    if (error.status === 409) {
      throw new Error('not_available_head_office');
    } else {
      throw error;
    }
  }
};
const getUserData = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/employee`;

  try {
    const res: UserData = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    if (error.status === 409) {
      throw new Error('not_available_head_office');
    } else {
      throw error;
    }
  }
};

const getUserPermissions = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v2/permissions`;
  return (await muleSoftInstance.get(path)).data;
};

const validateUserDistrict = async () => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/employee/validateDistrict`;
    return (await muleSoftInstance.get(path)).data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPolicies = async () => {
  const path = `${process.env.REACT_APP_API_SERVICES}/documents/latest`;
  const resp = await muleSoftInstance.get(path);
  return resp.data;
};

const acceptPolicies = async (idPolicies: number[]) => {
  try {
    const path = `${process.env.REACT_APP_API_SERVICES}/documents/acceptance`;
    const resp = await muleSoftInstance.post(path, idPolicies);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getUserLanguage = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/users/language`;
  const resp = await muleSoftInstance.get(path);
  return resp.data;
};

const setUserLanguage = async (locale: Locale): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/users/language`;
    const resp = await muleSoftInstance.put(path, {
      code: locale,
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const setUserDeskPreferences = async (
  preferences: UserWorkPreferences,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/desk/preferences`;
    const resp = await muleSoftInstance.put(path, preferences);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};
const setUserParkingPreferences = async (
  preferences: UserParkingPreferences,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/parking/preferences`;
    const resp = await muleSoftInstance.put(path, preferences);

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getReservationPolicies = async () => {
  try {
    const path = `${process.env.REACT_APP_API_SERVICES}/link`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getOtherPolicies = async (): Promise<OtherPolicies[]> => {
  try {
    const path = `${process.env.REACT_APP_API_SERVICES}/v1/otherPolicies`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const sendEmailNewCardCode = async () => {
  try {
    const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/newHIDByEmail`;
    const resp = await muleSoftInstance.post(path, {});
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWallet = async (fromDate = null) => {
  try {
    const path = `${process.env.REACT_APP_API_SERVICES}/wallet/transactions`;
    const resp = await muleSoftInstance.get(path, {
      params: { from: fromDate },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWalletV2 = async (clientId: string) => {
  try {
    const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/movement/${clientId}`;
    const resp = await muleSoftInstance.get(path, null, true);
    return resp.data.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPublicHolidays = async () => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/workday/employeesPublicHolidays`;
    const resp = await muleSoftInstance.get(path);
    return resp.data?.results;
  } catch (error) {
    throw new Error(error.message);
  }
};

const setDefaultHeadOffice = async (sedeId: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/defaultHeadOffice`;
    const resp = await muleSoftInstance.put(path, {
      id: sedeId,
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWalletDetailTransaction = async (orderId: string) => {
  try {
    const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order/${orderId}`;
    const resp = await muleSoftInstance.get(path, null, true);
    const detailResult = {
      success: resp?.data?.success,
      commerceName: resp?.data?.data?.commerceName,
      orderId: resp?.data?.data?.externalId,
      points: resp?.data?.data?.totalPoints,
      positionDetail: resp?.data?.data?.associatedData.items,
      additionalPayment: {
        quantity: resp?.data?.data?.totalRest,
        currency: resp?.data?.data?.currencyCode,
      },
      dateAndTime: resp?.data?.data?.statusDate,
      site: resp?.data?.data?.site,
      location: resp?.data?.data?.location,
      status: resp?.data?.data?.status,
    };
    return detailResult;
  } catch (error) {
    throw new Error(error);
  }
};

export const userServices = {
  getUserData,
  getUserPermissions,
  validateUserDistrict,
  getPolicies,
  acceptPolicies,
  getUserLanguage,
  setUserLanguage,
  getReservationPolicies,
  getOtherPolicies,
  sendEmailNewCardCode,
  getWallet,
  getWalletV2,
  getPublicHolidays,
  setDefaultHeadOffice,
  setUserDeskPreferences,
  setUserParkingPreferences,
  getUserHeadOffice,
  getWalletDetailTransaction,
};
