import { WorkroomAction, WorkroomState } from '../models/workroom.model';
import { workroomTypesEnum } from '../types/workroom.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  workroomSelected: null,
  workrooms: [],
  workroomTypes: [],
  workroomEquipmentOptions: [],
  buildings: [],
  searchFilters: null,
  reservNow: false,
  waitingListW: null,
  hosts: [],
  preselectedHost: null,
  workroomDetailReservation: null,
  workroomDetail: null,
  enabledFilters: { type: false, equipment: false },
};

export const workroomReducer = (
  state: WorkroomState = initialState,
  action: WorkroomAction,
): WorkroomState => {
  switch (action.type) {
    case workroomTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case workroomTypesEnum.SET_WORKROOM:
      return {
        ...state,
        isLoading: false,
        error: null,
        workroomSelected: action.workroomSelected,
      };
    case workroomTypesEnum.SET_WORKROOMS:
      return {
        ...state,
        isLoading: false,
        error: null,
        workrooms: action.workrooms,
      };
    case workroomTypesEnum.SET_WORKROOMTYPES:
      return {
        ...state,
        isLoading: false,
        error: null,
        workroomTypes: action.workroomTypes,
      };
    case workroomTypesEnum.SET_WORKROOMEQUIPMENT:
      return {
        ...state,
        isLoading: false,
        error: null,
        workroomEquipmentOptions: action.workroomEquipmentOptions,
      };
    case workroomTypesEnum.SET_WORKROOM_ENABLED_FILTERS:
      return {
        ...state,
        isLoading: false,
        error: null,
        enabledFilters: action.enabledFilters,
      };
    case workroomTypesEnum.SET_BUILDINGS:
      return {
        ...state,
        isLoading: false,
        error: null,
        buildings: action.buildings,
      };
    case workroomTypesEnum.SET_RESERVNOW:
      return {
        ...state,
        reservNow: action.reservNow,
      };
    case workroomTypesEnum.SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.searchFilters,
      };
    case workroomTypesEnum.RESET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: null,
        reservNow: false,
      };
    case workroomTypesEnum.SET_WAITING_LIST:
      return {
        ...state,
        waitingListWr: action.waitingListWr,
      };
    case workroomTypesEnum.SET_HOSTS:
      return {
        ...state,
        isLoading: false,
        error: null,
        hosts: action.hosts,
      };
    case workroomTypesEnum.SET_PRESELECTED_HOST:
      return {
        ...state,
        isLoading: false,
        error: null,
        preselectedHost: action.preselectedHost,
      };
    case workroomTypesEnum.DELETE_PRESELECTED_HOST:
      return {
        ...state,
        isLoading: false,
        error: null,
        preselectedHost: null,
      };
    case workroomTypesEnum.FAILURE_WORKROOM:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case workroomTypesEnum.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case workroomTypesEnum.RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case workroomTypesEnum.SET_WORKROOM_DETAIL_RESERVATION:
      return {
        ...state,
        isLoading: false,
        error: null,
        workroomDetailReservation: action.workroomDetailReservation,
      };
    case workroomTypesEnum.SET_WORKROOM_DETAIL:
      return {
        ...state,
        isLoading: false,
        error: null,
        workroomDetail: action.workroomDetail,
      };
    case workroomTypesEnum.RESET_WORKROOMS:
      return initialState;
    default:
      return state;
  }
};
