import muleSoftInstance from '../../utils/httpCommon';

const newOrder = async (
  pin: string,
  employeeId: string,
  commerceId: string,
): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order`;
  try {
    const resp = await muleSoftInstance.post(
      path,
      {
        pin: pin,
        data: {
          commerceId: commerceId,
          associatedData: {
            customer: {
              id: employeeId,
            },
          },
        },
      },
      null,
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getMenu = async (
  pin: string,
  orderExternalId: string,
  localLanguage: string,
): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments-integrator/menu`;

  try {
    const resp = await muleSoftInstance.post(
      path,
      {
        pin: pin,
        lang: localLanguage,
        orderExternalId: orderExternalId,
        currency: 'EUR',
      },
      null,
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const payOrder = async (
  orderId: string,
  urlOk: string,
  urlKo: string,
): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order`;
  try {
    const resp = await muleSoftInstance.put(
      path,
      {
        orderId,
        urlOk,
        urlKo,
      },
      null,
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const payWallet = async (orderId: string): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order/${orderId}/status`;
  try {
    const resp = await muleSoftInstance.put(
      path,
      {
        orderId,
      },
      null,
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const confirmOrder = async (orderId: string): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments-integrator/push_order`;
  try {
    const resp = await muleSoftInstance.post(
      path,
      {
        externalId: orderId,
        paid: true,
      },
      null,
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getOrder = async (orderId: string): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order/${orderId}`;
  try {
    const resp = await muleSoftInstance.get(path, null, true);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getReceipt = async (orderId: string): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/order/${orderId}/pdf`;
  try {
    const resp = await muleSoftInstance.get(
      path,
      { responseType: 'blob' },
      true,
    );
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getPaymentStatus = async (orderId: string): Promise<any> => {
  const path = `${process.env.REACT_APP_NEW_API_URL}/gw-c/payments/payment/transaction/${orderId}`;
  try {
    const resp = await muleSoftInstance.get(path, null, true);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const restaurantTableServices = {
  newOrder,
  getMenu,
  payOrder,
  payWallet,
  confirmOrder,
  getOrder,
  getReceipt,
  getPaymentStatus,
};
